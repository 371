import Style from "./user-profile-verification.module.scss";
import ResuableHeader from "../../Components/ResuableHeader/ResuableHeader";
import OTPInput from "otp-input-react";
import { useState, useEffect } from "react";
import backArrow from "../../Assets/Images/arrow-back.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateMobileProfile,
  loginmobile,
  resetLogin,
  sendOtp,
} from "../../slice/loginSlice";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { setToken } from "../../slice/auth";
import { useRef } from "react";
import axios from "axios";
import { useCallback } from "react";

const OtpVerification = ({
  title = "",
  showHeader = true,
  sendOtpOnCompLoad = false,
  dataToUpdate = {},
  onCancel = () => {},
}) => {
  // window.gtag('event', 'OtpVerification', { method: 'Google' });
  document.title = title;
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [OTP, setOTP] = useState("");
  const [remain, setRemain] = useState(false);
  const [updateProfileData, setUpdateProfileData] = useState(null);

  const { mobileNo, pvotpStatus, otpStatus } = useSelector(
    (state) => state.users
  );
  // console.log('Mobile No', mobileNo);
  // console.log({ pvotpStatus });

  const handleInputChange = (event) => {
    setOTP(event);
  };

  useEffect(() => {
    if (OTP.length === 4) {
      const { name, email, newMobile, mobile, grNumber, viewedCOVScreen } =
        updateProfileData || {};
      dispatch(
        updateMobileProfile({
          newMobile,
          otp: OTP,
          email,
          name,
          id: "EV_" + newMobile,
          mobile,
          grNumber,
          skipGrNumber: grNumber ? false : true,
          userPreference: "alreadyOwned",
          viewedCOVScreen,
        })
      );
      onCancel(false);
    }
    return () => {};
  }, [OTP, dispatch, onCancel, updateProfileData]);

  // useEffect(() => {
  //   if (pvotpStatus.status) {
  //     // if (pvotpStatus.status !== "success") {
  //     //   // console.log(pvotpStatus.message || pvotpStatus.status);
  //     //   setError(pvotpStatus.message || pvotpStatus.status);
  //     // } else {
  //     //   // alert(mobileNo)
  //     //   // remove this once your be is deployed
  //     //   dispatch(setToken(pvotpStatus.data));
  //     //   // alert("Profile updated successfully");
  //     //   navigate("/evinindia", { replace: true });
  //     // }
  //     // otpVerificationResponse();
  //   }
  //   return () => {
  //     dispatch(resetLogin());
  //   };
  // }, [dispatch, otpVerificationResponse, pvotpStatus.status]);

  function handleResend() {
    setOTP("");
    setRemain(false);
    dispatch(
      sendOtp({
        mobile: sendOtpOnCompLoad ? dataToUpdate?.newMobile : mobileNo,
      })
    );
  }

  const sendOtpHandler = useCallback(async (body) => {
    let token = localStorage.getItem("evtoken");
    var config = {
      method: "put",
      url: process.env.REACT_APP_USER_BACKEND_API + "send-otp",
      // url: process.uat.env.REACT_APP_USER_BACKEND_API + url,
      headers: {
        "Content-Type": "application/json",
        token: token ? token : "",
      },
      data: JSON.stringify(body),
    };

    try {
      // console.log('sfdasd');
      const response = await axios(config);
      response.data.mobile = body.mobile;
      return response.data;
    } catch (err) {}
  }, []);

  useEffect(() => {
    if (sendOtpOnCompLoad && !updateProfileData) {
      setUpdateProfileData(dataToUpdate);
    }
  }, [dataToUpdate, sendOtpOnCompLoad, updateProfileData]); //onload only
  useEffect(() => {
    if (updateProfileData) {
      sendOtpHandler({ mobile: updateProfileData?.newMobile });
      // dispatch(sendOtp({ mobile: updateProfileData?.newMobile }));
    }
  }, [sendOtpHandler, updateProfileData]);

  return (
    <>
      {showHeader && <ResuableHeader header={"My Profile"} />}
      <div onClick={() => onCancel(false)} style={{ padding: "25px" }}>
        <img src={backArrow} alt="Back-Icon" />
      </div>

      <main className={Style["otp-main"]}>
        <section className={Style["confirm-otp-main"]}>
          <p className={Style["confirm-text"]}>Confirm OTP</p>
          <p className={Style["otp-text"]}>
            OTP has been sent to you on your <br /> mobile number
          </p>
        </section>

        <section className={Style["otp-box"]}>
          <OTPInput
            value={OTP}
            onChange={handleInputChange}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            secure={false}
            maxTime={true}
            inputStyles={{
              width: "4em",
              height: "3.5em",
              border: "1px solid #14AE5C",
              borderRadius: 5,
              fontWeight: "bold",
              fontSize: 16,
              color: "white",
              backgroundColor: "#1b2028",
              outline: "none",
            }}
          />
          {error && <p className={Style["errorMessage"]}>{error}</p>}

          {!remain && (
            <Timer
              onStart={() => {}}
              onStop={() => {
                setRemain(true);
              }}
            />
          )}
          {remain && (
            <div className={Style["receive-text"]}>
              Didn't receive an OTP?
              <span className={Style["resend-text"]} onClick={handleResend}>
                Resend
              </span>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default OtpVerification;

const Timer = ({ onStart, onStop }) => {
  const [seconds, setSeconds] = useState(30);
  const timer = useRef();
  useEffect(() => {
    if (seconds > 0) {
      timer.current = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      onStop();
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [seconds]);
  return <div className="otp-count">Resend OTP : {seconds}</div>;
};
