import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//     activeMenu:"home"
// }

export const sidebarMenu = createSlice({
    name: "sidebar",
    initialState:{
        activeMenu:"Home",
        drowerOpen: false
    },
    reducers: {
        toggleActiveMenu: (state, action) => {
            state.activeMenu = action.payload;
        },
        drowerOpenStatus: (state, action) => {
            state.drowerOpen = action.payload;
        }
    }
})

export const { toggleActiveMenu, drowerOpenStatus } = sidebarMenu.actions;

export default sidebarMenu.reducer;