import './NewSplash.scss';
import logo from '../../Assets/Images/HDFC-Ergo-logo.svg';
import logoFavicon from '../../Assets/Images/logo-favicon.svg';
import greenElectric from '../../Assets/Images/logo-favicon.svg';

const NewSplash = () => {
  return (
    <main className='splash-main'>
      <div className='splash-logo'>
        <img className='galleryImg' src={logoFavicon} alt='favicon-logo' />
      </div>
      <div className='splash-text'>
        {/* <p className="splash-para1">All <font color="#00AF3C">Things</font></p>
                <p className="splash-para2">E<font color="#00AF3C">V</font></p> */}
        <div id='typing'>
          All <span>Things</span>
        </div>
        <div id='typing'>
          <span>EV</span>
        </div>
        {/* <div id="crow">|</div> */}
      </div>

      {/* <div className="hdfcergo-footer">
                <p>Powered By:</p>
                <img src={logo} alt="hdfc-ergo" />
            </div> */}
    </main>
  );
};

export default NewSplash;
