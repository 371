import React, { useEffect, useState, useCallback } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";

import "./VehicleInfo.scss";
import Speedometer from "../../Assets/Images/Speedometer.svg";
import Transmission from "../../Assets/Images/automatic-transmission1.svg";
import Seat from "../../Assets/Images/seat.svg";
import Power from "../../Assets/Images/Power_icon.svg";
import Battery from "../../Assets/Images/Icon-metro-battery-empty.svg";
import AccordionDownArrow from "../../Assets/Images/DD.svg";
import BikeBattery from "../../Assets/Images/vehicle-info-battery.svg";
import ResuableHeader from "../ResuableHeader/ResuableHeader";
import CarLoader from "../Loader/CarLoader";
import { numberFormat } from "../../utils/Helper";
import DroomFooter from "../DroomFooter/DroomFooter";
import { decrypt, encrypt } from "../../service/encryption";

const VehicleInfo = ({ title = "" }) => {
  document.title = title;
  const { id, type } = useParams();
  // console.log(id);

  const loc = useLocation();
  const vData = loc.state;
  // console.log(vData);

  const [info, setInfo] = useState({});

  const handleVarientInfo = useCallback(async () => {
    const url = process.env.REACT_APP_BACKEND_API + "droom/mmty-by-product";
    const params = encrypt(
      JSON.stringify({
        product_id: `${id}`,
      })
    );
    try {
      const result = await axios.post(url, params, {
        headers: {
          token: process.env.REACT_APP_COV_TOKEN,
        },
      });
      const vehicleInfo = JSON.parse(decrypt(result.data.data));
      setInfo(vehicleInfo);
    } catch (err) {
      // console.log(err);
    }
  }, [id]);

  useEffect(() => {
    // console.log('render');
    handleVarientInfo();
  }, [handleVarientInfo]);

  if (!info["vehicle_specification"]) {
    return <CarLoader />;
  }

  function cardBlock(type, details) {
    // if (type === "car") {
    const { seating_capacity, charging_time_for_electric_scooter } =
      type === "car"
        ? details["Basic Facts"]["Basic Facts"]
        : type === "bike"
        ? details["Technical Specification"]["Battery"]
        : details["Technical Specification"]["Engine"];
    return (
      <div className="vehicle-info-features">
        <div className="vehicle-info-features-img">
          {/* <img src={type === 'car' ? Seat : BikeBattery} alt='' /> */}
          <img src={type === "car" ? Battery : Battery} alt="" />
        </div>
        <div>
          <p className="vehicle-info-heading">
            {type === "car"
              ? seating_capacity
              : charging_time_for_electric_scooter}
          </p>
          <p className="vehicle-info-sub">
            {type === "car" ? "Seating Capcity" : "Charging Time"}
          </p>
        </div>
      </div>
    );
  }

  if (info["vehicle_specification"]) {
    const technicalSpecification =
      info["vehicle_specification"]["Technical Specification"];
    const technicalSpecificationn =
      info["vehicle_specification"]["Technical Specification"];
    // console.log(technicalSpecificationn.engine_type)
    const basicSpecification =
      info["vehicle_specification"]["Basic Facts"]["Basic Facts"] ||
      info["vehicle_specification"]["Basic Facts"]["Basic Fatcs"];
    // console.log({basicSpecification})
    return (
      <div style={{ backgroundColor: "#141414" }}>
        <ResuableHeader header={info.product_title} />
        <div className="vehicle-info-main">
          <div>
            <img
              //  src={Speedometer}
              src={info.stock_images || vData.stock_images}
              alt=""
              className="vehicle-info-poster"
            />
          </div>
          <div className="vehicle-info-basic">
            <div className="vehicle-info-prices">
              <p className="vehicle-info-exprice">Ex - Showroom Price:</p>
              <p className="exprice">
                ₹ {numberFormat(info.ex_showroom_price)}
              </p>
            </div>
            <section className="vehicle-info-content">
              <section>
                <div className="vehicle-info-features">
                  <div className="vehicle-info-features-img">
                    <img src={Speedometer} alt="" />
                  </div>
                  <div>
                    <p className="vehicle-info-heading">
                      {info.mileage_city !== ""
                        ? info.mileage_city
                        : "Not Specified"}
                    </p>
                    <p className="vehicle-info-sub">Mileage</p>
                  </div>
                </div>

                {/* For Card Box Section */}
                {cardBlock(type, info["vehicle_specification"])}
              </section>
              <section>
                <div className="vehicle-info-features">
                  <div className="vehicle-info-features-img">
                    <img src={Transmission} alt="" />
                  </div>
                  <div>
                    <p className="vehicle-info-heading">
                      {basicSpecification.transmission_type}
                    </p>
                    <p className="vehicle-info-sub">Transmission</p>
                  </div>
                </div>
                {technicalSpecification.Motor?.maximum_power ? (
                  <div className="vehicle-info-features">
                    <div className="vehicle-info-features-img">
                      <img src={Power} alt="" />
                    </div>
                    <div>
                      <p className="vehicle-info-heading">
                        {technicalSpecification.Motor.maximum_power}
                      </p>
                      <p className="vehicle-info-sub">Power</p>
                    </div>
                  </div>
                ) : technicalSpecification.Motor
                    ?.max_power_for_electric_scooter ? (
                  <div className="vehicle-info-features">
                    <div className="vehicle-info-features-img">
                      <img src={Power} alt="" />
                    </div>
                    <div>
                      <p className="vehicle-info-heading">
                        {technicalSpecification.Motor
                          .max_power_for_electric_scooter !== ""
                          ? technicalSpecification.Motor
                              .max_power_for_electric_scooter
                          : "NA"}
                      </p>
                      <p className="vehicle-info-sub">Power</p>
                    </div>
                  </div>
                ) : (
                  <div className="vehicle-info-features">
                    <div className="vehicle-info-features-img">
                      <img src={Power} alt="" />
                    </div>
                    <div>
                      <p className="vehicle-info-heading">
                        {technicalSpecificationn.Engine
                          ?.max_power_for_electric_scooter === ""
                          ? technicalSpecificationn.Engine
                              ?.max_power_for_electric_scooter
                          : "NA"}
                      </p>
                      <p className="vehicle-info-sub">Power</p>
                    </div>
                  </div>
                )}
              </section>
            </section>
          </div>
          <div className="vehicle-accordion">
            <Accordion
              sx={{
                background: "#1C1C24 !important",
              }}
            >
              {/* <div className='main_accordion'>Hello</div> */}
              <AccordionSummary
                aria-controls="panel1a-content"
                expandIcon={
                  <img className="arrow" src={AccordionDownArrow} alt="" />
                }
                id="panel1a-header"
                sx={{
                  background: "#1C1C24 !important",
                }}
              >
                <div className="vehicle-info-acc-main">
                  <p className="vehicle-info-acc-text">
                    <span className="heading">Other Details</span>
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails className="sub-accordion">
                <Accordion>
                  <div className="basic-fact-bg">
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      expandIcon={<img src={AccordionDownArrow} alt="" />}
                      id="panel1a-header"
                    >
                      <div className="vehicle-info-acc-main">
                        <p className="vehicle-info-acc-text">
                          <span className="heading">Basic Facts</span>
                        </p>
                      </div>
                    </AccordionSummary>
                  </div>
                  <AccordionDetails>
                    <div className="boder_div"></div>
                    <div className="flex-content">
                      <p>Exterior Color:</p>
                      <p>{basicSpecification.exterior_color}</p>
                    </div>
                    {type === "car" ? (
                      <div className="flex-content">
                        <p>Interior Color:</p>
                        <p>{basicSpecification.interior_color}</p>
                      </div>
                    ) : null}
                    <div className="flex-content">
                      <p>Body Type:</p>
                      <p>{basicSpecification.body_type}</p>
                    </div>
                    <div className="flex-content">
                      <p>Group Body Type:</p>
                      <p>{basicSpecification.group_body_type}</p>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Brake System */}
                <Accordion>
                  <div className="basic-fact-bg">
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      expandIcon={<img src={AccordionDownArrow} alt="" />}
                      id="panel1a-header"
                    >
                      <div className="vehicle-info-acc-main">
                        <p className="vehicle-info-acc-text">
                          <span className="heading">Brake Systems</span>
                        </p>
                      </div>
                    </AccordionSummary>
                  </div>
                  <AccordionDetails>
                    <div className="boder_div"></div>
                    <div className="flex-content">
                      <p>Front Brake Type:</p>
                      <p>
                        {
                          technicalSpecification["Brake System"]
                            .front_brake_type
                        }
                      </p>
                    </div>
                    <div className="flex-content">
                      <p>Interior Color:</p>
                      <p>
                        {technicalSpecification["Brake System"].rear_brake_type}
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Dimensions */}
                <Accordion>
                  <div className="basic-fact-bg">
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      expandIcon={<img src={AccordionDownArrow} alt="" />}
                      id="panel1a-header"
                    >
                      <div className="vehicle-info-acc-main">
                        <p className="vehicle-info-acc-text">
                          <span className="heading">Dimensions</span>
                        </p>
                      </div>
                    </AccordionSummary>
                  </div>
                  <AccordionDetails>
                    <div className="boder_div"></div>
                    {type === "car" ? (
                      <div className="flex-content">
                        <p>Boot Space:</p>
                        <p>{technicalSpecification.Dimensions.boot_space}</p>
                      </div>
                    ) : null}
                    <div className="flex-content">
                      <p>Ground Clearance:</p>
                      <p>
                        {technicalSpecification.Dimensions.ground_clearance ===
                        ""
                          ? "NA"
                          : technicalSpecification.Dimensions.ground_clearance}
                      </p>
                    </div>
                    {type === "car" ? (
                      <div className="flex-content">
                        <p>Kerb Weight:</p>
                        <p>{technicalSpecification.Dimensions.kerb_weight}</p>
                      </div>
                    ) : null}
                    <div className="flex-content">
                      <p>Overall Height:</p>
                      <p>
                        {technicalSpecification.Dimensions.overall_height === ""
                          ? "NA"
                          : technicalSpecification.Dimensions.overall_height}
                      </p>
                    </div>
                    <div className="flex-content">
                      <p>Overall Length:</p>
                      <p>
                        {technicalSpecification.Dimensions.overall_length === ""
                          ? "NA"
                          : technicalSpecification.Dimensions.overall_length}
                      </p>
                    </div>
                    <div className="flex-content">
                      <p>Overall Width:</p>
                      <p>
                        {technicalSpecification.Dimensions.overall_width === ""
                          ? "NA"
                          : technicalSpecification.Dimensions.overall_width}
                      </p>
                    </div>
                    {type === "car" ? (
                      <div className="flex-content">
                        <p>Saddle Height:</p>
                        <p>
                          {technicalSpecification.Dimensions.saddle_height !==
                          ""
                            ? technicalSpecification.Dimensions.saddle_height
                            : "NA"}
                        </p>
                      </div>
                    ) : null}
                    <div className="flex-content">
                      <p>Wheel Base:</p>
                      <p>
                        {technicalSpecification.Dimensions.wheel_base === ""
                          ? "NA"
                          : technicalSpecification.Dimensions.wheel_base}
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Engine */}
                {type === "car" ? (
                  <Accordion>
                    <div className="basic-fact-bg">
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        expandIcon={<img src={AccordionDownArrow} alt="" />}
                        id="panel1a-header"
                      >
                        <div className="vehicle-info-acc-main">
                          <p className="vehicle-info-acc-text">
                            <span className="heading">Engine</span>
                          </p>
                        </div>
                      </AccordionSummary>
                    </div>
                    <AccordionDetails>
                      <div className="boder_div"></div>
                      <div className="flex-content">
                        <p>Displacement:</p>
                        <p>
                          {technicalSpecification.Engine.displacement !== ""
                            ? technicalSpecification.Engine.displacement
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>Engine Type:</p>
                        <p>
                          {technicalSpecification.Engine.engine_type !== ""
                            ? technicalSpecification.Engine.engine_type
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>Fuel Efficiency:</p>
                        <p>
                          {technicalSpecification.Engine.fuel_efficiency !== ""
                            ? technicalSpecification.Engine.fuel_efficiency
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>Fuel Supply System:</p>
                        <p>
                          {technicalSpecification.Engine.fuel_supply_system !==
                          ""
                            ? technicalSpecification.Engine.fuel_supply_system
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>Maximum Power:</p>
                        <p>
                          {technicalSpecification.Engine.maximum_power !== ""
                            ? technicalSpecification.Engine.maximum_power
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>Maximum Torque:</p>
                        <p>
                          {technicalSpecification.Engine.maximum_torque !== ""
                            ? technicalSpecification.Engine.maximum_torque
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>No Of Cylinders:</p>
                        <p>
                          {technicalSpecification.Engine.no_of_cylinders !== ""
                            ? technicalSpecification.Engine.no_of_cylinders
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>Valve Configuration:</p>
                        <p>
                          {technicalSpecification.Engine
                            .valve_configuration_valvetrain !== ""
                            ? technicalSpecification.Engine
                                .valve_configuration_valvetrain
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>Valve Per Cylinder:</p>
                        <p>
                          {technicalSpecification.Engine.valves_per_cylinder !==
                          ""
                            ? technicalSpecification.Engine.valves_per_cylinder
                            : "NA"}
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
                {/* Susupensions */}
                <Accordion>
                  <div className="basic-fact-bg">
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      expandIcon={<img src={AccordionDownArrow} alt="" />}
                      id="panel1a-header"
                    >
                      <div className="vehicle-info-acc-main">
                        <p className="vehicle-info-acc-text">
                          <span className="heading">Suspensions</span>
                        </p>
                      </div>
                    </AccordionSummary>
                  </div>
                  <AccordionDetails>
                    <div className="boder_div"></div>
                    <div className="flex-content">
                      <p>Front Suspension:</p>
                      <p>
                        {technicalSpecification.Suspension.front_suspension !==
                        ""
                          ? technicalSpecification.Suspension.front_suspension
                          : "NA"}
                      </p>
                    </div>
                    <div className="flex-content">
                      <p>Rear Suspension:</p>
                      <p>
                        {technicalSpecification.Suspension.rear_suspension !==
                        ""
                          ? technicalSpecification.Suspension.rear_suspension
                          : "NA"}
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Performence */}
                {type === "car" ? (
                  <Accordion>
                    <div className="basic-fact-bg">
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        expandIcon={<img src={AccordionDownArrow} alt="" />}
                        id="panel1a-header"
                      >
                        <div className="vehicle-info-acc-main">
                          <p className="vehicle-info-acc-text">
                            <span className="heading">Performance</span>
                          </p>
                        </div>
                      </AccordionSummary>
                    </div>
                    <AccordionDetails>
                      <div className="boder_div"></div>
                      <div className="flex-content">
                        <p>Charging Time:</p>
                        <p>
                          {technicalSpecification.Performance
                            .charging_time_for_electric_scooter !== ""
                            ? technicalSpecification.Performance
                                .charging_time_for_electric_scooter
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>Mileage:</p>
                        <p>
                          {technicalSpecification.Performance
                            .mileage_electric_scooter !== ""
                            ? technicalSpecification.Performance
                                .mileage_electric_scooter
                            : "NA"}
                        </p>
                      </div>
                      <div className="flex-content">
                        <p>Top Speed:</p>
                        <p>
                          {technicalSpecification.Performance
                            .top_speed_for_electric_scooter !== ""
                            ? technicalSpecification.Performance
                                .top_speed_for_electric_scooter
                            : "NA"}
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
                {/* Features */}
                {/* {type==="car"?
          <Accordion>
            <div className="basic-fact-bg">
              <AccordionSummary
                aria-controls="panel1a-content"
                expandIcon={<img src={AccordionDownArrow} alt="" />}
                id="panel1a-header"
              >
                <div className="vehicle-info-acc-main">
                  <p className="vehicle-info-acc-text">
                    <span className="heading">Features</span>
                  </p>
                </div>
              </AccordionSummary>
            </div>
          <AccordionDetails>
            <div className="flex-content">
              <p>Exterior Color:</p>
              <p>{basicSpecification['Basic Facts'].exterior_color}</p>
            </div>
            <div className="flex-content">
              <p>Interior Color:</p>
              <p>{basicSpecification['Basic Facts'].interior_color}</p>
            </div>            
          </AccordionDetails>
          </Accordion> : null} */}
                {/*  */}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <DroomFooter />
      </div>
    );
  } else {
    return <p>Loading...</p>;
  }
};

export default VehicleInfo;
