import "./ResuableHeader.scss";
import { useDispatch } from "react-redux";
import backArrow from "../../Assets/Images/arrow-back.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ReusableCard from "../ReusableCard/ReusableCard";
import StickyHeader from "../UI/StickyHeader";
import { drowerOpenStatus } from "../../slice/sidebarMenuSlice";
const ResuableHeader = ({
  header,
  img,
  setShow,
  bgc,
  cardImg,
  headerDesc,
  cardYes,
  onClick = () => {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // console.log(img);
  const headerImg = img;
  return (
    <>
      <StickyHeader>
        <section className="header-container" onClick={onClick}>
          <div className="back-arrow">
            {setShow ? (
              setShow
            ) : (
              <div
                onClick={() => {
                  if (location.pathname === "/user-profile") {
                    navigate("/evinindia", { replace: true });
                    dispatch(drowerOpenStatus(true));
                    return;
                  }
                  navigate(-1, {
                    replace: true,
                  });
                }}
              >
                <img src={backArrow} alt="Back-Icon" />
              </div>
            )}
          </div>
          <div className="heading-text">
            <p>{header}</p>
            {headerImg && <img src={img} alt="" />}
          </div>
        </section>
      </StickyHeader>
      {cardYes && (
        <ReusableCard
          className="carrd"
          cardImg={cardImg}
          bgc={bgc}
          headerDesc={headerDesc}
        />
      )}
    </>
  );
};

export default ResuableHeader;
