import './tax-benefit.scss';
import closeYellow from '../../Assets/Images/close-yellow.svg';
import Scooter2 from '../../Assets/Images/scooterr.png';
import tally from '../../Assets/Images/tally.png';
import taxes from '../../Assets/Images/tax.png';
import percentRange from '../../Assets/Images/percentRange.png';
import StateRegulationCarousel from '../../Components/StateRegulationCarousel/StateRegulationCarousel';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';

const TaxBenefit = ({ title = '' }) => {
  document.title = title;
  const TaxBenefitsContent = [


    {
      cancelIcon: closeYellow,
      taxPara:
        '₹ 1,50,000 Deduction in Income Tax Return (ITR) under section 80EEB on loan interest charged',
      taxImg: tally,
      link: {
        text: 'Ministry of Power, GOI',
        value: 'https://powermin.gov.in/en/content/electric-vehicle',
      },
    },
    {
      cancelIcon: closeYellow,
      taxPara: 'GST rate reduction on EV- From 12% to 5%',
      taxImg: percentRange,
    },
    {
      cancelIcon: closeYellow,
      taxPara: 'Fully Exempted from Green Tax',
      taxImg: taxes,
    },
    {
      cancelIcon: closeYellow,
      taxPara:'Exempted from Road Tax and registration costs in selected states.',
      taxImg: Scooter2,
     
    },
  ];

  return (
    <div>
      <ResuableHeader header='Tax Benefits' />
      <StateRegulationCarousel carouselDataa={TaxBenefitsContent} />
    </div>
  );
};

export default TaxBenefit;
