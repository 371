// import ptb from "../../Assets/Images/ptb.svg";
// import ete from "../../Assets/Images/ete.svg";
// import aoe from "../../Assets/Images/aoe.svg";
import ptb from "../../Assets/Images/UP1_icon.svg";
import ete from "../../Assets/Images/UP2_icon.svg";
import aoe from "../../Assets/Images/UP3_icon.svg";
import "./UserPreference.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  sendPreference,
  updateEVDatailsLocalStorage,
} from "../../slice/loginSlice";
import { updateUserDetails } from "../../slice/userDetailsSlice";

let list = [
  { label: "Planning to buy an EV ?", value: "planningToBuy", image: ptb },
  {
    label: "Already own an EV ?",
    value: "alreadyOwned",
    image: ete,
  },
  { label: "Earn through EV ?", value: "earnThroughEv", image: aoe },
];

const UserPreference = ({ title = "" }) => {
  document.title = title;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mobileNo, preference, data } = useSelector((state) => state.users);
  // const { mobile } = JSON.parse(localStorage.getItem("evdetails"));
  const { mobile } = useSelector((state) => state.userDetails.user);
  const { status } = useSelector((state) => state.users.preferenceStatus);
  const onBoarding = async (obj) => {
    if (obj.value !== "alreadyOwned") {
      // dispatch(updateEVDatailsLocalStorage({ userPreference: obj.value }));
      dispatch(updateUserDetails({ userPreference: obj.value }));
      dispatch(sendPreference({ preference: obj.value, mobile: mobile }));
      // localStorage.setItem("preference", obj.value);
      navigate("/evinindia", { replace: true });
    } else {
      // localStorage.setItem("preference", obj.value);
      // dispatch(updateEVDatailsLocalStorage({ userPreference: obj.value }));
      dispatch(updateUserDetails({ userPreference: obj.value }));
      dispatch(
        sendPreference({
          preference: obj.value,
          mobile: mobile,
          skipGrNumber: true,
        })
      );
      window.history.pushState("", "", "/userPreference");
      navigate("/userVehicleInfo", {
        replace: true,
        state: { from: "user-pref" },
      });
    }
  };

  // useEffect(() => {
  //   console.log(status);
  //   if (status === "success") {
  //     console.log("useeffect user pref");
  //     navigate("/evinindia", { replace: true });
  //   } else {
  //     navigate("/userPreference", { replace: true });
  //   }
  // }, [status]);

  return (
    <div className="UserPreference">
      <div className="UserPreference_area">
        <p className="UserPreference_card_p">What are you looking for?</p>
        <p className="UserPreference_card_p1">Please select your choice</p>
      </div>
      <div className="UserPreference_card">
        {list.map((d, index) => {
          return (
            <div
              key={index}
              className="UserPreference_plannin"
              onClick={() => {
                onBoarding(d);
              }}
            >
              <img src={d.image} alt="icons" />
              {/* <hr className="vrline" /> */}
              <span>{d.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserPreference;
