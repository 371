import './DroomFooter.scss';
import droomImage from '../../Assets/Images/droom.svg';

const DroomFooter = () => {
  return (
    <>
      <footer>
        <div className='droom-footer-main'>
          <div className='droom-text'>
            <p>Powered by</p>
          </div>
          <div className='droom-image'>
            <img src={droomImage} alt='Droom-Logo' />
          </div>
        </div>
      </footer>
    </>
  );
};

export default DroomFooter;
