import "./dash-board.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// import { dashboardItems } from '../../utils/Utilities';
import { dashboardItems } from "../../Routes/Dashboard-Route";
// import BestInterest from "../BestInterest/BestInterest";
import { useSelector } from "react-redux";
import XpressCarLoanLogo from "../../Assets/Images/XpressCarLoanLogo.png";
import manVectorLoan from "../../Assets/Images/manVectorLoan.svg";
import loanRings from "../../Assets/Images/loan-Rings.svg";
import loanArrow from "../../Assets/Images/loan-arrow.svg";

const setMyPreference = (myPreference) => {
  // alert(myPreference);
  if ("alreadyOwned" === myPreference) {
    return [
      dashboardItems.item1,
      dashboardItems.item2,
      dashboardItems.item3,
      dashboardItems.item4,
    ];
  }
  if ("earnThroughEv" === myPreference) {
    return [
      dashboardItems.item2,
      dashboardItems.item1,
      dashboardItems.item3,
      dashboardItems.item4,
    ];
  }
  return [
    dashboardItems.item3,
    dashboardItems.item1,
    dashboardItems.item2,
    dashboardItems.item4,
  ];
};

const DashBoard = (props) => {
  const navigate = useNavigate();
  const { activeMenu } = useSelector((state) => state.sidebarMenu);
  const { userPreference } = useSelector((state) => state.userDetails.user);
  const loggedIn = useSelector((state) => state.auth.token);
  // let userData = JSON.parse(localStorage.getItem("evdetails") || "{}");
  // const { userPreference } = userData;
  // if (!userPreference || userPreference.length <= 2) {
  //   navigate("/userPreference", { replace: true });
  // }
  document.title = "Dashboard";
  // let myPreference = localStorage.getItem("preference");
  // console.log("myPreference ===", myPreference);

  const arrComponent = setMyPreference(userPreference);

  const gotonext = (path) => {
    navigate(path);
  };

  useEffect(() => {
    let el = document.getElementById(activeMenu);
    el.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
    return;
  }, [activeMenu]);

  useEffect(() => {
    if (userPreference === "" || userPreference.length <= 2) {
      if (loggedIn) navigate("/userPreference", { replace: true });
    }
  }, [userPreference, loggedIn]);

  return (
    <>
      {arrComponent.map((data, index) => {
        return (
          <div key={index} id={data.heading}>
            <div className="chargeitup-wrapper">
              <section className="chargeitup-header">
                <div className="chargeitup-img">
                  <img src={data.headingImg} alt="Charge" />
                </div>
                <div className="chargeitup-header-text">{data.heading}</div>
              </section>
              <section className="card-containers">
                <div
                  className="nearby-stations-card"
                  onClick={() => gotonext(data.to1)}
                  style={{ backgroundColor: data.cardBackground1 }}
                >
                  <div className="nearby-stations-img">
                    <img src={data.cardImg1} alt="Nearby-Station" />
                  </div>
                  <div>
                    <div className="nearby-stations-header-text">
                      {data.cardHeading1}
                    </div>
                    <div className="nearby-stations-content">
                      {data.cardPara1}
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => gotonext(data.to2)}
                  className="planning-trip-card "
                  style={{ backgroundColor: data.cardBackground2 }}
                >
                  <div className="planning-trip-img">
                    <img src={data.cardImg2} alt="Planning-Trip" />
                  </div>
                  <div>
                    <div className="planning-trip-header-text">
                      {data.cardHeading2}
                    </div>
                    <div className="planning-trip-content">
                      {data.cardPara2}
                    </div>
                  </div>
                </div>
              </section>
              {/* {data?.cardImg3 && (
                // <section className="card-containers">
                //   <div
                //     className="main-container-loan"
                //     onClick={() => {
                //       window.open(
                //         "https://applycarloan.hdfcbank.com/DiAL?MCID=ergo_EV_xcl&icid=ergo_EV_xcl&MKTFlag=Yes&promo_code=HDFC_Ergo_xcl",
                //         "_blank"
                //       );
                //     }}
                //   >
                //     <div className="left">
                //       <div className="item">
                //         <p style={{ fontSize: "20px" }}>
                //           <b> Auto loan at best</b> interest<br></br> rates on
                //           purchase of your
                //           <br /> favourite <b>EV</b>{" "}
                //           <img style={{ marginLeft: "5px" }} src={loanArrow} alt="arrow loan"></img>
                //         </p>
                //       </div>
                //       <div className="upper-ring">
                //         <img src={loanRings} alt="ring svg loan"></img>
                //       </div>
                //     </div>
                //     <div className="right-svg">
                //       <div
                //         style={{
                //           padding: "1rem 0 1rem 1rem",
                //           display: "flex",
                //           alignItems: "center",
                //           width: "70%",
                //         }}
                //       >
                //         <p style={{ fontSize: "12px" }}>powered by</p>
                //         <img
                //           width={"65%"}
                //           src={XpressCarLoanLogo}
                //           alt="powered by hdfc bank"
                //         />
                //       </div>
                //       <div className="lower-image">
                //         <img src={manVectorLoan} alt="lower svg loan" width={"100%"}></img>
                //       </div>
                //     </div>
                //   </div>
                // </section>
              // )} */}
              {/* { index === 0 &&  <BestInterest/>} */}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default DashBoard;
