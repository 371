import { useState } from 'react';
import Style from './sign-up.module.scss';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileno, setMobileno] = useState('');
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState(false);

  // Validation Function
  function validateName(str) {
    if (/^[a-zA-Z]+$/.test(str)) {
      setError(false);
      return true;
    }
    setError(true);
  }
  function validateMobileNumber(str) {
    if (/^[6-9]\d{9}$/gi.test(str)) {
      return true;
    }
    setError(true);
  }
  function validateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    setError(true);
  }
  const handleOnChange = e => {
    const val = parseInt(e.target.value) || '';

    if (typeof val === 'number') {
      setMobileno(e.target.value);
    } else {
      setMobileno();
    }
  };

  const handleChange = e => {
    let isChecked = e.target.checked;
    // console.log(isChecked);
    setTerms(isChecked);
    // do whatever you want with isChecked value
  };

  const handleSubmit = e => {
    e.preventDefault();

    const checkName = validateName(name);
    const checkEmail = validateEmail(email);
    const checkMobile = validateMobileNumber(mobileno);

    if (checkName && checkEmail && checkMobile && terms) {
      // console.log(name, email, mobileno, terms);
      navigate('/verification');
    } else {
      alert('Please fill correct details');
    }
  };

  const mobileField = !error ? Style['text-boxes'] : Style['show-error'];

  return (
    <>
      <main className={Style['signup-main']}>
        <section className={Style['create-account-main']}>
          <p className={Style['create-text']}>Create an Account</p>
          <p className={Style['pleasefill-text']}>
            Please fill the details below
          </p>
        </section>
        <section className={Style['input-boxes-main']}>
          <span className={mobileField}>
            <input
              type='text'
              className={Style['input-boxes']}
              placeholder='Enter Name'
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
            />
          </span>
          <span className={mobileField}>
            <input
              type='email'
              className={Style['input-boxes']}
              placeholder='Enter Email'
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
            />
          </span>

          <span className={mobileField}>
            +91
            <input
              type='text'
              className={Style['input-boxes']}
              placeholder='Enter Mobile Number'
              required
              pattern='\d*'
              autoComplete='off'
              maxLength={10}
              value={mobileno}
              onChange={handleOnChange}
            />
          </span>
        </section>
        <section className={Style['terms-section']}>
          <input
            type='checkbox'
            className={Style['terms-check']}
            onChange={handleChange}
          />
          <p className={Style['terms-text']}>
            I agree to &nbsp;
            <span className={Style['terms-conditions']}>
              Terms & Conditions
            </span>
            &nbsp; of this App.
          </p>
        </section>
        <button
          onClick={e => handleSubmit(e)}
          className={Style['btn-continue']}
        >
          Continue
        </button>
        <div className={Style['already-text']}>
          Already an user?{' '}
          <span
            className={Style['sign-in']}
            onClick={() => {
              navigate('/signin');
            }}
          >
            Sign In
          </span>
        </div>
      </main>
    </>
  );
};

export default SignUp;
