import React, { useState, useEffect, useRef, useMemo } from 'react';
import './CostOfVehicle.scss';
import COV from '../../Assets/Images/Cost_Of_Vehicle.svg';
import TabBar from '../TabBar/TabBar';
import ResuableHeader from '../ResuableHeader/ResuableHeader';
import SearchBar from '../SearchBar/SearchBar';
import TCODetails from '../TCODetails/TCODetails';

import { VehicleAPI } from '../../APIs/CommonLogics';
import Loader from '../Loader/Loader';
import { useSelector } from 'react-redux';

const CostOfVehicle = ({ changeContent, title = '' }) => {
  document.title = title;
  const childFunc = useRef(null);

  const { isOpen } = useSelector(store => {
    return store.loader;
  });

  //
  const [tabNo, setTabNo] = useState(1);
  const [data, setData] = useState(null);
  const [cto, setCto] = useState(null);

  const tabFunc = tabno => {
    // console.log({ tabno });
    // setCto()
    setTabNo(tabno);
  };

  let tabs = useMemo(() => ['car', 'bike', 'scooter'], []);

  useEffect(() => {
    setCto(null);
    childFunc.current('');
    async function datafetch() {
      let data = await VehicleAPI(tabs[tabNo - 1]);
      setData(data);
    }
    datafetch();
  }, [tabNo, tabs]);

  const resultObj = [];

  if (data) {
    for (const datas of data) {
      resultObj[datas.product_title] = datas;
    };
  }

  const getTCODetail = async detail => {
    setCto(detail);
  };

  return (
    <>
      <ResuableHeader
        header='Find Cost Of Vehicle'
        url='/dashboard'
        headerDesc='Know the rough estimate of owning EV'
        cardImg={COV}
        bgc='#062121'
        cardYes={true}
      />

      <div className='cost-of-vehicle-main'>
        <div>Select Category</div>

        <TabBar tabFunc={tabFunc} />

        <div className='cost-of-vehicle-sub'>
          <div className='vehicle-name'>Vehicle Name</div>
          <SearchBar
            childFunc={childFunc}
            suggestions={resultObj}
            filterSection={false}
            getTCODetail={getTCODetail}
          />

          {cto ? (
            <div>
              <TCODetails detail={cto} type={tabs[tabNo - 1]} />
            </div>
          ) : isOpen ? (
            <Loader />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CostOfVehicle;
