import React, { useState, useEffect } from "react";
import "./SideMenu.scss";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HdfcergoLogo from "../../Assets/Images/hdfcergo-logo.png";
import SideNav from "../../Assets/Images/SideNav.svg";
import DBActive1 from "../../Assets/Images/DBActive1.svg";
import RTOmenu from "../../Assets/Images/RTOmenu.svg";
import DBActive from "../../Assets/Images/DBInActive1.svg";
import insightIcon_active from "../../Assets/Images/insightIcon_active.svg";
import chargeItUp_active from "../../Assets/Images/chargeItUp_active.svg";
import RTOmenu_active from "../../Assets/Images/RTOmenu_active.svg";
import earnIcon_active from "../../Assets/Images/earnIcon_active.svg";
import planningToBuy_active from "../../Assets/Images/planningToBuy_active.svg";
import close from "../../Assets/Images/Close.svg";
import profile from "../../Assets/Images/Profile.svg";
import insightIcon from "../../Assets/Images/insightIcon.svg";
import planningToBuy from "../../Assets/Images/PlanningToBuy.svg";
import chargeItUp from "../../Assets/Images/ChargeItUp.svg";
import earnIcon from "../../Assets/Images/earnIcon.svg";

import Drawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import backArrow from "../../Assets/Images/arrow-back.svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleActiveMenu, drowerOpenStatus } from "../../slice/sidebarMenuSlice";

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const SideMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
   const {drowerOpen } = useSelector((state) => state.sidebarMenu);
  const evdetails = localStorage.getItem("evdetails");
  const { name } = useSelector((state) => state.userDetails.user);
  const { activeMenu } = useSelector((state) => state.sidebarMenu);

  const menuList = [
    {
      label: "Home",
      icon: DBActive,
      activeIcon: DBActive1,
    },
    {
      label: "Planning to buy EV?",
      icon: planningToBuy,
      activeIcon: planningToBuy_active,
    },
    {
      label: "Charge It Up",
      icon: chargeItUp,
      activeIcon: chargeItUp_active,
    },
    {
      label: "Earn Through EV",
      icon: earnIcon,
      activeIcon: earnIcon_active,
    },
    {
      label: "RTO Services",
      icon: RTOmenu,
      activeIcon: RTOmenu_active,
    },
    {
      label: "Insights",
      icon: insightIcon,
      activeIcon: insightIcon_active,
    },
  ];

  return (
    <div className="side_menu">
      <img src={SideNav} alt="SideNav" onClick={() => {
       
         dispatch(drowerOpenStatus(true))
      }} />
      <Drawer
        anchor="left"
        open={drowerOpen}
        onClose={() => {
          dispatch(drowerOpenStatus(false))
        }}
        style={{ width: "100px" }}
      >
        <div className="back_button" onClick={() => {
          dispatch(drowerOpenStatus(false))
        }}>
          <img src={close} alt="Back-Icon" />
        </div>
        <List>
          <ListItem disablePadding>
            <ListItemText className="sub_menu">
              <div className="menu_item"
               onClick={() => {
                navigate("/user-profile");
              }}
              >
                <img
                  src={profile}
                  className="userIcon"
                  alt="HDFC-Ergo-logo"
                />
              </div>
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemText className="sub_menu">
              <div>{name ? `Hey ${name},` : null}</div>
            </ListItemText>
          </ListItem>

          <div className="border"></div>

          {menuList.map((item, index) => {
            return (
              <ListItem
                disablePadding
                sx={{
                  padding: "0 16px",
                  height: activeMenu === item?.label ? "60px" : "45px",
                }}
                key={item?.label}
              >
                <ListItemText className="sub_men">
                  <div
                    className={`${
                      activeMenu === item?.label
                        ? "sideBorder"
                        : "sidebar-not-active"
                    }`}
                  >
                     {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                     <a
                        className="menu_title menu_name"
                        onClick={() => {
                          dispatch(toggleActiveMenu(item?.label));
                          dispatch(drowerOpenStatus(false))
                        }}
                      > 
                    <MenuItem className="menu_name">
                        <img
                          className="menu_icon"
                          src={activeMenu ===  item?.label ? item?.activeIcon : item?.icon}
                          // src={item?.icon}
                          alt="planningToBuy"
                        />
                        <div>{item?.label}</div>
                      {/* </a> */}
                    </MenuItem>
                    </a>
                  </div>
                </ListItemText>
              </ListItem>
            );
          })}

            <footer>
                <div className="sidemenu-footer">
                  <div className="droom-text">
                    <p>Powered by</p>
                  </div>
                  <div className="droom-image">
                    <img className="userIcon" src={HdfcergoLogo} alt="Droom-Logo" />
                  </div>
                </div>
              </footer>
        </List>
      </Drawer>
    </div>
  );
};

export default SideMenu;
