import "./Planning.scss";
import swal from "sweetalert";
import planningTrip from "../../Assets/Images/PAT.svg";
import ResuableHeader from "../ResuableHeader/ResuableHeader";
import alert_icon from "../../Assets/Images/alert.svg";
import outline from "../../Assets/Images/Outline.svg";
import edit from "../../Assets/Images/edit.svg";
import arrow from "../../Assets/Images/Chevron_right.svg";
import outline_icon from "../../Assets/Images/location_icon1.svg";
import map_icon from "../../Assets/Images/location_icon2.svg";
import Edit_icon from "../../Assets/Images/Edit_icon1.svg";
import Line_icon from "../../Assets/Images/Line.svg";

import location_white from "../../Assets/Images/loc.svg";
import { HandleScrollToTop } from "../../APIs/CommonLogics";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import AutoComplete from "react-google-autocomplete";
import { decrypt } from "../../service/encryption";
// import ChooseLocatoion from "./ChooseLocatoion/ChooseLocatoion"

const Planning = ({ title = "" }) => {
  document.title = title;
  HandleScrollToTop();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [source, setSource] = useState("");
  const [findButtonDisable, setFindButtonDisable] = useState(false);

  const [destination, setDestination] = useState("");

  let nearByStations = [];

  const handleSubmit = () => {
    // console.log(source, destination);
    // if (!source || !destination) return;

    if (!source || !destination) {
      swal({
        title: "Error!",
        text: "Please Enter Source &  Destination Location",
        icon: alert_icon,
        buttons: {
          cancel: "Ok",
        },
        className: "alert-bg",
      });
    } else {
      const apiURL =
        process.env.REACT_APP_GOOGLE_lOCATION_API + `route-ev-stations`;
      const authorization = localStorage.getItem("GSauthToken");
      setFindButtonDisable(true);
      axios
        .post(
          apiURL,
          { origin: source, destination },
          {
            headers: {
              token: decrypt(JSON.parse(authorization)),
            },
          }
        )
        .then((res) => {
          console.log("response", res.data);
          //   setDatas(res.data.data)
          localStorage.setItem("planTripSource", source);
          localStorage.setItem("planTripDestination", destination);
          nearByStations = res.data.data;
          localStorage.setItem(
            "planTripResults",
            JSON.stringify(nearByStations)
          );
          setFindButtonDisable(false);
          navigate("/planatrip/locationonway", { state: res.data.data });
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          setFindButtonDisable(false);
        });
    }

    //   const apiURL =
    //   process.env.REACT_APP_GOOGLE_lOCATION_API +
    //   `route-ev-stations?origin=${source}&destination=${destination}`;
    // setFindButtonDisable(true);
    // axios
    //   .get(apiURL)
    //   .then(res => {
    //     // console.log('response', res.data);
    //     //   setDatas(res.data.data)
    //     localStorage.setItem('planTripSource', source);
    //     localStorage.setItem('planTripDestination', destination);
    //     nearByStations = res.data.data;
    //     localStorage.setItem('planTripResults', JSON.stringify(nearByStations));
    //     setFindButtonDisable(false);
    //     navigate('/planatrip/locationonway', { state: res.data.data });
    //   })
    //   .catch(err => {
    //     // console.log(err);
    //   })
    //   .finally(() => {
    //     setFindButtonDisable(false);
    //   });
    // }
  };

  return (
    <>
      <div className="planning-wrapper">
        <ResuableHeader
          header="Planning a Trip"
          url="/dashboard"
          cardImg={planningTrip}
          bgc="#115173"
          headerDesc="Find the charging stations on the way"
          cardYes={true}
        />
        {/* <ChooseLocatoion /> */}
        <div className="location-box">
          <section className="location-form">
            <form
              className="form-container"
              action="/"
              method="post"
              acceptCharset="UTF-8"
            >
              <label
                className="current-location-label"
                htmlFor="current-location"
              >
                Your current location
              </label>
              <span className="planning-source">
                <img
                  className="outline-img"
                  src={outline_icon}
                  alt="Map-Icon"
                />
                <AutoComplete
                  apiKey={`${process.env.REACT_APP_AUTOCOMPLETE}`}
                  value={source}
                  onChange={(e) => {
                    setSource(e.target.value);
                  }}
                  autocomplete="off"
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "IN" },
                  }}
                  onPlaceSelected={(place) => {
                    // console.log(
                    //   'Place' + place.address_components[0].long_name
                    // );
                    setSource(place.address_components[0].long_name);
                  }}
                  name="source"
                  className="planning-input"
                  placeholder="Enter Source Location"
                />
              </span>

              <label
                className="destination-location-label"
                htmlFor="destination-location"
              >
                Where do you want to go ?
              </label>

              <span className="planning-source">
                <img src={map_icon} alt="Location-Icon" className="loc-img" />

                <AutoComplete
                  apiKey={`${process.env.REACT_APP_AUTOCOMPLETE}`}
                  value={destination}
                  onChange={(e) => {
                    setDestination(e.target.value);
                  }}
                  autocomplete="off"
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "IN" },
                  }}
                  onPlaceSelected={(place) => {
                    // console.log(
                    //   'Place' + place.address_components[0].long_name
                    // );
                    setDestination(place.address_components[0].long_name);
                  }}
                  name="destination"
                  className="planning-input"
                  placeholder="Enter Destination Location"
                />
              </span>

              <div className="buttonDiv">
                <button
                  className="find-station"
                  type="button"
                  onClick={handleSubmit}
                  disabled={findButtonDisable}
                >
                  {findButtonDisable ? <Loader /> : "Find Station"}
                </button>
              </div>
            </form>
          </section>
        </div>
        {localStorage.getItem("planTripSource") ? (
          <div className="search-box">
            <section className="search-form">
              <form className="search-container" acceptCharset="UTF-8">
                <div
                  className="last-search-container"
                  onClick={() => {
                    let data = localStorage.getItem("planTripResults");
                    try {
                      data = JSON.parse(data);
                      navigate("/planatrip/locationonway", {
                        state: data,
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  <div className="last-search-text">Your last Search</div>
                  <img src={arrow} alt="arrow" />
                </div>

                <div className="planning-text-main">
                  <span className="planning-search-image">
                    <img src={map_icon} alt="Map-Icon" className="loc-img" />
                  </span>

                  <div>{localStorage.getItem("planTripSource")}</div>
                </div>
                <div>
                  <img
                    className="line_icon"
                    src={Line_icon}
                    alt="Location-Icon"
                  />
                </div>
                <div className="planning-text-main">
                  <span className="planning-search-image">
                    <img
                      className="loc-img"
                      src={location_white}
                      alt="Location-Icon"
                    />
                  </span>
                  <div>{localStorage.getItem("planTripDestination")}</div>
                </div>
              </form>
            </section>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Planning;
