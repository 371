import { useState, useEffect } from "react";
import Style from "./sign-in.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import backArrow from "../../Assets/Images/arrow-back.svg";
import { sendOtp, resetLogin } from "../../slice/loginSlice";
import TermsCondition from "../../Components/TermsCondition/TermsCondition";
import { termsHeader, termsContent } from "../../Helper/TermsConditions";
import Loader from "../../Components/Loader/Loader";
import ResuableHeader from "../../Components/ResuableHeader/ResuableHeader";

const SignIn = ({ title = "" }) => {
  // console.log('ererjer');
  // window.gtag('send', 'event', {
  //   eventCategory: 'Test Link',
  //   eventAction: 'Page',
  //   eventLabel: 'ritesh Visnwakarm',
  // });

  document.title = title;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileno, setMobileno] = useState("");
  const [error, setError] = useState("");
  const [termsAndCondition, setTermsAndCondition] = useState(true);
  const [successMsg, setSuccessMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const { data, status, otpStatus } = useSelector((state) => state.users);

  // console.log('data', data, status);
  // console.log(otpStatus);

  useEffect(() => {
    if (otpStatus.status) {
      // setLoader(true);
      // console.log(otpStatus.status)
      if (otpStatus.status) {
      }
      if (otpStatus.status !== "success") {
        setSuccessMsg(otpStatus.message);
        if (otpStatus.message) setLoader(false);
      } else {
        navigate("/verification", { replace: true });
      }
    }
    return () => {
      dispatch(resetLogin());
    };
  }, [otpStatus.status, otpStatus.message, dispatch, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateMobileNumber(mobileno)) {
      setLoader(true);
      dispatch(sendOtp({ mobile: mobileno }));
      // setLoader(false);
    }
  };

  function validateMobileNumber(str) {
    if (/^[6-9]\d{9}$/gi.test(str)) {
      return true;
    }
    setError(
      "Please add your 10 digit mobile number, we promise not to spam you. "
    );
  }

  const handleOnChange = (e) => {
    const reg = /^[0-9\b]+$/;
    if (e.target.value === "" || reg.test(e.target.value)) {
      setMobileno(e.target.value);
      setError("");
    }
  };

  useEffect(() => {
    if (mobileno.length === 10 && termsAndCondition === true) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
      setSuccessMsg("");
    }
  }, [mobileno, termsAndCondition]);

  // console.log(loader);

  const mobileFieldd =
    !error && !successMsg ? Style["text-boxes"] : Style["text-box-error"];
  const mobileField = !error
    ? Style["text-boxes"]
    : !successMsg
    ? Style["show-error"]
    : Style["text-boxes"];

  const [show, setShow] = useState(false);

  const tnc = () => {
    setShow(true);
    // console.log('tnc');
  };

  useEffect(() => {
    if (localStorage.getItem("evtoken")) {
      return navigate("/evinindia", { replace: true });
    } else {
      dispatch({ type: "USER_LOGOUT" });
      localStorage.removeItem("evtoken");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="back_click"
        onClick={() => navigate("/")}
        style={{ padding: "20px 10px 0px 24px" }}
      >
        <img src={backArrow} alt="Back-Icon" />
      </div>
      <main className={Style["signup-main"]}>
        <section className={Style["create-account-main"]}>
          <p className={Style["create-text"]}>Welcome</p>
          <p className={Style["pleasefill-text"]}>
            Please enter 10 digit mobile number
          </p>
        </section>
        <section className={Style["input-boxes-main"]}>
          <span className={mobileFieldd}>
            <input
              type="tel"
              className={Style["input-boxes"]}
              placeholder="Enter Mobile Number"
              required
              pattern="\d*"
              autoComplete="off"
              maxLength={10}
              autoFocus
              id="login-input"
              value={mobileno}
              onChange={handleOnChange}
            />
          </span>

          <section className={Style["terms-section"]}>
            <input
              type="checkbox"
              className={Style["terms-check"]}
              onChange={(e) => setTermsAndCondition(!termsAndCondition)}
              checked={termsAndCondition}
            />
            <p className={Style["terms-text"]}>
              I agree to &nbsp;
              <span className={Style["terms-conditions"]} onClick={() => tnc()}>
                Terms & Conditions
              </span>
              &nbsp; of this App.
            </p>
          </section>
          {(error && <p className={Style["errorMessage"]}>{error}</p>) ||
            (successMsg && (
              <p className={Style["errorMessage"]}>{successMsg}</p>
            ))}
        </section>

        <section>
          {btnDisabled ? (
            <button
              onClick={(e) => console.log("btn-clicked")}
              className={Style["btn-disabled"]}
              disabled
            >
              Continue
            </button>
          ) : (
            <button
              onClick={(e) => {
                if (!loader) {
                  handleSubmit(e);
                }
              }}
              id="login-button"
              className={Style["btn-continue"]}
            >
              {loader ? <Loader /> : "Continue"}
            </button>
          )}
        </section>

        {show && (
          <TermsCondition
            header={termsHeader}
            content={termsContent}
            setShow={setShow}
          />
        )}
      </main>
    </>
  );
};

export default SignIn;
