import './Header.scss';
import logo from '../../Assets/Images/ev-logo.svg';

import userIcon from '../../Assets/Images/user.svg';
import mp from '../../Assets/Images/MP.svg';
import day from '../../Assets/Images/day.svg';
import Story from '../Story/Story';
import HdfcergoLogo from '../../Assets/Images/hdfcergo-logo.png';
import SideNav from '../../Assets/Images/SideNav.svg';
import StickyHeader from '../UI/StickyHeader';
import { useNavigate } from 'react-router-dom';
import SideMenu from "../../Components/sideMenu/SideMenu"

const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <StickyHeader>
        <div className='header-wrapper'>
          <section className='main-header'>
            <SideMenu />
            <div className='logo'>
              <img src={logo} className='header-logo' alt='HDFC-Ergo-logo' />
            </div>
          </section>
        </div>
      </StickyHeader>
    </>
  );
};

export default Header;
