import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./EChallan.scss";
import { useNavigate } from "react-router-dom";
import echallan from "../../Assets/Images/echallan_status.svg";
import arrow from "../../Assets/Images/Chevron_right.svg";
import arrowbg from "../../Assets/Images/Chevron_right_BG.svg";
import ResuableHeader from "../ResuableHeader/ResuableHeader";
import { InputAdornment, FormControl, OutlinedInput } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { geteChallan } from "../../slice/e-challanSlice";
import { geteVehicleInfo } from "../../slice/vehicleInfoSlice";
import StickyHeader from "../UI/StickyHeader";
import backArrow from "../../Assets/Images/arrow-back.svg";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        },
      },
    },
  },
});

const EChallan = ({ title = "" }) => {
  document.title = title;
  const nevigate = useNavigate();
  // const evdetails = localStorage.getItem("evdetails");
  // const { grNumber } = evdetails
  //   ? JSON.parse(evdetails)
  //   : { grNumber: undefined };
  const { grNumber } = useSelector((state) => state.userDetails.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(grNumber ? false : true);
  const [vehicleRegno, setVehicleRegno] = useState(grNumber);

  const handleOnChange = (e) => {
    let value = e.target.value.toUpperCase();
    setVehicleRegno(value);
    if (
      /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$/.test(value) ||
      /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/.test(value)
    ) {
      setErrorMessage("");
      setBtnDisabled(false);
    } else {
      setErrorMessage("Please enter valid vehicle number");
      setBtnDisabled(true);
    }
  };

  const handleSubmit = () => {
    if (btnDisabled === false) {
      const recentData = localStorage.getItem("recenteChallanSearch");
      localStorage.setItem("recenteChallanSearch", [vehicleRegno, recentData]);
      nevigate(`/e-challan-status`, { state: { regNo: vehicleRegno } });
      // nevigate(`/findelectricvehicle`)
    } else if (vehicleRegno === "") {
      setErrorMessage("Please enter valid vehicle number");
    } else {
      setErrorMessage("Please enter valid vehicle number");
    }
  };

  const handleRecentSubmit = (item) => {
    const recentData = localStorage.getItem("recenteChallanSearch");
    localStorage.setItem("recenteChallanSearch", [item, recentData]);
    nevigate(`/e-challan-status`, { state: { regNo: item } });
    // nevigate(`/findelectricvehicle`)
  };

  const recentSearch = localStorage.getItem("recenteChallanSearch")?.split(",");
  const topRecentSearch = Array.from(new Set(recentSearch)).filter(
    (e) => e !== ""
  );

  return (
    <div className="vehicle-wrapper">
      {/* <ResuableHeader
        header="e-Challan status"
        url="/dashboard"
        bgc="#115173"
        headerDesc="Find the charging stations on the way"
      /> */}
      <StickyHeader>
        <section className="header-container">
          <div
            className="back-arrow"
            onClick={() => {
              nevigate("/evinindia");
            }}
          >
            <img src={backArrow} alt="Back-Icon" />
          </div>
          <div className="heading-text">
            <p>e-Challan status</p>
          </div>
        </section>
      </StickyHeader>
      <div className="title">View pending traffic e-challans</div>
      <div className="location-box">
        <section className="location-form">
          <form
            className="form-container"
            action="/"
            method="post"
            acceptCharset="UTF-8"
          >
            <label className="sub-label" htmlFor="destination-location">
              Enter Vehicle Registration No
            </label>

            <FormControl variant="outlined" className="registration_input">
              <ThemeProvider theme={theme}>
                <OutlinedInput
                  className="registration_input"
                  id="outlined-adornment-weight"
                  placeholder="Ex:RJ13CC1234"
                  name="setVehicleRegno"
                  value={vehicleRegno}
                  onChange={handleOnChange}
                  endAdornment={
                    <InputAdornment position="end" onClick={handleSubmit}>
                      <img src={arrowbg} alt="Charge" />
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  style={{ backgroundColor: "#242424" }}
                  size="small"
                />
              </ThemeProvider>
              <div className="error_message">{errorMessage}</div>
            </FormControl>
          </form>
        </section>
      </div>

      {topRecentSearch?.length >= 1 ? (
        <div className="location-box">
          <section className="location-form">
            <form
              className="form-container"
              action="/"
              method="post"
              acceptCharset="UTF-8"
            >
              <label className="sub-label" htmlFor="destination-location">
                Recent search
              </label>

              <FormControl variant="outlined">
                {topRecentSearch?.slice(0, 3).map((item, i) => {
                  return (
                    <>
                      <OutlinedInput
                        key={i}
                        className="registration_input"
                        id="outlined-adornment-weight"
                        placeholder="MH-04-U-8271"
                        value={item}
                        disabled
                        endAdornment={
                          <InputAdornment position="end">
                            <img src={arrow} alt="Charge" />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        style={{ backgroundColor: "#242424" }}
                        size="small"
                        onClick={() => handleRecentSubmit(item)}
                      />
                      <br />
                    </>
                  );
                })}
              </FormControl>
            </form>
          </section>
        </div>
      ) : null}

      <div className="other-service">
        <div className="other-service-title">Other Services</div>
        <div
          className="e-challan-satus"
          onClick={() => nevigate("/checkvehicleinfo")}
        >
          <div className="e-challan-icon">
            <img src={echallan} alt="Charge" style={{ width: "70%" }} />
          </div>
          <div className="e-challan-item">
            <div>Check Vehicle Info</div>
            <div>View vehicle info with registration no.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EChallan;
