import battery from "../Assets/Images/battery.svg";
import nearbyStn from "../Assets/Images/NBS.svg";
import planningTrip from "../Assets/Images/PAT.svg";
import earn from "../Assets/Images/Earn.svg";
import install from "../Assets/Images/Install.svg";
import CE from "../Assets/Images/CE.svg";
import bike from "../Assets/Images/bike.svg";
import findVehicle from "../Assets/Images/find_vehicle.svg";
import costOFVehicle from "../Assets/Images/Cost_Of_Vehicle.svg";
import kazamThree from "../Assets/Images/kazam 3.3.png";
import kazamMini from "../Assets/Images/kazam-mini.png";
import VehicleInfo from "../Assets/Images/VehicleInfo.svg";
import Rto from "../Assets/Images/Rto.svg";
import CheckVehicle from "../Assets/Images/Check_Vehicle_Info.svg";
import echallan from "../Assets/Images/echallan_status.svg";
import loanBanner from "../Assets/Images/Auto-Loan-Banner.png";

export const dashboardItems = {
  item1: {
    heading: "Charge It Up",
    headingImg: battery,
    cardImg1: nearbyStn,
    cardImg2: planningTrip,
    to1: "/evchargingstations",
    cardHeading1: "Nearby Stations",
    cardPara1: "Find the nearest station to charge your vehicle",
    to2: "/planatrip",
    cardHeading2: "Planning a Trip?",
    cardPara2: "Find the charging stations on the way",
    backgroundClr: "#022C43",
    cardBackground1: "#115173",
    cardBackground2: "#053F5E",
  },
  item2: {
    heading: "Earn Through EV",
    headingImg: earn,
    cardImg1: install,
    cardImg2: CE,
    to1: "/install",
    cardHeading1: "Install",
    cardPara1: "Setup charging units at your own place",
    to2: "/earningcalculator",
    cardHeading2: "Calculate Earnings",
    cardPara2: "Know your returns on setting up a unit",
    backgroundClr: "#120026",
    cardBackground1: "#4D0D80",
    cardBackground2: "#2F0E53",
  },
  item3: {
    heading: "Planning to buy EV?",
    headingImg: bike,
    cardImg1: findVehicle,
    cardImg2: costOFVehicle,
    cardImg3: loanBanner,
    to1: "/findelectricvehicle",
    cardHeading1: "Find Vehicle",
    cardPara1: "Check out the vehicle of your choice",
    to2: "/tcocalculator",
    cardHeading2: "Find cost of vehicle",
    cardPara2: "Know rough estimate of owning an EV",
    backgroundClr: "#001616",
    cardBackground1: "#044343",
    cardBackground2: "#062121",
  },
  item4: {
    heading: "RTO Services",
    headingImg: Rto,
    cardImg1: CheckVehicle,
    cardImg2: echallan,
    to1: "/checkvehicleinfo",
    cardHeading1: "Check Vehicle Info",
    cardPara1: "View vehicle info with registration no.",
    to2: "/e-challan",
    cardHeading2: "e-Challan Status",
    cardPara2: "View panding traffic e-challans",
    backgroundClr: "#001616",
    cardBackground1: "#C43D3D",
    cardBackground2: "#892A2A",
  },
};

export const productContent = [
  {
    productImg: kazamThree,
    productName: "kazam 3.3",
    productRate: "₹ 14,000.00",
    listOne: "3.3 KW AC Smart Charging Station",
    listTwo: "SIM Based IOT Enabled",
    listThree: "Supports: Electric Car, Electric Bike, Electric Scooter",
    listFour: "3 Pinpoint, 16A Socket Charger",
    moreDetailLink: "/kazamthree",
    removehr: true,
  },
  {
    productImg: kazamMini,
    productName: "kazam Mini",
    productRate: "₹ 5,500.00",
    listOne: "3.3 KW AC Smart Charging Station",
    listTwo: "Wi-fi Based IOT Enabled",
    listThree: "Supports: Electric Car, Electric Bike, Electric Scooter",
    listFour: "3 Pinpoint, 16A Socket Charger, GSM(optional)",
    moreDetailLink: "/kazammini",
    removehr: false,
  },
];
