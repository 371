import React, { useState } from "react";
import "./CheckVehicleInfo.scss";
import { useNavigate } from "react-router-dom";
import echallan from "../../Assets/Images/echallan_status.svg";
import arrow from "../../Assets/Images/Chevron_right.svg";
import arrowbg from "../../Assets/Images/Chevron_right_BG.svg";
import ResuableHeader from "../ResuableHeader/ResuableHeader";
import { HandleScrollToTop } from "../../APIs/CommonLogics";
import { InputAdornment, FormControl, OutlinedInput } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import StickyHeader from "../UI/StickyHeader";
import backArrow from "../../Assets/Images/arrow-back.svg";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        },
      },
    },
  },
});

const CheckVehicleInfo = ({ title = "" }) => {
  // const evdetails = localStorage.getItem("evdetails");
  // const { grNumber } = evdetails
  //   ? JSON.parse(evdetails)
  //   : { grNumber: undefined };
  const { grNumber } = useSelector((state) => state.userDetails.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(grNumber ? false : true);
  const [vehicleRegno, setVehicleRegno] = useState(grNumber);
  document.title = title;
  HandleScrollToTop();
  const nevigate = useNavigate();

  const handleOnChange = (e) => {
    let value = e.target.value.toUpperCase();
    setVehicleRegno(value);
    if (
      /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$/.test(value) ||
      /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/.test(value)
    ) {
      setErrorMessage("");
      setBtnDisabled(false);
    } else {
      setErrorMessage("Please enter valid vehicle number");
      setBtnDisabled(true);
    }
  };

  const handleSubmit = () => {
    if (btnDisabled === false) {
      const recentData = localStorage.getItem("recentVehicleSearch");
      localStorage.setItem("recentVehicleSearch", [vehicleRegno, recentData]);
      nevigate(`/checkRegistrationDetails`, { state: { regNo: vehicleRegno } });
      // nevigate(`/findelectricvehicle`)
    } else if (vehicleRegno === "") {
      setErrorMessage("Please enter valid vehicle number");
    } else {
      setErrorMessage("Please enter valid vehicle number");
    }
  };
  const handleRecentSubmit = (item) => {
    const recentData = localStorage.getItem("recentVehicleSearch");
    localStorage.setItem("recentVehicleSearch", [item, recentData]);
    nevigate(`/checkRegistrationDetails`, { state: { regNo: item } });
  };

  const recentSearch = localStorage.getItem("recentVehicleSearch")?.split(",");
  const topRecentSearch = Array.from(new Set(recentSearch)).filter(
    (e) => e !== ""
  );

  return (
    <>
      {/* <ResuableHeader
        header="Check Vehicle Info"
        url="/dashboard"
        bgc="#115173"
        headerDesc="Find the charging stations on the way"
      /> */}
      <StickyHeader>
        <section className="header-container">
          <div
            className="back-arrow"
            onClick={() => {
              nevigate("/evinindia");
            }}
          >
            <img src={backArrow} alt="Back-Icon" />
          </div>
          <div className="heading-text">
            <p>Check Vehicle Info</p>
          </div>
        </section>
      </StickyHeader>
      <div className="vehicle-wrapper">
        <div className="title">View vehicle info with registration no.</div>
        <div className="location-box">
          <section className="location-form">
            <form
              className="form-container"
              action="/"
              method="post"
              acceptCharset="UTF-8"
            >
              <label className="sub-label" htmlFor="destination-location">
                Enter Vehicle Registration No
              </label>
              <FormControl variant="outlined">
                <ThemeProvider theme={theme}>
                  <OutlinedInput
                    className="registration_input"
                    id="outlined-adornment-weight"
                    placeholder="Ex:RJ13CC1234"
                    name="setVehicleRegno"
                    value={vehicleRegno}
                    onChange={handleOnChange}
                    required
                    endAdornment={
                      <InputAdornment position="end" onClick={handleSubmit}>
                        <img src={arrowbg} alt="Charge" />
                      </InputAdornment>
                    }
                    style={{ backgroundColor: "#242424" }}
                    size="small"
                  />
                </ThemeProvider>
                <div className="error_message">{errorMessage}</div>
              </FormControl>
            </form>
          </section>
        </div>

        {topRecentSearch?.length >= 1 ? (
          <div className="location-box">
            <section className="location-form">
              <form
                className="form-container"
                action="/"
                method="post"
                acceptCharset="UTF-8"
              >
                <label className="sub-label" htmlFor="destination-location">
                  Recent search
                </label>
                <FormControl variant="outlined">
                  {topRecentSearch?.slice(0, 3).map((item, i) => {
                    return (
                      <>
                        <OutlinedInput
                          key={i}
                          className="registration_input"
                          id="outlined-adornment-weight"
                          value={item}
                          disabled
                          endAdornment={
                            <InputAdornment position="end">
                              <img src={arrow} alt="Charge" />
                            </InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          style={{ backgroundColor: "#242424" }}
                          size="small"
                          onClick={() => handleRecentSubmit(item, i)}
                        />
                        <br />
                      </>
                    );
                  })}
                </FormControl>
              </form>
            </section>
          </div>
        ) : null}

        <div className="other-service">
          <div className="other-service-title">Other Services</div>
          <div
            className="e-challan-satus"
            onClick={() => nevigate("/e-challan")}
          >
            <div className="e-challan-icon">
              <img src={echallan} alt="Charge" style={{ width: "70%" }} />
            </div>
            <div className="e-challan-item">
              <div>e-Challan Status</div>
              <div>View pending traffic e-challans</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckVehicleInfo;
