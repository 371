import "./insight-section.scss";
import scooter from "../../Assets/Images/scooter.svg";
import order from "../../Assets/Images/order.svg";
import brake from "../../Assets/Images/brake.png";
import tyre from "../../Assets/Images/tyre.png";
import carBattery from "../../Assets/Images/car-battery.png";
import arrowBlack from "../../Assets/Images/arrow-black.svg";
import arrowWhite from "../../Assets/Images/arrow-white.svg";
import insight from "../../Assets/Images/insight.svg";
import { useNavigate } from "react-router-dom";

const Clickable = () => {
  const navigate = useNavigate();

  const waitBeforeShow = (path) => {
    setTimeout(() => {
      navigate(path);
    }, 500);
  };

  return (
    <>
      <div className="clickable-wrapper" id="Insights">
        <section className="text-headers">
          <div className="insight-text">
            <img className="insight-img" src={insight} alt="" />
            <span className="insight-text-sub">Insights</span>
          </div>

          {/* <div
            className='view-all-text '
            onClick={() => waitBeforeShow('/blogs')}
          >
            View all
          </div> */}
        </section>
        <section className="click-card-container clickable-cards">
          <div
            className="click-card-one "
            onClick={() => waitBeforeShow("/taxbenefitsofev")}
          >
            <div className="click-card-one-para">
              Know tax benefits
              <br />
              of purchasing EV
              <span className="display-arrow">
                <img src={arrowBlack} alt="Back-Arrow-Icon" />
              </span>
            </div>
            <img src={scooter} className="inner_image_one" alt="Scooter" />
          </div>
          <div
            className="click-card-two "
            onClick={() => waitBeforeShow("/state")}
          >
            <div className="click-card-two-para">
              State wise benefits
              <br />
              and regulations
              <span className="display-arrow">
                <img src={arrowWhite} alt="Back-Arrow-Icon" />
              </span>
            </div>
            <img src={order} className="inner_image_two" alt="Order" />
          </div>

          <div
            className="click-card-three "
            onClick={() => waitBeforeShow("/battery")}
          >
            <div className="click-card-three-para">
              Battery
              <br />
              Maintenance Tips
              <span className="display-arrow">
                <img src={arrowWhite} alt="Back-Arrow-Icon" />
              </span>
            </div>
            <img
              src={carBattery}
              className="inner_image_three"
              alt="Car-Battery"
            />
          </div>

          <div
            className="click-card-four "
            onClick={() => waitBeforeShow("/break")}
          >
            <div className="click-card-four-para">
              Brake
              <br />
              Maintenance Tips
              <span className="display-arrow">
                <img src={arrowWhite} alt="Back-Arrow-Icon" />
              </span>
            </div>
            <img src={brake} className="inner_image_four" alt="Brake-Kit" />
          </div>

          <div
            className="click-card-five "
            onClick={() => waitBeforeShow("/wheel")}
          >
            <div className="click-card-five-para">
              Wheel/Tire
              <br />
              Maintenance Tips
              <span className="display-arrow">
                <img src={arrowWhite} alt="Back-Arrow-Icon" />
              </span>
            </div>
            <img src={tyre} className="inner_image_five" alt="Wheel" />
          </div>

          {/* <div
            className='click-card-six '
            onClick={() => waitBeforeShow('/blogs')}
          >
            <div className='click-card-six-para'>
              View all other content
              <span className='display-arrow'>
                <img src={arrowWhite} alt='Back-Arrow-Icon' />
              </span>
            </div>
          </div> */}
        </section>
      </div>
    </>
  );
};

export default Clickable;
