import React, { useState, useEffect, useCallback } from "react";
import "./Location.scss";
import location_icon from "../../Assets/Images/location_icon1.svg";
import ResuableHeader from "../ResuableHeader/ResuableHeader";
import LocationCard from "../LocationCard/LocationCard";
import NearBy from "../../Assets/Images/NBS.svg";
import { encrypt, decrypt } from "./../../service/encryption";
import axios from "axios";
import Loader from "../Loader/Loader";
import CarLoader from "../Loader/CarLoader";

import AutoComplete from "react-google-autocomplete";
import closeRed from "../../Assets/Images/Close-icon.svg";

let currentAddress = { formatted_address: "" };
const Location = ({ title = "Nearby Stations" }) => {
  document.title = title;

  const [message, setMessage] = useState("Loading...");

  const showBlock = useCallback(async (sessionLocation, pincode) => {
    // alert(pincode)

    if (typeof sessionLocation === "string" || pincode) {
      let requestData = pincode ? { pincode } : JSON.parse(sessionLocation);
      // console.log({ requestData });
      if ((requestData && requestData.lat && requestData.lng) || pincode) {
        const encryptedLocation = encrypt(JSON.stringify(requestData));
        // console.log(encryptedLocation);

        const locationAddressUrl =
          process.env.REACT_APP_GOOGLE_lOCATION_API + "location-address";
        const authorization = localStorage.getItem("GSauthToken");
        // if (!currentAddress.formatted_address) {
        // getting the location
        axios
          .post(
            locationAddressUrl,
            { ...encryptedLocation },
            {
              headers: {
                token: decrypt(JSON.parse(authorization)),
              },
            }
          )
          .then((response) => {
            currentAddress = JSON.parse(decrypt(response.data.data));
            // console.log(currentAddress.formatted_address);
            if (pincode) {
              getNearByStation(encrypt(JSON.stringify(currentAddress)));
            }
            setPassLocation(currentAddress.formatted_address);
          })
          .catch((error) => {
            setPassCardLocation(null);
            setMessage("Opps! Something went wrong.");
            // console.log(error);
          });
        if (!pincode) {
          getNearByStation(encryptedLocation);
        }
        // }
      }
    }
  }, []);
  const showPosition = useCallback(
    (position) => {
      // console.log(position);

      window.sessionStorage.setItem(
        "user_location",
        JSON.stringify({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      );

      let sessionLocation = window.sessionStorage.getItem("user_location");

      showBlock(sessionLocation);
      // history.push('/location')
    },
    [showBlock]
  );

  const getLocation = useCallback(
    ({ force = true }) => {
      let sessionLocation = window.sessionStorage.getItem("user_location");
      if (!sessionLocation || force) {
        // console.log('taking location');
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            showPosition,

            (error) => {
              // console.log(error);
              if (error.code === error.PERMISSION_DENIED)
                // console.log('you denied me :-');
                console.log("show pincode promt");
              let pincode = prompt("Please enter your pincode");
              {
                console.log("pincode", pincode);
              }
              if (pincode === null || pincode === "") {
                // alert("Please enter your location");
                setMessage(null);
              } else {
                // alert(pincode)
                showBlock(null, pincode);
              }
            }
          );
        } else {
          window.alert("Location is not supported by this browser");
        }
      } else {
        showBlock(sessionLocation);
      }
    },
    [showBlock, showPosition]
  );

  useEffect(() => {
    // we have location or not
    // if yes populate data else ask location
    getLocation({ force: false });
  }, [getLocation]);

  const [passLocation, setPassLocation] = useState("");
  const [passCardLocation, setPassCardLocation] = useState(null);

  function getNearByStation(encryptedLocation) {
    const evListUrl = process.env.REACT_APP_GOOGLE_lOCATION_API + "ev-stations";
    const authorization = localStorage.getItem("GSauthToken");
    axios
      .post(
        evListUrl,
        { ...encryptedLocation },
        {
          headers: {
            token: decrypt(JSON.parse(authorization)),
          },
        }
      )
      .then((response) => {
        setMessage(null);
        let evList = JSON.parse(decrypt(response.data.data));
        setPassCardLocation(evList.results);
        document.title = title;
      })
      .catch((error) => {
        setPassCardLocation(null);
        setMessage("Opps! Something went wrong.");
        // console.log(error);
      });
  }

  const setLongitude = () => {};
  const setLatitude = () => {};

  const onBlur = () => {
    // console.log('blurred');
    setPassLocation("");
    // alert(passLocation);
  };

  return (
    <>
      <div className="location-wrapper">
        <ResuableHeader
          header="Nearby EV Charging Stations"
          url="/dashboard"
          cardImg={NearBy}
          bgc="#115173"
          headerDesc="Find the nearest station to charge your vehicle"
          cardYes={true}
        />

        <section className="location-displayer">
          <div className="current_location">
            <AutoComplete
              apiKey={`${process.env.REACT_APP_AUTOCOMPLETE}`}
              onPlaceSelected={(place) => {
                console.clear();
                window.sessionStorage.setItem(
                  "user_location",
                  JSON.stringify({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  })
                );
                let sessionLocation =
                  window.sessionStorage.getItem("user_location");
                showBlock(sessionLocation);
              }}
              name="passLocation"
              // defaultValue={passLocation}
              value={passLocation}
              className="current_location_input"
              // useRef={textInput}
              // onChange={e => {
              //   setType(e.target.value);
              // }}
              autocomplete="off"
              options={{
                types: ["(regions)"],
                componentRestrictions: { country: "IN" },
              }}
              onChange={(e) => {
                // e.stopPropagation();
                // e.preventDefault();
                // onFocus();
                setPassLocation(e.target.value);
                // console.log('her');
              }}

              // onBlur={() => {
              //   onBlur();
              // }}
            />
          </div>
          <div
            className="location-icon"
            onClick={passLocation.length > 0 ? onBlur : getLocation}
          >
            <img
              src={passLocation.length > 0 ? closeRed : location_icon}
              alt="Location-Icon"
            />
          </div>
        </section>
        <div
          className={
            message === null && !passCardLocation ? "alterMessage" : "alterhide"
          }
        >
          Plaese enter your location/Pincode
        </div>
        <div className={message ? "errorMsg" : null}>
          {message === "Loading..." ? <CarLoader /> : message}
        </div>
        {console.log("passCardLocation", passCardLocation)}
        {passCardLocation ? <LocationCard locater={passCardLocation} /> : null}
      </div>
    </>
  );
};

export default Location;
