import Andhra_Pradesh_EVPolicy1 from '../Assets/Images/AndhraPradesh_1.png';
import Andhra_Pradesh_EVPolicy2 from '../Assets/Images/AndhraPradesh_2.png';
import Andhra_Pradesh_EVPolicy3 from '../Assets/Images/AndhraPradesh_3.png';
import Andhra_Pradesh_EVPolicy4 from '../Assets/Images/AndhraPradesh_4.png';

import Delhi_EVPolicy1 from '../Assets/Images/Delhi_1.png';
import Delhi_EVPolicy2 from '../Assets/Images/Delhi_2.png';
import Delhi_EVPolicy3 from '../Assets/Images/Delhi_3.png';
import Delhi_EVPolicy4 from '../Assets/Images/Delhi_4.png';

import Gujarat1 from '../Assets/Images/Gujarat_1.png';
import Gujarat2 from '../Assets/Images/Gujarat_2.png';
import Gujarat3 from '../Assets/Images/Gujarat_3.png';
import Gujarat4 from '../Assets/Images/Gujarat_4.png';

import Karnataka1 from '../Assets/Images/Delhi_1.png';
import Karnataka2 from '../Assets/Images/Delhi_3.png';
import Karnataka3 from '../Assets/Images/Karnataka_1.png';

import Kerala1 from '../Assets/Images/Delhi_1.png';
import Kerala2 from '../Assets/Images/AndhraPradesh_3.png';
import Kerala3 from '../Assets/Images/Kerala_1.png';
import Kerala4 from '../Assets/Images/Kerala_2.png';

import MP1 from '../Assets/Images/AndhraPradesh_1.png';
import MP2 from '../Assets/Images/AndhraPradesh_3.png';
import MP3 from '../Assets/Images/AndhraPradesh_4.png';
import MP4 from '../Assets/Images/MP_1.png';

import MH1 from '../Assets/Images/Karnataka_1.png';
import MH2 from '../Assets/Images/MH_2.png';
import MH3 from '../Assets/Images/MH_1.png';
import MH4 from '../Assets/Images/Gujarat_2.png';

import Rajastan1 from '../Assets/Images/Gujarat_2.png';
import Rajastan2 from '../Assets/Images/MH_1.png';
import Rajastan3 from '../Assets/Images/Rajastan_1.png';

import TN1 from '../Assets/Images/MH_1.png';
import TN2 from '../Assets/Images/Gujarat_2.png';
import TN3 from '../Assets/Images/TN1.png';
import TN4 from '../Assets/Images/Rajastan_1.png';

import Telangana1 from '../Assets/Images/Gujarat_2.png';
import Telangana2 from '../Assets/Images/AndhraPradesh_4.png';
import Telangana3 from '../Assets/Images/Gujarat_4.png';
import Telangana4 from '../Assets/Images/Telungana_1.png';

import UP1 from '../Assets/Images/Gujarat_2.png';
import UP2 from '../Assets/Images/Kerala_1.png';
import UP3 from '../Assets/Images/AndhraPradesh_4.png';
import UP4 from '../Assets/Images/AndhraPradesh_3.png';

import closeYellow from '../Assets/Images/close-yellow.svg';

export const statesDetails = [
  {
    state: 'Andhra Pradesh',
    stateId: 1,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara:
          'Phase out all fossil fuel based commercial fleets and logistic vehicles in top 4 cities by 2024 and in all cities by 2030',
        taxImg: Andhra_Pradesh_EVPolicy1,
        // stateDesc: 'Subsidy of ₹5000/kWh of battery capacity on Electric two wheeler purchase'
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'All sorts of government vehicles will be converted to EV by 2024',
        taxImg: Andhra_Pradesh_EVPolicy2,
        // stateDesc: 'Subsidy of ₹5000/kWh of battery capacity on Electric two wheeler purchase'
      },
      {
        cancelIcon: closeYellow,
        taxPara: '1,00,000 slow and fast charging stations in state by 2024',
        taxImg: Andhra_Pradesh_EVPolicy3,
        // stateDesc: 'Subsidy of ₹5000/kWh of battery capacity on Electric two wheeler purchase'
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'Capital subsidies available for manufacturing firms & private charging stations',
        taxImg: Andhra_Pradesh_EVPolicy4,
        // stateDesc: 'Subsidy of ₹5000/kWh of battery capacity on Electric two wheeler purchase',
        check: true,
      },
    ],
  },
  {
    state: 'Delhi',
    stateId: 2,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara:
          'Subsidy of ₹5000/kWh of battery capacity on Electric two wheeler purchase',
        taxImg: Delhi_EVPolicy1,
        stateDesc:
          'Maximum limit of subsidy for Electric two wheeler is ₹30,000',
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'Subsidy of ₹10,000/kWh of battery capacity on Electric four wheeler purchase',
        taxImg: Delhi_EVPolicy2,
        stateDesc:
          'Maximum limit of subsidy for Electric two wheeler is ₹1,50,000',
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Save more by scraping your old petrol two wheeler',
        taxImg: Delhi_EVPolicy3,
        stateDesc:
          '₹5000 subsidy on new electric two wheeler purchase in exchange of old petrol two wheeler',
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Exempted from road tax and registration costs',
        taxImg: Delhi_EVPolicy4,
        // stateDesc: 'Incentives to the developers of private EV parks & clusters with plug and play facilities',
        check: true,
      },
    ],
  },
  {
    state: 'Gujarat',
    stateId: 3,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara: 'Subsidy of ₹10,000/kWh of battery capacity on EV purchase',
        taxImg: Gujarat1,
        stateDesc:
          'Maximum Limit of subsidy on an electric two-wheeler & four-wheeler are Rs. 20,000 and Rs. 1,50,000 respectively.',
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'Exemption on registration fee for electric car owners substantially reducing cost of ownership',
        taxImg: Gujarat2,
        stateDesc:
          '',
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'Capital subsidies of upto 25% to those who construct commercial public EV charging stations',
        taxImg: Gujarat3,
        stateDesc:
          'Available for the first 250 commercial public Electric Vehicle charging stations [ Limited to Rs. 10 lakh per station].',
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          "All residential and commercial companies to issue a 'No Objection Certificate' (NOC) to members wishing to build charging stations and provide parking spaces",
        taxImg: Gujarat4,
        check: true,
      },
    ],
  },
  {
    state: 'Karnataka',
    stateId: 4,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara: '15% capital subsidy to investors in electric vehicle sector',
        taxImg: Karnataka1,
        stateDesc:
          'Subsidy is on the value of fixed assets over five equal annual payments, with the maximum land covered being 50 acres',
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Start of electric bike taxi system',
        taxImg: Karnataka2,
        stateDesc: `<div><li>It allows aggregators such as Rapido, Ola, and Uber to register as e-bike taxi operators</li><li>It may help electric vehicles gain traction in the final mile transportation segment.</li></div>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          '50% of state government cars to be replaced with electric vehicles',
        taxImg: Karnataka3,
        check: true,
      },
    ],
  },
  {
    state: 'Kerala',
    stateId: 5,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara:
          '1 million Electric vehicles on road by 2022 across all categories',
        taxImg: Kerala1,
        stateDesc:
          '',
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Creating charging infrastructure',
        taxImg: Kerala2,
        stateDesc: `<div><li>Government will strengthen the charging infrastructure by the NTPC and EESL</li></div>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Manufacturing in the state',
        taxImg: Kerala3,
        stateDesc: `<div><ul><li>KSEB will ensure to meet the energy requirement for the project in the State</li><li>Various financial and regulatory benefits for manufacturers</li><li>Govt. is working to make an e-Auto manufacturing facility under KAL, power distributors and energy system and storage providers</li></ul></div>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Awareness creation & promotion',
        taxImg: Kerala4,
        stateDesc: `<div><ul><li>Government plans to host EV expo</li><li>E-mobility zones at tourist villages/spots</li></ul></div>`,
        check: true,
      },
    ],
  },
  {
    state: 'Madhya Pradesh',
    stateId: 6,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara:
          'EV to contribute 25% of all new public transport vehicle registrations by 2026',
        taxImg: MP1,
        stateDesc:
          '',
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Regulatory Incentives & Parking cost',
        taxImg: MP2,
        stateDesc:
        
          '<div><li>All EV two wheelers & four wheelers are exempted from road tax & vehicle registration fee for 5 years.</li></div><div><li> 100% waiver on parking charges at any Municipal Corporation run parking facility for 5 years</li></div>',
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'Capital Subsidy of 25% of the value of the charging station equipment/machinery',
        taxImg: MP3,
        stateDesc: `<div>
          <div><u>Benefit applicable to:</u></div>
          <li>Small charging stations: First 300 stations [max upto Rs. 200000]</li>
          <li>Medium charging stations: First 100 stations [max upto Rs. 400000]</li>
          <li>Large charging stations: First 100 stations [max upto Rs. 1000000]</li>
          </div>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'Selected cities to stop registering new Internal combustion engine (ICE) autos',
        taxImg: MP4,
        check: true,
      },
    ],
  },
  {
    state: 'Maharashtra',
    stateId: 7,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara: 'Subsidy of ₹5000/kWh of battery capacity on EV purchase',
        taxImg: MH1,
        stateDesc:
          '',
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Save more by scraping your old petrol two wheeler',
        taxImg: MH2,
        stateDesc:
          '₹7000 subsidy on new electric two wheeler purchase in exchange of old petrol two wheeler',
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'Incentives for manufacturing companies on delivering 5 year battery warranty',
        taxImg: MH3,
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Exempted from road tax and registration costs',
        taxImg: MH4,
        check: true,
      },
    ],
  },
  {
    state: 'Rajasthan',
    stateId: 8,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara:
          'State goods and services tax (SGST) Refund for all EV purchased',
        taxImg: Rajastan1,
        stateDesc:
          '',
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'Subsidy upto Rs. 10,000 on electric two wheeler purchase as per battery capacity',
        taxImg: Rajastan2,
        stateDesc: `<table>
          <tbody>
          <tr >
          <td style="height: 15.0pt; width: 121pt;" >Battery Capacity</td>
          <td style="width: 121pt;" width="161">Subsidy</td>
          </tr>
          <tr >
          <td style="height: 15.0pt;" height="20">Up to 2 kWh</td>
          <td class="xl63" align="left">₹ 5,000</td>
          </tr>
            <tr >
          <td style="height: 15.0pt;" height="20">Up to 4 kWh</td>
          <td class="xl63" align="left">₹ 7,000</td>
          </tr>
            <tr >
          <td style="height: 15.0pt;" height="20">Up to 5 kWh</td>
          <td class="xl63" align="left">₹ 9,000</td>
          </tr>
          <tr >
          <td style="height: 15.0pt;" height="20">Over 5 kWh</td>
          <td class="xl63" align="left">₹ 10,000</td>
          </tr>
          </tbody>
          </table>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'Subsidy upto  Rs. 20,000 on electric three wheeler purchase as per battery capacity',
        taxImg: Rajastan3,
        stateDesc: `<table>
          <tbody>
          <tr >
          <td style="height: 15.0pt; width: 121pt;" >Battery Capacity</td>
          <td style="width: 121pt;" width="161">Subsidy</td>
          </tr>
          <tr >
          <td style="height: 15.0pt;" height="20">Up to 3 kWh</td>
          <td class="xl63" align="left">₹ 10,000</td>
          </tr>
            <tr >
          <td style="height: 15.0pt;" height="20">Up to 4 kWh</td>
          <td class="xl63" align="left">₹ 15,000</td>
          </tr>
          <tr >
          <td style="height: 15.0pt;" height="20">Up to 5 kWh</td>
          <td class="xl63" align="left">₹ 17,000</td>
          </tr>
          <tr >
          <td style="height: 15.0pt;" height="20">Over 5 kWh</td>
          <td class="xl63" align="left">₹ 20,000</td>
          </tr>
          </tbody>
          </table>`,
        check: true,
      },
    ],
  },
  {
    state: 'Tamil Nadu',
    stateId: 9,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara: '100% exemption from motor vehicle tax till december 2022',
        taxImg: TN1,
        stateDesc:
          '',
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          '100% exemption on stamp duty on the purchase of land for the production of EV & units of EV',
        taxImg: TN2,
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          '100% exemption on electricity tax for electric vehicle production units and charging infrastructure till December 2025.',
        taxImg: TN3,
      },
      {
        cancelIcon: closeYellow,
        taxPara:
          'All auto-rickshaws will be converted to EVs in six major cities by 2029',
        taxImg: TN4,
        check: true,
      },
    ],
  },
  {
    state: 'Telangana',
    stateId: 10,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara: '100% exemption of road tax and registration fee',
        taxImg: Telangana1,
        stateDesc: `<li>Benefit applicable for first 200000 electric two wheelers and first 5000 electric four wheeler private vehicles purchased & registered within Telangana</li>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Charging Infrastructure',
        taxImg: Telangana2,
        stateDesc: `<li>Charging/ swapping station for every 50 km within state boundaries on highway to major cities</li><li>Existing Residential Townships with 1000+ families shall be encouraged to develop charging stations lots</li>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Capital investment subsidy',
        taxImg: Telangana3,
        stateDesc: `<li>Subsidy of 20% of investment, capped at 30 Cr. for Mega Enterprises</li>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'SGST Reimbursement',
        taxImg: Telangana4,
        stateDesc: `<li>100% net SGST reimbursement capped at 5 Cr. per year with a cumulative cap of 25 Cr. over a period of 7 years for Mega Enterprises</li>`,
        check: true,
      },
    ],
  },
  {
    state: 'Uttar Pradesh',
    stateId: 11,
    stateIn: [
      {
        cancelIcon: closeYellow,
        taxPara: 'Tax exemptions',
        taxImg: UP1,
        stateDesc: `<li>100% road tax exemption for two wheeler and 75% for three & four wheelers with 100% reimbursement on electric vehicle registrations</li>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Incentives to manufacturing units',
        taxImg: UP2,
        stateDesc: `<div><ul><li>Reimbursement upto 25% of the cost of land for Mega Anchor Project & Ultra mega battery plant</li><li>Incentives on stamp duty to all new EV companies</li></ul></div>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Charging Infrastructure',
        taxImg: UP3,
        stateDesc: `<div><ul><li>20% Subsidy on investment, capped at 30 Cr. for Mega Enterprises.</li><li>50% Capital interest subsidy on fixed capital investment for setting up hydrogen generation and fuelling plants first 10 units [Land cost exclusion]</li></ul></div>`,
      },
      {
        cancelIcon: closeYellow,
        taxPara: 'Private EV parks',
        taxImg: UP4,
        stateDesc: `<ul><li>Incentives to the developers of private EV parks & clusters with plug and play facilities</li></ul>`,
        check: true,
      },
    ],
  },
];
