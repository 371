import "./LocationCard.scss";
import star from "../../Assets/Images/Icon ionic-ios-star.svg";
import direction from "../../Assets/Images/Icon-awesome-directions.svg";
import Nostation from "../NoStationFound/Nostation";
import ResuableHeader from "../ResuableHeader/ResuableHeader";
import { useLocation } from "react-router-dom";

const LocationCard = (props) => {
  document.title = props.title;

  const loc = useLocation();
  const data = props.locater ? props.locater : loc.state;
  return (
    <>
      {!props.locater ? (
        <ResuableHeader header="Stations on my Way" url="/planatrip" />
      ) : null}
      {data.length > 0 ? (
        data.map((locator) => (
          <section key={locator.place_id} className="location-cards">
            <div className="locationCardLeft">
              <div
                className="location-details"
                onClick={() => {
                  window.open(
                    `https://maps.google.com/?q=${locator.name}${locator.vicinity}`,
                    "_blank"
                  );
                }}
              >
                <div className="location-name">{locator.name}</div>
                <div className="location-address">
                  <p>{locator.vicinity}</p>
                </div>
              </div>
              <div className="location-info">
                <div className="location-sub-details">
                  <div className="km">
                    {locator.distance ? locator.distance : ""}
                  </div>
                  {locator.distance ? <span className="vertical"></span> : null}
                  <div className="timing">
                    {locator.opening_hours ? "Open" : "Closed"}
                  </div>
                  <span className="vertical"></span>
                  <div className="ratings">
                    {!locator.rating ? (
                      "NA"
                    ) : (
                      <div>
                        <img className="star-img" src={star} alt="star" />
                        <span className="rating-point">
                          {" " + locator.rating}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="locationCardRight"
              onClick={() => {
                const {
                  geometry: {
                    location: { lat, lng },
                  },
                } = locator;
                // alert("asdf")
                window.open(
                  `https://maps.google.com/?q=${lat},${lng}`,
                  "_blank"
                );
              }}
            >
              <img src={direction} alt="" />
            </div>
          </section>
        ))
      ) : (
        <Nostation />
      )}
    </>
  );
};

export default LocationCard;
