import { useState, useEffect } from 'react';

export const numberFormat = value => {
  let d = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    useGrouping: true,
  }).format(value);
  return d.replace('.00', '').replace('₹', '');
};

export const Count = props => {
  const { number, duration, intial, style } = props;
  const [count, setCount] = useState(intial ? intial : number - 100);
  useEffect(() => {
    let start = intial ? intial : number - 100;
    const len = number.toString().length;
    const end = parseInt(number);
    if (start === end) return;

    let totalMilSecDur = parseInt(duration);
    // let incrementTime = (totalMilSecDur / end) * 1000;
    let incrementTime = 1 * 15;
    let timer = setInterval(() => {
      start += 1;
      setCount(numberFormat(start.toString().padStart(len, '0')));
      if (start === end) clearInterval(timer);
    }, incrementTime);
  }, [number, duration]);

  return (
    <h1 className='Count' style={{ ...style }}>
      {count}
    </h1>
  );
};
