import { createSlice } from '@reduxjs/toolkit';

export const blog = createSlice({
  name: 'blog',
  initialState: {
    title:"Blogs", 
  },
  reducers: {
    changeTabs: (state, action) => {
      const { title } = action.payload;
      state.title = title;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTabs } = blog.actions;

export default blog.reducer;
