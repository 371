import './Nostation.scss';
import notFound from '../../Assets/Images/notfound.svg';

const Nostation = ({ title = '' }) => {
  document.title = title;
  return (
    <>
      <div className='no-station-wrapper'>
        <section className='no-station-found'>
          <div className='no-station-img'>
            <img src={notFound} alt='Charging-Station-Not-Found' />
          </div>
          <div className='no-station-para'>No EV stations found</div>
        </section>
      </div>
    </>
  );
};

export default Nostation;
