import React from 'react';
import './Loader.scss';

const Loader = ({
  text,
  size = 20,
  border = 2,
  color = '#E41E26',
  fColor = '#f3f3f3',
}) => {
  return (
    <>
      {/* <Skeleton count={5} baseColor='grey' highlightColor='lightgrey' width='90%' circle={true} />         */}
      {/* <div class="loader">Loading...</div> */}
      <div
        className='loader'
        style={{
          height: size + 'px',
          width: size + 'px',
          border: `${border}px solid ${fColor}`,
          borderBottom: `${border}px solid ${color}`,
          borderTop: `${border}px solid ${color}`,
          margin: `0 auto`,
        }}
      ></div>
    </>
  );
};

export default Loader;
