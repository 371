import { createSlice } from "@reduxjs/toolkit";

export const userAuth = createSlice({
  name: "auth",
  initialState: {
    token: null,
  },
  reducers: {
    setToken: (state, action) => {
      if (action.payload.token) {
        localStorage.setItem("evtoken", action.payload.token);
        state.token = action.payload.token;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken } = userAuth.actions;

export default userAuth.reducer;
