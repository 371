import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store, { persistor } from "./store";
import { Provider } from "react-redux";
import Desktop from "./Assets/Images/landing-page.jpg";
import { PersistGate } from "redux-persist/integration/react";

if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    // <div>Sorry, this website is only available on desktop devices.</div>,
    <img
      src={Desktop}
      alt="Sorry, this website is only available on desktop devices"
      className="desktop-img"
    />,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

serviceWorkerRegistration.register();
