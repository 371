import Style from './TermsCondition.module.scss';
import backArrow from '../../Assets/Images/arrow-back.svg';

const TermsCondition = ({ header, content, setShow }) => {
  const toggle = () => {
    setShow(false);
  };

  return (
    <>
      <main className={Style['terms-mainn']}>
        <section className={Style['terms-header']}>
          <span
            className={Style['btn-close']}
            onClick={() => {
              toggle();
            }}
          >
            <img src={backArrow} alt='close' />
          </span>
        </section>
        <section>
          <p className={Style['terms-text']}>Terms & Conditions</p>
          <section
            className={Style['terms-description']}
            dangerouslySetInnerHTML={{ __html: content }}
          ></section>
        </section>
      </main>
    </>
  );
};

export default TermsCondition;
