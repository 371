import LocationCard from '../LocationCard/LocationCard';
import ResuableHeader from '../ResuableHeader/ResuableHeader';

const StationOnWay = ({title=""}) => {
  document.title = title
  return (
    <>
      <ResuableHeader header='Stations on my way' url='/planatrip' />
      <LocationCard />
    </>
  );
};

export default StationOnWay;
