import { createSlice } from "@reduxjs/toolkit";
import CarApi from "../APIs/CarApi.json";
import BikeApi from "../APIs/BikeApi.json";
import ScooterApi from "../APIs/ScooterApi.json";

const initialState = {
  brandFilter: {
    car: [],
    bike: [],
    scooter: [],
  },
  data: {
    car: CarApi.data.products,
    bike: BikeApi.data.products,
    scooter: ScooterApi.data.products,
  },
  priceFilter: {
    car: { min: 0, max: Number.POSITIVE_INFINITY },
    bike: { min: 0, max: Number.POSITIVE_INFINITY },
    scooter: { min: 0, max: Number.POSITIVE_INFINITY },
  },
  sort: {
    car: "LtoH",
    bike: "LtoH",
    scooter: "LtoH",
  },
};

//default asce
function compare({ key, sortType }, a, b) {
  let type = sortType === "desc" ? -1 : 1;
  // console.log(type);

  if (a[key] < b[key]) {
    return -1 * type;
  }
  if (a[key] > b[key]) {
    return 1 * type;
  }
  return 0 * type;
}

export const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    applyFilter: (state, action) => {
      // alert("heresasd")
      const { brand, type, price } = action.payload;
      if (brand?.length > 0) {
        state.brandFilter[type] = brand.reduce((a, b) => {
          a[b] = b;
          return a;
        }, {});
      } else {
        state.brandFilter[type] = [];
      }
      state.priceFilter[type] = price;
    },
    setPriceRange: (state, action) => {
      const { data, type } = action.payload;
      const min = data.price.min;
      const max = data.price.max;
      // console.log(data);
      state.filter[type].price = { min, max };
    },
    setSort: (state, action) => {
      // console.log(action);
      const { value, sortType, type } = action.payload;
      state.sort[type] = value;

      // we are doing becase temp is coming as proxy type pls research and update it
      let temp = JSON.parse(JSON.stringify(state.data[type]));
      let key =
        value === "atoz" || value === "ztoa"
          ? "make_org_name"
          : "ex_showroom_price";

      temp.sort(compare.bind(null, { key, type: value, sortType }));
      // console.log(temp, value, sortType);
      state.data[type] = temp;
    },
  },
});

// Action creators are generated for each case reducer function
export const { applyFilter, setSort, setPriceRange } = vehicleSlice.actions;

export default vehicleSlice.reducer;
