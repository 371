import './SortVehicle.scss';
import close from '../../Assets/Images/Close-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setSort } from '../../slice/vehicle';
import { HandleHideScroll } from '../../APIs/CommonLogics';
import { Radio } from '@mui/material';

const SortVehicle = ({ onClose, type }) => {
  HandleHideScroll();
  const sort = useSelector(state => state.vehicle.sort[type]);
  // console.log(sort)
  const dispatch = useDispatch();
  // console.log({ sort });

  function onClickRadio(e) {
    let value = e.target.value;
    // console.log(value);
    let sortType = 'asc';
    if (value === 'HtoL' || value === 'ztoa') {
      sortType = 'desc';
    }
    onClose();
    // console.log(value, type, sortType);
    dispatch(setSort({ value, type, sortType }));
  }

  let sortObj = [
    { label: 'Price - Lowest to Highest', value: 'LtoH' },
    { label: 'Price - Highest to Lowest', value: 'HtoL' },
    { label: 'Alphabetical : A to Z', value: 'atoz' },
    { label: 'Alphabetical : Z to A', value: 'ztoa' },
  ];

  return (
    <>
      <div
        className='sortclass'
        onClick={() => {
          onClose();
        }}
      >
        <div className='sort-vehicle-main'>
          <div className='sort-header'>
            <p>Sort By</p>
            <div
              onClick={() => {
                onClose();
              }}
            >
              <img src={close} alt='Close-btn' />
            </div>
          </div>
          <hr />
          <div className='sort-main'>
            {sortObj.map((d, i) => {
              return (
                <div className='sort-button' key={i}>
                  <label htmlFor='highest'>{d.label}</label>
                  <Radio
                    sx={{
                      color: "white",
                      '&.Mui-checked': {
                        color: "#14AE5C",
                      },
                    }}
                    value={d.value}
                    name='sort'
                    onChange={onClickRadio}
                    checked={d.value === sort}
                />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SortVehicle;
