import '../FCV/FCV-Details.scss';
import { numberFormat } from '../../utils/Helper';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';
import droomImage from '../../Assets/Images/droom.svg';
import { useParams, useLocation } from 'react-router-dom';
import { Grid } from "@mui/material";
const FCVDetails = detail => {
  // const id = useParams();
  const { state } = useLocation();

  // console.log(state);
  return (
    <>
      <ResuableHeader header='Find Cost Of Vehicle' />
      <main>
        <section className='FCV_Details'>
          <p className='FCV_Details--carName'>{state.product_title}</p>
          <img src={state.stock_image} alt='' className='vehicle-list-img' />
          {state.ex_showroom_price ? (
            <div className='FCV_Details--Price'>
              {/* <p className='FCV_Details--showRoom'>Ex - Showroom Price:</p>
              <p className='FCV_Details--carPrice'>
                &#x20b9; {numberFormat(state.ex_showroom_price)}
              </p>
           */}
           
            <Grid container  alignItems="center" >
              <Grid item xs={7} sm={7}>
              <p className='FCV_Details--showRoom'>Ex - Showroom Price:</p>
              </Grid>
              <Grid item xs={5} sm={5}>
              <p className='FCV_Details--carPrice'>
                &#x20b9; {numberFormat(state.ex_showroom_price)}
              </p>
              </Grid>
              </Grid>
            </div>
          ) : null}
        </section>

        <section className='FCV_Card'>
          <div className='FCV_Card--main'>
            <div className='FCV_Card--owner'>
              <p className='FCV_Card--owner-text'>
                Total cost of ownership(for 5 Years)
              </p>
              <p className='FCV_Card--owner-price'>
                ₹ {numberFormat(state.tco)}
              </p>
            </div>
            <hr />
            <div className='FCV_Card-list-main'>
              <div className='FCV_Card-list'>
                <p>Insurance</p>
                <p>₹ {numberFormat(state.insurance_cost)}</p>
              </div>
              <div className='FCV_Card-list'>
                <p>Charging cost</p>
                <p>₹ {numberFormat(state.fuel_cost)}</p>
              </div>
              <div className='FCV_Card-list'>
                <p>Tyre change cost</p>
                <p>₹ {numberFormat(state.tyre_change_cost)}</p>
              </div>
              <div className='FCV_Card-list'>
                <p>Service cost</p>
                <p>₹ {numberFormat(state.service_cost)}</p>
              </div>
              <div className='FCV_Card-list'>
                <p>Depreciation</p>
                <p>₹ {numberFormat(state.depreciation_cost)}</p>
              </div>
            </div>
          </div>
        </section>
        <footer>
        <div className='droom-footer-tab'>
          <div className='droom-text'>
            <p>Powered by</p>
          </div>
          <div className='droom-image'>
            <img src={droomImage} alt='Droom-Logo' />
          </div>
        </div>
      </footer>
      </main>
    </>
  );
};

export default FCVDetails;
