import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    name: "Dear user",
    mobile: "",
    grNumber: "",
    skipGrNumber: true,
    email: "",
    userPreference: "",
    viewedCOVScreen: false,
  },
};

export const userDetails = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    updateUserDetails: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { updateUserDetails } = userDetails.actions;
export default userDetails.reducer;
