import React, { useState, useEffect, useRef, useMemo } from "react";
import "./FCV.scss";
import droomImage from "../../Assets/Images/droom.svg";
import TabBar from "../../Components/TabBar/TabBar";
import ResuableHeader from "../../Components/ResuableHeader/ResuableHeader";
import SearchBar from "../../Components/SearchBar/SearchBar";
import TCODetails from "../../Components/TCODetails/TCODetails";

import { VehicleAPI } from "../../APIs/CommonLogics";
import Loader from "../../Components/Loader/Loader";
import BookData from "../../Helper/Data.json";
import Search from "../../Components/Search/Search";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setVehicle, updatePageView, viewScreen } from "../../slice/covSlice";
import { updateEVDatailsLocalStorage } from "../../slice/loginSlice";

import FCVLanding from "./FCV-Landing";
import { updateUserDetails } from "../../slice/userDetailsSlice";

const FCV = ({ changeContent, title = "" }) => {
  document.title = title;
  const childFunc = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    listofVehicles,
    monthlyKmDriven,
    currentData,

    screenDetails,
  } = useSelector((state) => state.cov);

  // let userData = JSON.parse(localStorage.getItem("evdetails") || "{}");
  const { viewedCOVScreen } = useSelector((state) => state.userDetails.user);

  console.log(listofVehicles, monthlyKmDriven);
  const tabsList = ["car", "bike", "scooter"];
  //
  // alert(viewedCOVScreen);
  const [tabNo, setTabNo] = useState(1);
  const [data, setData] = useState(null);
  const [cto, setCto] = useState(null);

  const tabFunc = (tabno) => {
    // console.log({ tabno });
    // setCto()
    if (tabNo !== tabno) {
      setActiveSearchedVehicle(undefined);
      setVehicleName("");
    }
    setTabNo(tabno);
  };

  let tabs = useMemo(() => tabsList, []);
  // console.log(tabs[tabNo-1])

  useEffect(() => {
    setCto(null);
    async function datafetch() {
      let data = await VehicleAPI(tabs[tabNo - 1]);
      // console.log(data);
      setData(data);
      setBtnDisable(true);
    }
    datafetch();
    dispatch(setVehicle({ type: tabs[tabNo - 1] }));
    setActiveKiloMeter();
    setActiveSearchedVehicle();
    setMonthKm();
    // setBtnDisable(true);
  }, [tabNo, tabs]);

  const resultObj = [];
  if (data) {
    // console.log(data);
    for (const datas of data) {
      resultObj[datas.product_title] = datas;
    }
    // console.log(resultObj);
  }

  const getTCODetail = async (detail) => {
    // console.log(detail);

    setCto(detail);
  };

  const [vehicleName, setVehicleName] = useState();

  const [monthKm, setMonthKm] = useState();

  const [loader, setLoader] = useState(false);
  const [ctoData, setCtoData] = useState();
  const VehicleVariant = async (product_name, kmDriven) => {
    // console.log(product_name, kmDriven);

    setLoader(true);

    let foundValue = resultObj[product_name];
    // console.log(foundValue.product_id);
    const vehicleId = foundValue.product_id;

    const params = {
      product_id: vehicleId,
      kms: kmDriven,
    };
    const url = process.env.REACT_APP_BACKEND_API + "droom/ev-tco";

    const result = await axios.post(url, params, {
      headers: {
        token: process.env.REACT_APP_COV_TOKEN,
      },
    });

    const CTODetail = result.data.data;
    setCtoData(CTODetail);
    setLoader(false);
    // console.log(CTODetail);
    navigate(`/FCV/${foundValue.product_id}`, {
      state: result.data.data,
    });
  };

  const [searchText, setSearchText] = useState("");
  const getSearchedText = (text) => {
    // console.log(text);
    setSearchText(text);
  };

  const FCV = {};

  // For Searched Vehicle
  const [activeSearchedVehicle, setActiveSearchedVehicle] = useState();
  const changeSearchedVehicleActive = (index) => {
    setActiveSearchedVehicle(index.id);
    setVehicleName(index.value);
    // console.log(index);
  };

  const getSearchedVehicleActiveClass = (index, className) => {
    // console.log(index, className);
    return activeSearchedVehicle === index ? className : "";
  };

  // For Monthly Kilo Meter

  const [activeKiloMeter, setActiveKiloMeter] = useState();
  const changeKiloMeterActive = (index) => {
    setActiveKiloMeter(index.id);
    setMonthKm(index.value);
    // console.log(index);
  };

  const getKiloMeterActiveClass = (index, className) => {
    // console.log(index, className);
    return activeKiloMeter === index ? className : "";
  };

  const [btnDisable, setBtnDisable] = useState(true);
  useEffect(() => {
    // console.log(vehicleName, monthKm, searchText);
    if ((vehicleName || searchText) && monthKm) {
      setBtnDisable(false);
    }
  }, [vehicleName, monthKm, searchText]);

  useEffect(() => {
    if (screenDetails.status === "success") {
      // dispatch(updateEVDatailsLocalStorage({ viewedCOVScreen: true }));
      dispatch(updateUserDetails({ viewedCOVScreen: true }));
    }
  }, [dispatch, screenDetails]);

  return (
    <>
      {!viewedCOVScreen ? (
        <FCVLanding
          onClick={(e) => {
            dispatch(viewScreen({ viewedCOVScreen: true }));
            dispatch(updateUserDetails({ viewedCOVScreen: true }));
          }}
        />
      ) : (
        <>
          <ResuableHeader header="Find Cost Of Vehicle" />

          <TabBar tabFunc={tabFunc} />
          <div className="cost-of-vehicle-main">
            <div className="cost-of-vehicle-sub">
              <div className="vehicle-name">Vehicle Name</div>
              {/* <Search placeholder='Enter a Book Name...' data={BookData} /> */}
              <SearchBar
                childFunc={childFunc}
                suggestions={resultObj}
                filterSection={false}
                getTCODetail={getTCODetail}
                placeholder={currentData.searchText}
                getSearchedText={getSearchedText}
                fromFCV={vehicleName}
                fcv={FCV}
              />
              <span className="FCV--searched-terms">
                Commonly searched terms
              </span>
              {/*  */}
              <div className="FCV_radio-main">
                {currentData.listofVehicles.map((vehicle, i) => {
                  // console.log('km' + vehicle.id);
                  return (
                    <div className="radioVehicleBox" key={vehicle.id}>
                      <div
                        // className='FCV_label'
                        className={`FCV_label ${getSearchedVehicleActiveClass(
                          vehicle.id,
                          "FCV_labell"
                        )} `}
                        onClick={() => changeSearchedVehicleActive(vehicle)}
                      >
                        {vehicle.label}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/*  */}
              <p className="FCV_Kms-Driven">Monthly Kms Driven</p>
              <div className="FCV_monthly-km">
                {currentData.monthlyKmDriven.map((Km, i) => {
                  // console.log('Km' + Km.id);
                  return (
                    <div className="radioKmBox" key={Km.id}>
                      <div
                        // className='FCV_Km-label'
                        className={`FCV_Km-label ${getKiloMeterActiveClass(
                          Km.id,
                          "FCV_Km-labell"
                        )} `}
                        onClick={() => changeKiloMeterActive(Km)}
                      >
                        {Km.label}
                      </div>
                    </div>
                  );
                })}
              </div>

              {btnDisable ? (
                <button
                  className="find-cost-disable"
                  onClick={() => {
                    VehicleVariant(searchText, monthKm);
                  }}
                  disabled
                >
                  Find Cost
                </button>
              ) : (
                <button
                  className="find-cost"
                  disabled={loader ? true : false}
                  onClick={() => {
                    VehicleVariant(searchText, monthKm);
                  }}
                >
                  {loader ? <Loader /> : "Find Cost"}
                </button>
              )}
            </div>
          </div>
        </>
      )}

      <footer>
        <div className="droom-footer-tab">
          <div className="droom-text">
            <p>Powered by</p>
          </div>
          <div className="droom-image">
            <img src={droomImage} alt="Droom-Logo" />
          </div>
        </div>
      </footer>
    </>
  );
};

export default FCV;
