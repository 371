import { useEffect, useState } from "react";
import "./splash.scss";
import { LoaderBar } from "../../Components/common/loader";

import arrowWhite from "../../Assets/Images/arrow-white.svg";
import logo from "../../Assets/Images/Logo.svg";
import logoText from "../../Assets/Images/logo-text.svg";
import electricCar from "../../Assets/Images/electric-car.png";
import splashBike from "../../Assets/Images/Bike.png";
import missionPragati from "../../Assets/Images/mission-pragati.png";
import cs from "../../Assets/Images/csimg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getAppStatus } from "../../slice/loginSlice";
import { setToken } from "../../slice/auth";
import { updateUserDetails } from "../../slice/userDetailsSlice";

const splashContent = [
  {
    splashImg: electricCar,
    splashHeading: "Planning to buy EV ?",
    splashPara:
      "Get a new experience with EV profitably and to nature's advantage.",
    buttonImg: arrowWhite,
  },
  {
    splashImg: splashBike,
    splashHeading: "Earn through EV",
    splashPara:
      "To enable ecosystems for responsible energy adoption with innovations.",
    buttonImg: arrowWhite,
  },
  {
    splashImg: cs,
    splashHeading: "Finding nearest station?",
    splashPara:
      "Select your nearest location to get a list of fast charging stations for your EV.",
    buttonImg: arrowWhite,
  },
  {
    splashImg: missionPragati,
    splashHeading: "Let's ride eco friendly",
    splashPara:
      "Enjoy sustainable, green and better versions of transportation.",
    buttonImg: arrowWhite,
  },
];

const Splash = ({ title = "" }) => {
  document.title = title;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);

  // let timer;

  // const moveSplash = () => {
  //   timer = setInterval(() => {
  //     // console.log('Done');
  //     setCurrentIndex((prevIndex) => {
  //       if (prevIndex === splashContent.length - 1) {
  //         return 0;
  //       } else {
  //         return prevIndex + 1;
  //       }
  //     });
  //   }, 5000);
  // };

  // useEffect(
  //   () => () => {
  //     clearInterval(timer);
  //   },
  //   [timer]
  // );
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const token = localStorage.getItem("evtoken");
  //   if (token) {
  //     dispatch(getAppStatus());
  //   }
  //   moveSplash();
  // }, [dispatch, moveSplash]);

  const evDetails = useSelector((state) => state.userDetails.user);
  const token = useSelector((state) => state.auth);

  const removeUndefined = (value) => {
    if (value === "undefined") {
      return "";
    } else if (value === "") {
      return "";
    } else if (value === "null") {
      return "";
    } else if (value === null) {
      return "";
    } else if (value === undefined) {
      return "";
    } else {
      return value;
    }
  };

  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/signin"
    ) {
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      if (
        (localStorage.getItem("evtoken") === null ||
          localStorage.getItem("evtoken") === undefined ||
          localStorage.getItem("evtoken") === "undefined") &&
        urlParams.get("token")
      ) {
        localStorage.setItem("evtoken", urlParams.get("token"));
        dispatch(setToken({ token: localStorage.getItem("evtoken") }));
        dispatch(
          updateUserDetails({
            name: removeUndefined(urlParams.get("name")),
            mobile: removeUndefined(urlParams.get("mobile")),
            email: removeUndefined(urlParams.get("email")),
            userPreference: removeUndefined(urlParams.get("userPreference")),
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("evtoken")) {
      return setTimeout(() => navigate("/evinindia", { replace: true }), 4500);
    } else {
      setTimeout(() => {
        navigate("/signin", { replace: true });
      }, 4500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate("/evinindia");
  //   }, 5000)
  // }, [navigate]);

  return (
    <>
      <div className="splashMainBlock">
        <div className="splashContainerBlock">
          <div className="splashAnimationBlock">
            <img className="evlogo" src={logo} alt="" />
            <img className="logotitle" src={logoText} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Splash;
