import './ReusableCard.scss';

const ReusableCard = props => {
  return (
    <>
      <div className='card_header' style={{ backgroundColor: props.bgc }}>
        <div className='card_logo'>
          <img
            src={props.headerImg ? props.headerImg : props.cardImg}
            alt='Charing-Station-Logo'
          />
        </div>
        <div className='card_para'>
          <p>{props.headerDesc}</p>
        </div>
      </div>
    </>
  );
};

export default ReusableCard;
