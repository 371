import React from 'react';
import './video-blog.scss';
import { useNavigate } from 'react-router-dom';

const VideoBlog = () => {
  const navigate = useNavigate();
  const videoContent = [
    {
      id: 1,
      blogthumbnail: 'oFBmC7gYNVY',
    },
    {
      id: 2,
      blogthumbnail: '8yuTInSBh-A',
    },
    {
      id: 3,
      blogthumbnail: 'Fl4ZTXhk5KE',
    },
    {
      id: 4,
      blogthumbnail: 'wbVxVsUC-B0',
    }
  ];

  const videoConetnt = videoContent.map((item, index) => {
    return (
      <div
        key={index}
        className='video-responsives yt-iframe'
        // style={{background:'https://i.ytimg.com/vi/'+item.blogthumbnail+"/hq720.jpg"}}
        style={{
          backgroundImage: `url('https://i.ytimg.com/vi/${item.blogthumbnail}/hq720.jpg')`,
        }}
        onClick={() => {
          navigate(`/blogs/video/${item.blogthumbnail}`);
        }}
      >
        <iframe
          id='ytplayer'
          className='iframe'
          width='100%'
          height='360'
          title='video'
          src={`https://www.youtube.com/embed/${item.blogthumbnail}?&autoplay=1' frameborder='0' allow='autoplay;' allowfullscreen`}
          frameBorder='0'
          style={{ pointerEvents: 'none' }}
        ></iframe>
      </div>
    );
  });

  return <>{videoConetnt}</>;
};

export default VideoBlog;
