import React from 'react';
import closeYellow from '../../Assets/Images/close-yellow.svg';
import WheelMaintenance1 from '../../Assets/Images/WheelMaintenance1.png';
import WheelMaintenance2 from '../../Assets/Images/WheelMaintenance2.png';
import WheelMaintenance3 from '../../Assets/Images/WheelMaintenance3.png';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';

import StateRegulationCarousel from '../../Components/StateRegulationCarousel/StateRegulationCarousel';

const WheelMaintenance = ({ title = '' }) => {
  document.title = title;
  const TaxBenefitsContent = [
    {
      cancelIcon: closeYellow,
      taxPara:
        'Electric Vehicles tires are more prone to wear-and-tear than conventional vehicles',
      taxImg: WheelMaintenance1,
      batteryDesc:
        'Heavy Batteries are placed horizontally on car floor which create more pressure on tires',
    },
    {
      cancelIcon: closeYellow,
      taxPara: 'Keep a check on Tire Pressure',
      taxImg: WheelMaintenance2,
      batteryDesc: 'Low pressure can reduce vehicle’s range by 3%',
    },
    {
      cancelIcon: closeYellow,
      taxPara: 'Frequently inspect your tires visually.',
      taxImg: WheelMaintenance3,
    },
  ];
  return (
    <div>
      <ResuableHeader header='Tire Management Tips' />
      <StateRegulationCarousel carouselDataa={TaxBenefitsContent} />
    </div>
  );
};

export default WheelMaintenance;
