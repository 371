import React from 'react';
import './CarLoader.scss';
import carLoader from '../../Assets/Images/car-loader.svg';
const CarLoader = ({ width = '100px', height = '100px' }) => {
  // console.log(width, height);
  return (
    <div className='car-loader-main'>
      <div
        className='rotating'
        style={{
          width: '100px',
          height: '100px',
          lineHeight: '100px',
          textAlign: 'center',
        }}
      >
        <img className='car-loader' src={carLoader} alt='' />
      </div>
    </div>
  );
};

export default CarLoader;
