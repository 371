import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const geteChallan = createAsyncThunk(
  "eChallan/geteChallan",
  async (arg, { rejectWithValue }) => {
    try {
      // for dev
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}e-challan/info`,
        { vehicleNumber: arg }
      );
      // for UAT
      // const {data} = await axios.get(`https://ev-uat.hdfcergo.com/ev/e-challan/info?vehicleNumber=${arg}`)
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const eChallanReducer = createSlice({
  name: "eChallan",
  initialState: {
    data: {},
    success: false,
    message: "",
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [geteChallan.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [geteChallan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.success = true;
    },
    [geteChallan.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.success = false;
    },
  },
});

export default eChallanReducer.reducer;
