import Slider from 'infinite-react-carousel';
import './StateRegulationCarousel.scss';
import { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import closeYellow from '../../Assets/Images/close-yellow.svg';
import backArrow from '../../Assets/Images/arrow-back.svg';

const StateRegulationCarousel = ({ carouselDataa, title = '' }) => {
  document.title = title;
  const childFunc = useRef(null);
  const navigate = useNavigate();
  // const { id } = useParams();
  // console.log(id);

  const location = useLocation();
  // console.log(location);

  const carouselData = location.state;
  // console.log(carouselData);

  const tax =
    carouselData && carouselData.state ? carouselData.stateIn : carouselDataa;

  const settings = {
    arrows: false,
    // arrowsBlock: true,
    autoplay: true,
    dots: true,
    // pauseOnHover: true,
    // dotsScroll: 4,
  };

  let height = window.screen.height;

  return (
    <div className='slider-main' ref={childFunc}>
      {carouselData?.state ? (
        <div>
          <div className='stateHeaders'>
          <div onClick={() => navigate(-1)}>
                <img src={backArrow} alt='Back-Icon' />
              </div>
            <span className='ev-policy-text'>
              {carouselData.state} EV Policy
            </span>
            {/* <div
              className='back-arrow-state'
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={closeYellow} alt='Back Arrow' />
            </div> */}
          </div>
          <br />
        </div>
      ) : null}

      <Slider {...settings}>
        
        {tax.map((item, index) => {
          return (
            <>
              <section key={index} className='tax-benefit-wrapper'>
                <div
                  className='tax-benefit-container'
                  style={{ height: (height / 100) * 70 + 'px' }}
                >
                  {carouselData?.state ? null : (
                    <div onClick={() => navigate(-1)}>
                      {/* <img
                        src={item.cancelIcon}
                        className='tax-close-icon'
                        alt=''
                      /> */}
                    </div>
                  )}

                  <div className='tax-benefit-para'>
                    <p>
                      {item.taxPara}
                      {/* {window.screen.height} */}
                    </p>
                  </div>
                  <div className='tax-benefit-img'>
                    <div style={{ margin: 'auto', flex: 2 }}>
                      <img src={item.taxImg} onfo alt='Scooter-Icon' />
                    </div>

                    {item.batteryDesc && (
                      <div className='batteryDesc'>{item.batteryDesc}</div>
                    )}
                    {item.stateDesc && (
                      <div
                        className='batteryDesc'
                        dangerouslySetInnerHTML={{ __html: item.stateDesc }}
                      ></div>
                    )}
                    {item.check && (
                      <div
                        className='check-state-button-main'
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <button className='check-state-button'>
                          Check other states
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              {item.link ? (
                <div className='src-source'>
                  <a href={item.link.value} target='_blank'>
                    Source: {item.link.text}
                  </a>
                </div>
              ) : null}
            </>
          );
        })}
      </Slider>
    </div>
  );
};

export default StateRegulationCarousel;
