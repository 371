import closeYellow from '../../Assets/Images/close-yellow.svg';
import BatteryMaintenance1 from '../../Assets/Images/BatteryMaintenance1.png';
import BatteryMaintenance2 from '../../Assets/Images/BatteryMaintenance2.png';
import BatteryMaintenance3 from '../../Assets/Images/BatteryMaintenance3.png';
import BatteryMaintenance4 from '../../Assets/Images/BatteryMaintenance4.png';
import StateRegulationCarousel from '../../Components/StateRegulationCarousel/StateRegulationCarousel';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';

const BatteryMaintenance = ({ title = '' }) => {
  document.title = title;
  const TaxBenefitsContent = [
    {
      cancelIcon: closeYellow,
      taxPara: 'Don’t park electric vehicle in extremely high temperatures',
      taxImg: BatteryMaintenance1,
      batteryDesc:
        'The temperature control system of the electric vehicle will drain the battery in extreme high temperatures.',
    },
    {
      cancelIcon: closeYellow,
      taxPara: 'Avoid Quick-charging stations',
      taxImg: BatteryMaintenance2,
      batteryDesc:
        'Although quick charging seems to be a great convenience, it reduces battery life.',
    },
    {
      cancelIcon: closeYellow,
      taxPara: 'Avoid extreme states of charge',
      taxImg: BatteryMaintenance3,
      batteryDesc:
        'Improve overall Battery life of electric vehicle by not keeping battery at extreme states ,I.e., fully charged (100%) or fully uncharged (0%) state',
    },
    {
      cancelIcon: closeYellow,
      taxPara: 'Don’t keep your vehicle uncharged for long duration',
      taxImg: BatteryMaintenance4,
      batteryDesc:
        'Keeping EV uncharged for long duration degrades battery. Maintain the charge just above low mark',
    },
  ];
  return (
    <div>
      <ResuableHeader header='Battery Management Tips' />
      <StateRegulationCarousel carouselDataa={TaxBenefitsContent} />
    </div>
  );
};

export default BatteryMaintenance;
