import './text-blog-details.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { blogContent } from '../../Helper/Text-Blogs';

import mp from '../../Assets/Images/MP.svg';
import backArrow from '../../Assets/Images/arrow-back.svg';
import { HandleScrollToTop } from '../../APIs/CommonLogics';

const TextBlogDetails = ({ title = '' }) => {
  document.title = title;
  const navigate = useNavigate();
  HandleScrollToTop();

  const { id } = useParams();

  const idd = Number(id);

  const findd = blogContent.find(b => b.blogId === idd);

  return (
    <>
      <section
        className='header-container'
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className='back-arrow'>
          <div>
            <img src={backArrow} alt='Back-Icon' />
          </div>
        </div>

        <div className='heading-text'>
          {/* <img src={mp} alt='Header Img' /> */}
        </div>
      </section>
      <div>
        <div className='swipe-main'>{findd && findd.answer}</div>
      </div>
    </>
  );
};

export default TextBlogDetails;
