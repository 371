import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialCarState = {
  listofVehicles: [
    { id: 1, label: 'TATA Nexon XM', value: 'Tata Nexon EV XM 2021' },
    { id: 2, label: 'TATA Altroz STD', value: 'Tata Altroz EV STD 2022' },
    {
      id: 3,
      label: 'TATA CURVV Electric',
      value: 'Tata CURVV Electric',
    },
    { id: 4, label: 'TATA Sierra STD', value: 'Tata Sierra EV STD 2023' },
  ],
  searchText: 'Eg: Audi eTron',
  monthlyKmDriven: [
    { id: 5, label: 'Upto 500', value: '500' },
    { id: 6, label: 'Upto 1000', value: '1000' },
    { id: 7, label: 'Upto 1500', value: '1500' },
    { id: 8, label: 'Upto 2000', value: '2000' },
  ],
};

const initialBikeState = {
  listofVehicles: [
    {
      id: 1,
      label: 'Revolt rv 300',
      value: 'Revolt RV300 Standard 2020',
    },

    {
      id: 2,
      label: 'Tork Motors Kratos',
      value: 'Tork Kratos Standard 2022',
    },

    {
      id: 3,
      label: 'Tork T6X',
      value: 'Tork T6X Standard 2022',
    },
  ],
  searchText: 'Eg: Revolt rv 300',
  monthlyKmDriven: [
    { id: 5, label: 'Upto 500', value: '500' },
    { id: 6, label: 'Upto 1000', value: '1000' },
    { id: 7, label: 'Upto 1500', value: '1500' },
    { id: 8, label: 'Upto 2000', value: '2000' },
  ],
};

const initialScooterState = {
  listofVehicles: [
    {
      id: 1,
      label: 'Ather 450',
      value: 'Ather 450 Standard 2018',
    },
    {
      id: 2,
      label: 'Ola s1',
      value: 'Ola S1 Standard 2022',
    },
    {
      id: 3,
      label: 'Ather 450 Plus',
      value: 'Ather 450 Plus Standard 2021',
    },
  ],
  searchText: 'Eg: Ather 450',
  monthlyKmDriven: [
    { id: 5, label: 'Upto 500', value: '500' },
    { id: 6, label: 'Upto 1000', value: '1000' },
    { id: 7, label: 'Upto 1500', value: '1500' },
    { id: 8, label: 'Upto 2000', value: '2000' },
  ],
};

let evDatails = JSON.parse(localStorage.getItem('evdetails') || '{}');

async function makeAPICall(body, { url }) {
  // thunkAPI.dispatch(showVehicle({type}))
  evDatails = JSON.parse(localStorage.getItem('evdetails') || '{}');
  let token = localStorage.getItem('evtoken');
  body.mobile = evDatails.mobile;
  var config = {
    method: 'put',
    url: process.env.REACT_APP_USER_BACKEND_API + url,
    // url: process.uat.env.REACT_APP_USER_BACKEND_API + url,
    headers: {
      'Content-Type': 'application/json',
      token: token ? token : '',
    },
    data: JSON.stringify(body),
  };

  try {
    const response = await axios(config);
    response.data.mobile = body.mobile;
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    err.response.data.mobile = body.mobile;
    return err.response.data;
  }
}

export const viewScreen = createAsyncThunk(
  'update/viewscreen',
  async (data, {}) => {
    // console.log(data);
    const url = 'update-view-screen';
    return makeAPICall(data, { url: url });
  }
);

export const cov = createSlice({
  name: 'cov',
  initialState: {
    viewedCOVScreen: evDatails.viewedCOVScreen,
    currentTab: 'car',
    currentData: initialCarState,
    data: {
      car: initialCarState,
      bike: initialBikeState,
      scooter: initialScooterState,
    },
    screenDetails: { status: null, data: null },
  },
  reducers: {
    setVehicle: (state, actions) => {
      const { brand, type, price } = actions.payload;
      state.currentTab = type;
      state.currentData = state.data[type];
    },
    updatePageView: (state, actions) => {
      state.viewedCOVScreen = true;
    },
  },
  extraReducers: {
    [viewScreen.pending]: (state, action) => {
      state.screenDetails.status = 'loading';
    },
    [viewScreen.fulfilled]: (state, action) => {
      // console.log({action});
      if (action.payload.message !== 'Success') {
        state.screenDetails.status = 'failed';
        state.screenDetails.message = action.payload.message;
      } else {
        state.screenDetails.status = 'success';
        state.viewedCOVScreen = true;
        let userData = JSON.parse(localStorage.getItem('evdetails') || '{}');
        localStorage.setItem(
          'evdetails',
          JSON.stringify({ ...userData,viewedCOVScreen:true})
        );
        state.screenDetails.data = { ...action.payload.data };
      }
    },
    [viewScreen.rejected]: (state, action) => {
      state.screenDetails = 'failed';
    },
  },
});

// Action creators are generated for each case reducer function
export const { setVehicle, updatePageView } = cov.actions;

export default cov.reducer;
