import React, { useState, useEffect, useRef, useCallback } from "react";
import "./UserVehicleInformation.scss";
import Loader from "../../Components/Loader/Loader";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  userVehicleInfo,
  userPreferenceEv,
  sendPreference,
  updateEVDatailsLocalStorage,
} from "../../slice/loginSlice";
import ResuableHeader from "../ResuableHeader/ResuableHeader";
import backArrow from "../../Assets/Images/arrow-back.svg";
import { updateUserDetails } from "../../slice/userDetailsSlice";

const UserVehicleInformation = ({ title = "" }) => {
  document.title = title;
  let navigate = useNavigate();
  // const { mobileNo, preference } = useSelector((state) => state.users);
  const { status } = useSelector((state) => state.users.preferenceStatus);
  const { mobile, userPreference: tempPreferencee } = useSelector(
    (state) => state.userDetails.user
  );
  // const { mobile } = JSON.parse(localStorage.getItem("evdetails"));
  // const preferencee = localStorage.getItem("preference");
  // const tempPreferencee = localStorage.getItem("tempPreference");
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { grNumber, from } = state || {};
  const [vehicleNo, setVehicleNo] = useState(grNumber || "");
  const [vehicleError, setVehicleError] = useState("");
  const [disableButton, setDisableButton] = useState(grNumber ? false : true);
  const vehicleNoRef = useRef(null);
  const [focused, setFocused] = React.useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  // useEffect(() => {
  //   if (status === "success") {
  //     navigate("/userVehicleInfo", { replace: true });
  //   }
  // }, [navigate, status]);

  const handleOnChange = useCallback((input) => {
    let value = input.toUpperCase();
    setVehicleNo(value);
    if (
      /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$/.test(value) ||
      /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/.test(value)
    ) {
      setVehicleError("");
      setDisableButton(false);
    } else {
      setVehicleError("Please enter valid vehicle number");
      setDisableButton(true);
    }
  }, []);

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   if(disableButton === false){
  //     navigate('/user-profile', { replace: true });
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (from === "user-pref") {
      dispatch(
        sendPreference({
          preference: tempPreferencee,
          mobile: mobile,
          grNumber: vehicleNo,
        })
      );
      // dispatch(
      //   updateEVDatailsLocalStorage({
      //     userPreference: tempPreferencee,
      //     to: "evinindia",
      //     grNumber: vehicleNo,
      //   })
      // );
      dispatch(
        updateUserDetails({
          userPreference: tempPreferencee,
          to: "evinindia",
          grNumber: vehicleNo,
        })
      );
      return navigate("/evinindia");
    }
    if (disableButton === false) {
      // dispatch(
      //   sendPreference({
      //     preference: tempPreferencee,
      //     mobile: mobile,
      //     grNumber: vehicleNo,
      //   })
      // );
      // dispatch(
      //   updateEVDatailsLocalStorage({
      //     // userPreference: tempPreferencee,
      //     to: "evinindia",
      //     // grNumber: vehicleNo,
      //   })
      // );
      // dispatch(
      //   updateUserDetails({
      //     to: "evinindia",
      //   })
      // );
      navigate("/user-profile", {
        replace: true,
        state: {
          vehicleNoFromUserVehInfoComp: vehicleNo,
          userPreference: tempPreferencee,
          to: "evinindia",
        },
      });
    }
  };

  const handleSkip = (e) => {
    e.preventDefault();
    // dispatch(
    //   sendPreference({
    //     preference: preferencee,
    //     mobile: mobile,
    //     grNumber: "",
    //     skipGrNumber: true,
    //   })
    // );
    // navigate("/evinindia", { replace: true });
    // dispatch(
    //   updateEVDatailsLocalStorage({
    //     userPreference: preferencee,
    //     to: "evinindia",
    //     skipGrNumber: true,
    //   })
    // );
    // navigate("/user-profile", { replace: true });
    // dispatch(
    //   updateEVDatailsLocalStorage({
    //     userPreference: preferencee,
    //     to: "evinindia",
    //     skipGrNumber: true,
    //   })
    // );
    if (from === "user-pref") return navigate("/evinindia");
    navigate(-1, {
      replace: true,
    });
  };

  const goback = () => {
    // localStorage.removeItem('preference');
    navigate("/user-profile", { replace: true });
  };

  useEffect(() => {
    if (from !== "user-pref" && grNumber?.length > 0) {
      handleOnChange(grNumber);
    }
  }, [handleOnChange, grNumber, from]);

  return (
    <div className="UserVehicleInformation">
      <ResuableHeader />
      {/* <div className="uservehicle-info">
        <img
          src={backArrow}
          alt=""
          onClick={() => {
            // goback();
          }}
        />
      </div> */}
      <div className="preference-main">
        <div className="UserPreference_area1">
          <p className="UserPreference_card1_p">Vehicle Registration No.</p>
          <p className="UserPreference_card1_p1">
            Electric is the future of mobility. You're amongst less than 1% of
            people in India that owns EV.
          </p>
          <p className="UserPreference_card1_p1">
            Help us by telling your vehicle registration number to get
            personalized deals on insurance.
          </p>
        </div>

        <div className="UserPreference_card1">
          <div className="UserPreference_plannin1">
            <input
              ref={vehicleNoRef}
              onFocus={onFocus}
              onBlur={onBlur}
              type="text"
              className="UserPreference_plannin1_input"
              placeholder="Ex-MH07CA7772"
              required="true"
              autoFocus
              value={vehicleNo}
              onChange={(e) => handleOnChange(e.target.value)}
            />
          </div>
          {vehicleError ? (
            <div className="vehicleError">{vehicleError}</div>
          ) : (
            ""
          )}
          <div className="UserPreference_plannin1_button">
            {status !== "loading" ? (
              <button onClick={(e) => handleSubmit(e)}>Submit</button>
            ) : (
              <button className="btn_disabled" disabled>
                <Loader />
              </button>
            )}
          </div>
        </div>
      </div>

      <section
        className="skip-now"
        onClick={(e) => {
          handleSkip(e);
          // navigate('/home', { replace: true });
        }}
      >
        <p>Skip for now</p>
      </section>
    </div>
  );
};

export default UserVehicleInformation;
