import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const geteVehicleInfo = createAsyncThunk(
  "vehicleInfo/getvehicleInfo",
  async (arg, { rejectWithValue }) => {
    try {
      // for dev
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}vehicle-detail/info`,
        { vehicleNumber: arg }
      );
      // for UAT
      //  const {data} = await axios.get(`https://ev-uat.hdfcergo.com/ev/vehicle-detail/info?vehicleNumber=${arg}`)
      return data;
    } catch (error) {
      rejectWithValue(error.reaponse.data);
    }
  }
);

const vehicleInfoinitialState = {
  data: {},
  success: false,
  message: "",
  loading: false,
};

const vehicleInfoReducer = createSlice({
  name: "vehicleInfo",
  initialState: vehicleInfoinitialState,
  reducers: {},
  extraReducers: {
    [geteVehicleInfo.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [geteVehicleInfo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.success = true;
    },
    [geteVehicleInfo.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.message = payload;
    },
  },
});

export default vehicleInfoReducer.reducer;
