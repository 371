import { createSlice } from '@reduxjs/toolkit';

import closeRed from '../Assets/Images/close-red.svg';
import bikeThumb from '../Assets/Images/bike-thumbnail.svg';
import carThumb from '../Assets/Images/car-thumbnail.svg';
import chargingThumb from '../Assets/Images/charging-point-thumbnail.svg';

const storiesContent = [
  {
    cancelBtn: closeRed,
    storyOuterImg: [bikeThumb],
    storyPara:
      'The Indian EV market is expected to reach USD 17.01 Billion by 2026, growing at a CAGR of 23.47%',
  },
  {
    cancelBtn: closeRed,
    storyOuterImg: [carThumb],
    storyPara:
      'Norway has the largest EV usage of all countries; 58% of the car bought in Norway last year were Electric Vehicles',
  },
  {
    cancelBtn: closeRed,
    storyOuterImg: [chargingThumb],
    storyPara:
      'Norway has the largest EV usage of all countries; 58% of the car bought in Norway last year were Electric Vehicles',
  },
  {
    cancelBtn: closeRed,
    storyOuterImg: [bikeThumb],
    storyPara:
      'The Indian EV market is expected to reach USD 17.01 Billion by 2026, growing at a CAGR of 23.47%',
  },
  {
    cancelBtn: closeRed,
    storyOuterImg: [bikeThumb],
    storyPara:
      'Norway has the largest EV usage of all countries; 58% of the car bought in Norway last year were Electric Vehicles',
  },
];

const initialState = {
  show: false,
  allStories: storiesContent,
  currentIndex: 0,
  progress: 0,
};

export const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    showStory: (state, action) => {
      const { index } = action.payload;
      state.show = true;
      state.currentIndex = index;
    },
    hideStory: state => {
      state.show = false;
    },
    nextStory: state => {
      state.currentIndex = state.currentIndex + 1;
    },
    startProgress: state => {
      state.progress = state.progress + 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showStory, hideStory, nextStory, startProgress } =
  storySlice.actions;

export default storySlice.reducer;
