import React, { useState, useRef } from "react";
import "./Vehicle.scss";
import { useSelector } from "react-redux";
import SearchBar from "../SearchBar/SearchBar";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StickyHeader from "../UI/StickyHeader";
import { HandleScrollToTop } from "../../APIs/CommonLogics";
import { numberFormat } from "../../utils/Helper";
import Loader from "../Loader/Loader";
import close from "../../Assets/Images/Close-icon.svg";
import { Grid } from "@mui/material";
import { useCallback } from "react";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Vehicle = ({ type }) => {
  HandleScrollToTop();
  // console.log(type)

  const childFunc = useRef(null);

  const brandFilter = useSelector((state) => state.vehicle.brandFilter[type]);
  const priceFilter = useSelector((state) => state.vehicle.priceFilter[type]);
  // console.log({ brandFilter, priceFilter });
  // console.log({filter});

  const navigate = useNavigate();
  // console.log(history)
  const [open, setOpen] = React.useState(false);
  const [varient, setVarient] = useState([]);
  const [brandLength, setBrandlength] = useState(false);
  const [priceLength, setPricelength] = useState(false);
  const VehicleVariant = async (bucket, make, model) => {
    const params = {
      bucket: bucket,
      make: make,
      model: model,
    };
    const url = process.env.REACT_APP_BACKEND_API + "droom/other-variant";
    // console.log(url);
    const result = await axios.post(url, params, {
      headers: {
        token: process.env.REACT_APP_COV_TOKEN,
      },
    });
    // const car = convertToArray(result.data.data);
    // console.log(result.data.data);
    const variant = result.data.data;
    return variant;
  };

  const handleOpen = async (bucket, make, model) => {
    // console.log(bucket, make, model);
    setOpen(true);
    let data = await VehicleVariant(bucket, make, model);
    setVarient(data);
  };
  const handleClose = () => {
    setVarient([]);
    setOpen(false);
  };
  const [searchData, setSearchData] = useState(null);
  const [searchEmpty, setSearchEmpty] = useState(true);

  const data = useSelector((state) => state.vehicle.data[type]);
  // console.log({ data })

  const resultObj = [];
  if (data) {
    for (const datas of data) {
      resultObj[`${datas.make_org_name} ${datas.model}`] = datas;
    }
    // console.log(resultObj);
  }

  const searchedCarsList = useCallback(
    (carsSearch) => {
      carsSearch = Array.isArray(carsSearch)
        ? carsSearch
        : [carsSearch?.make_org_name + " " + carsSearch?.model];
      let carlistSuggestion = carsSearch?.map((carName) => {
        return data.filter((item, index) => {
          let data = item?.make_org_name + " " + item?.model;
          return data.indexOf(carName) > -1;
        });
      });
      setSearchData(carlistSuggestion.flat(1));
      setSearchEmpty(false);
    },
    [data]
  );

  const inputEmptyorNot = (emptyornot) => {
    if (!emptyornot) {
      setSearchEmpty(true);
    }
    // console.log(emptyornot);
  };

  const ModalPopUp = (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {/* Select Variants */}
          <div className="select-header">
            <p>Select Variants</p>
            <div onClick={handleClose}>
              <img src={close} alt="Close-btn" />
            </div>
          </div>
        </Typography>

        {varient.length > 0 ? (
          <Grid container spacing={1}>
            {varient.map((items) => {
              return (
                <Grid item xs={6}>
                  <button
                    className="pop-button pop-active"
                    onClick={() => {
                      navigate(
                        `/findelectricvehicle/${type}/${items.product_id}`,
                        {
                          state: items,
                        }
                      );
                    }}
                  >
                    {`${items.model_org_name} ${items.trim}`}
                  </button>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <span>
            <Loader />
          </span>
        )}
      </Box>
    </Modal>
  );

  const check = searchEmpty ? data : searchData;
  // console.log(check);
  // console.log(searchData)
  useEffect(() => {
    setPricelength(
      priceFilter.min > 100000 ||
        (priceFilter?.max !== null && priceFilter?.max < 200000000)
    );
    setBrandlength(Object.keys(brandFilter)?.length > 0);
  }, [brandFilter, priceFilter]);
  if (searchData || data) {
    return (
      <>
        <StickyHeader>
          <SearchBar
            childFunc={childFunc}
            tabType={type}
            suggestions={resultObj}
            searchedCarsList={searchedCarsList}
            inputEmptyorNot={inputEmptyorNot}
            filterSection={true}
            brandLength={brandLength}
            priceLength={priceLength}
          />
        </StickyHeader>
        {((!searchEmpty && searchData) || data).map((vechicle, index) => {
          const applyFilterFlag =
            (brandFilter[vechicle.make_org_name.toLowerCase()] ||
              !brandLength) &&
            vechicle.ex_showroom_price >= priceFilter.min &&
            vechicle.ex_showroom_price <= priceFilter.max;

          return searchData || applyFilterFlag ? (
            <VehicleCard
              key={index}
              index={index}
              tabType={type}
              vehicle={vechicle}
              handleOpen={handleOpen}
            />
          ) : null;
        })}
        {ModalPopUp}
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default Vehicle;

function VehicleCard({ index, vehicle, handleOpen, tabType }) {
  // console.log({ index, name: vehicle.make_org_name, tabType });
  return (
    <div key={index} className="vehicle-list-main">
      <img src={vehicle.stock_images} alt="" className="vehicle-list-img" />
      <div className="car-details">
        <div>
          <div className="car-name-section">
            <p className="car-name">{vehicle.make_org_name}</p>
            <p className="car-name">{vehicle.model_org_name}</p>
          </div>
          <div className="car-price">
            <p>&#x20b9; {numberFormat(vehicle.ex_showroom_price)}</p>
            <p className="onwards">(Onwards)</p>
          </div>
        </div>

        <div>
          <button
            className="view-button"
            id={vehicle.product_id}
            onClick={() => handleOpen(tabType, vehicle.make, vehicle.model)}
          >
            View Details
          </button>
        </div>
      </div>
    </div>
  );
}
