import { combineReducers } from "redux";
import auth from "./auth";
import blogs from "./blogs";
import covSlice from "./covSlice";
import eChallanSlice from "./e-challanSlice";
import filter from "./filter";
import loaderSlice from "./loaderSlice";
import loginSlice from "./loginSlice";
import sidebarMenuSlice from "./sidebarMenuSlice";
import story from "./story";
import vehicle from "./vehicle";
import vehicleInfoSlice from "./vehicleInfoSlice";
import userDetailsSlice from "./userDetailsSlice";

const reducers = combineReducers({
  auth: auth,
  blog: blogs,
  cov: covSlice,
  eChallan: eChallanSlice,
  filter,
  loader: loaderSlice,
  users: loginSlice,
  sidebarMenu: sidebarMenuSlice,
  story,
  vehicle,
  vahicleInfo: vehicleInfoSlice,
  userDetails: userDetailsSlice,
});

export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};
