import React, { useState, useEffect } from "react";
import SearchIcon from "../../Assets/Images/search_icon.svg";
import FilterIcon from "../../Assets/Images/filter_icon.svg";
import Sort from "../../Assets/Images/sort_icon.svg";
import "./SearchBar.scss";
import Filter from "../Filter/Filter";
import Loader from "../Loader/Loader";
import axios from "axios";
import SortVehicle from "../SortVehicle/SortVehicle";
// import { useSelector, useDispatch } from 'react-redux';

const SearchBar = ({
  suggestions,
  searchedCarsList = () => {},
  inputEmptyorNot,
  tabType,
  filterSection,
  brandLength,
  priceLength,
  getTCODetail = () => {},
  childFunc,
  placeholder,
  getSearchedText,
  fcv,
  fromFCV,
}) => {
  var suggestionsKeys = Object.keys(suggestions ? suggestions : false);
  // console.log(suggestionsKeys);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [kmDriven, setkmDriven] = useState("");

  useEffect(() => {
    childFunc.current = setInput;
    setInput(fromFCV);
    getSearchedText && getSearchedText(fromFCV);
  }, [childFunc, fromFCV]);

  const onChange = (e) => {
    const userInput = e.target.value;

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestionsKeys.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(e.target.value);
    // inputEmptyorNot(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = (e) => {
    setFilteredSuggestions([]);
    setInput(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    fcv && getSearchedText(e.target.innerText);
    filterSection && getCarData(e.target.innerText);
  };

  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setInput(filteredSuggestions[activeSuggestionIndex]);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          // console.log(suggestion)
          let className;

          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }

          return (
            <li className={className} key={suggestion} onClick={onClick}>
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <em>sorry no such vehicle available</em>
      </div>
    );
  };

  const [vehicleError, setVehicleError] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const VehicleVariant = async (product_id, kmDriven) => {
    if (!product_id) {
      return;
    }
    if (!(kmDriven >= 500 && kmDriven <= 10000)) {
      setVehicleError("Monthly Km Driven must between 500 to 10000");
      return;
    }
    const params = {
      product_id: product_id,
      kms: kmDriven,
    };
    const url = process.env.REACT_APP_BACKEND_API + "droom/ev-tco";

    setShowLoader(true);

    const result = await axios.post(url, params, {
      headers: {
        token: process.env.REACT_APP_COV_TOKEN,
      },
    });

    const CTODetail = result.data.data;
    // console.log(CTODetail);
    getTCODetail(CTODetail);
    setShowLoader(false);
  };

  const getCarData = (suggestionText) => {
    if (input === undefined || input === null) {
      return;
    }

    // console.log(suggestions);
    // console.log(suggestionText, input);
    let foundValue = suggestions[suggestionText || input];
    // console.log(foundValue.product_id);
    console.log(foundValue);
    filterSection && searchedCarsList(foundValue);
    VehicleVariant(foundValue.product_id, kmDriven);
  };

  const handleFilter = () => {
    setSortOpen(false);
    setFilterOpen(true);
    // console.log('Open')
  };

  const handleSort = () => {
    setSortOpen(true);
    // console.log('Sort Open')
  };

  const handleKMDriven = (e) => {
    // console.log(e.target.value);
    setVehicleError("");
    setkmDriven(e.target.value);
  };

  useEffect(() => {
    if (showSuggestions) {
      searchedCarsList(filteredSuggestions);
    }
  }, [filteredSuggestions, searchedCarsList, showSuggestions]);

  return (
    <div className="find-vehicle-main">
      <div className="nav-main">
        <div
          className={
            filterSection
              ? "find-vehicle-input_container"
              : "find-vehicle-input_container_condition"
          }
        >
          <input
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={input}
            className="find-vehicle-input"
            placeholder={placeholder ? placeholder : "Search"}
          />
          {filterSection && (
            <span className="find-vehicle-input_img" data-role="toggle">
              <img
                src={SearchIcon}
                alt="Search Icon"
                onClick={() => !input && inputEmptyorNot("")}
              />
            </span>
          )}
        </div>

        {filterSection && (
          <>
            <div className="sort-filter" onClick={handleSort}>
              <img src={Sort} alt="" className="sort-fiter-img" />
              <span>Sort</span>
            </div>
            {brandLength || priceLength ? (
              <div className="sort-filter" onClick={handleFilter}>
                <img src={FilterIcon} alt="" className="sort-fiter-img" />
                <span className="filtered">.</span>
                <span>Filter</span>
              </div>
            ) : (
              <div className="sort-filter" onClick={handleFilter}>
                <img src={FilterIcon} alt="" className="sort-fiter-img" />
                <span>Filter</span>
              </div>
            )}
          </>
        )}
      </div>

      {/*  */}

      {showSuggestions && input && <SuggestionsListComponent />}
      {filterOpen ? (
        <Filter
          type={tabType}
          onClose={() => {
            setFilterOpen(false);
          }}
        />
      ) : null}

      {sortOpen ? (
        <SortVehicle
          type={tabType}
          onClose={() => {
            setSortOpen(false);
          }}
        />
      ) : null}

      {/* For Monthly Kms driven */}
      {!filterSection && <></>}
    </div>
  );
};

export default SearchBar;
