import React from 'react';
import { useParams } from 'react-router-dom';

const VideoPreview = ({ title = '' }) => {
  document.title = title;
  const { id } = useParams();
  // console.log(id);

  return (
    <>
      <div className='video-preview'>
        <iframe
          id='ytplayer'
          className='iframe'
          width='100%'
          height='360'
          title='video'
          src={`https://www.youtube.com/embed/${id}?&autoplay=1' frameborder='0' allow='autoplay;' allowfullscreen`}
          cacheEnabled={true}
          frameBorder='0'
          allowsInlineMediaPlayback={true}
          mediaPlaybackRequiresUserAction={true}
          allow='autoplay; encrypted-media'
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
};

export default VideoPreview;
