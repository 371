import './FindVehicle.scss';
import TabBar from '../FindVehicleChild/TabBar';
import ResuableHeader from '../ResuableHeader/ResuableHeader';
import StickyHeader from '../UI/StickyHeader';

const FindVehicle = ({ title = '' }) => {
  document.title = title;
  return (
    <>
      <StickyHeader>
        <ResuableHeader
          header='Find Electric Vehicles'
          url='/dashboard'
          cardYes={false}
        />
        <TabBar />
      </StickyHeader>
    </>
  );
};

export default FindVehicle;
