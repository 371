import React, { useState } from 'react';
import './blogs.scss';
import TextBlog from '../../Pages/TextBlog/text-blog';
import VideoBlog from '../../Pages/VideoBlog/video-blog';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';
import mp from '../../Assets/Images/MP.svg';
import StickyHeader from '../../Components/UI/StickyHeader';
import { HandleScrollToTop } from '../../APIs/CommonLogics';
import { useSelector, useDispatch } from 'react-redux';
import { changeTabs } from '../../slice/blogs';
import kazamLogo from '../../Assets/Images/kazam-logo.jpg';
import {
  BrowserRouter,
  useNavigate,
  Route,
  Outlet,
  Link,
  useSearchParams,
} from 'react-router-dom';
import { useEffect } from 'react';

export const Blogs = ({ title }) => {
  const storeblogs = useSelector(state => state.blog);
  const dispatch = useDispatch();
  document.title = title;
  HandleScrollToTop();
  const toggleTab = name => {
    dispatch(changeTabs({ title: name }));
  };

  const getActiveClass = (title, className) => {
    return storeblogs.title === title ? className : '';
  };

  return (
    <div className='container'>
      {/* {toggleState} */}
      <StickyHeader>
        <ResuableHeader header={storeblogs.title==="Blogs"?"Blogs":"Videos"} url='/dashboard' />
        <ul className='tab-list'>
          <li
            className={`tabs ${getActiveClass('Blogs', 'active-tabs')}`}
            onClick={() => {
              toggleTab(`Blogs`);
            }}
          >
            Blogs
          </li>
          <li
            className={`tabs ${getActiveClass(`Video Blogs`, 'active-tabs')}`}
            onClick={() => {
              toggleTab(`Video Blogs`);
            }}
          >
            Videos
          </li>
        </ul>
      </StickyHeader>
      {/* <Outlet /> */}
      <div className='content-container'>
        <div className={`content ${getActiveClass('Blogs', 'active-content')}`}>
          <TextBlog />
        </div>
        <div
          className={`content ${getActiveClass(
            'Video Blogs',
            'active-content'
          )}`}
        >
          <VideoBlog />
        </div>
      </div>
      <section>
        <a class='powered-by' href='https://www.kazam.in/' target='_blank'>
          <span className='powered-by-text'>Powerd By</span>
          <img className='hdfcergo-logo' src={kazamLogo} alt='' />
        </a>
      </section>
    </div>
  );
};

export default Blogs;
