import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';
import CE from '../../Assets/Images/CE.svg';
import CEInfo from '../../Assets/Images/CE-Info.svg';
import slideBike from '../../Assets/Images/CE_slide_bike.svg';
import './CalculatorNew.scss';
import { useEffect } from 'react';

const kazamData = [
  {
    id: 1,
    text: 'Kazam Mini',
    txtLabel: '₹ 5,500',
    value: 5500,
    defaultChecked: 'defaultChecked',
  },
  {
    id: 2,
    text: 'Kazam Mini',
    txtLabel: '₹ 14,000',
    value: 14000,
    border: true,
  },
  { id: 3, text: 'Kazam Mini', txtLabel: '₹ 15,000', value: 15000 },
];
const CalculatorNew = () => {
  const [active, setActive] = useState(1);
  const changeActive = index => {
    setActive(index);
    // console.log(index);
  };

  const getActiveClass = (index, className) => {
    // console.log(index, className);
    return active === index ? className : '';
  };

  // For Charger
  const [chargerActive, setChargerActive] = useState(1);
  const changeChargerActive = index => {
    setChargerActive(index.id);
    setChargerType(index.value);
    // console.log(index);
  };

  const getChargerActiveClass = (index, className) => {
    // console.log(index, className);
    return chargerActive === index ? className : '';
  };

  const useSlider = (min, max, defaultState, label, id) => {
    // console.log(id);
    const [slide, setSlide] = useState(defaultState);
    const handleChange = e => {
      // console.log(e.target.value);
      getSlide(e.target.value);
      setSlide(e.target.value);
    };

    const Slider = () => (
      <div className='slider-block'>
        <img
          src={slideBike}
          alt='slide-bike'
          className='slide-icon'
          style={{ left: (slide - 1) * 10 + '%' }}
        />

        <input
          type='range'
          className='CE-Slider'
          id={id}
          min={min}
          max={max}
          step={1}
          // value={state} // don't set value from state
          defaultValue={slide} // but instead pass state value as default value
          onChange={handleChange} // don't set state on all change as react will re-render
          onMouseUp={() => {}} // only set state when handle is released
        />
      </div>
    );
    return [slide, Slider, setSlide];
  };

  const [slideValue, Slider] = useSlider(1, 10, 1, 'Threshold', 'threshold');

  const [sliderValue, setSliderValue] = useState(1);
  const getSlide = slide => {
    // console.log(slide);
    setSliderValue(slide);
  };
  const [chargerType, setChargerType] = useState(5500);
  const handleChargerType = e => {
    // console.log(e.target.value);
    setChargerType(e.target.value);
  };

  const [ppy, setPpy] = useState();
  const [pp, setPp] = useState();

  useEffect(() => {
    const PPY = (sliderValue * 3 * 15 - sliderValue * 3 * 7) * 365;
    if (active === 1) {
      setPpy(PPY);
    }
    if (active === 2) {
      // console.log({ chargerType, PPY });
      const payperiod = ((chargerType / PPY) * 365).toFixed();
      // console.log(payperiod);
      setPp(payperiod);
    }
  }, [sliderValue, chargerType, active, ppy, pp]);

  return (
    <>
      <ResuableHeader
        header='Calculate Earnings'
        url='/dashboard'
        cardImg={CE}
        bgc='#2F0E53'
        headerDesc='See how much you can earn on setting up a charging station'
        cardYes={true}
      />

      <main className='CE-Main'>
        <section className='CE-TOC'>
          <p className='CE-TOC--text'>Type of Chargers</p>

          <div className='CE-TOC--Card'>
            {kazamData.map((items, i) => {
              return (
                <div
                  key={i}
                  className={`CE-TOC--Item ${getChargerActiveClass(
                    items.id,
                    'CE-TOC--Itemm'
                  )} `}
                  onClick={() => changeChargerActive(items)}
                >
                  <p className='CE-TOC--Item--text'>{items.text}</p>
                  <p className='CE-TOC--Item--value'>{items.txtLabel}</p>
                </div>
              );
            })}
          </div>

          <p className='CE-TOC--vehicle_charged'>
            No. of Vehicle charged / day
          </p>

          <Slider defaultState={0} />
          <div className='vehicle-charge-label'>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((items, i) => {
              return <div key={i}>{items}</div>;
            })}
          </div>
        </section>

        <section>
          <div className='toggle-button-sub'>
            <button
              onClick={() => changeActive(1)}
              className={`btn ${getActiveClass(1, 'active')} `}
            >
              Profit in First Year
            </button>
            <button
              onClick={() => changeActive(2)}
              className={`btn ${getActiveClass(2, 'active')} `}
            >
              Payback Period
            </button>
          </div>
        </section>

        <section>
          <div className='CE-TOC--amount-card'>
            <span className='CE-TOC--amount'>
              {active === 1 ? <CurrencyFormat value={ppy} displayType={'text'} thousandSeparator={true} prefix={'₹ '} /> : `${pp} Days`}
            </span>
            <span className='tooltip-container'>
              {/* <img src={CEInfo} /> */}
              {/* <span className="tooltip">tooltip text</span> */}
            </span>
          </div>
        </section>

        <section>
          <p className='CE-TOC--assumptions-text'>Assumptions</p>
          <div className='CE-TOC--assumptions'>
            <ul className='CE-TOC--assumptions-list'>
              <li>
                Average unit consumed by 1 Two-wheeler to fully charge is 3
                units.
              </li>
              <li>Average charge of 1 unit in Mumbai is ₹ 8.58.</li>
              <li>Charges levied to customers is at ₹ 15 per unit.</li>
              <li>
                Profit is assuming you only install 1 charging station and rent
                out for charging.
              </li>
            </ul>
          </div>
        </section>
      </main>
      <br /><br/>
    </>
  );
};

export default CalculatorNew;

// Using Radio Button

{
  /* <div className='CE-TOC--Card'>
            {kazamData.map((items, i) => {
              return (
                <label
                  htmlFor={'calc' + items.id}
                  key={i}
                  // className='abc'
                  className={items.border ? 'CE-TOC--Item' : 'CE-TOC--Itemm'}
                >
                  <input
                    id={'calc' + items.id}
                    className='radioCalc'
                    type='radio'
                    name='ChargerType'
                    defaultChecked={
                      items.defaultChecked ? items.defaultChecked : null
                    }
                    value={items.value}
                    onChange={e => {
                      handleChargerType(e);
                    }}
                  />
                  <p className='CE-TOC--Item--text'>{items.text}</p>
                  <p className='CE-TOC--Item--value'>{items.txtLabel}</p>
                </label>
              );
            })}
          </div> */
}
