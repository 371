import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const TabBar = ({ tabFunc }) => {
  const data = useSelector(state => state.vehicle.data['car']);
  const resultObj = [];
  if (data) {
    for (const datas of data) {
      resultObj[datas.product_title] = datas;
    }
    // console.log(resultObj);
  }

  const [toggleState, setToggleState] = useState(1);
  // console.log(toggleState);
  tabFunc(toggleState);

  const toggleTab = index => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    toggleState === index ? className : '';

  // const [showContent, setShowContent] = useState(null)

  return (
    <>
      <div className='container'>
        <ul className='tab-list'>
          <li
            className={`tabs ${getActiveClass(1, 'active-tabs')}`}
            onClick={() => toggleTab(1)}
          >
            Car
          </li>
          <li
            className={`tabs ${getActiveClass(2, 'active-tabs')}`}
            onClick={() => toggleTab(2)}
          >
            Bike
          </li>
          <li
            className={`tabs ${getActiveClass(3, 'active-tabs')}`}
            onClick={() => toggleTab(3)}
          >
            Scooter
          </li>
        </ul>
      </div>
    </>
  );
};

export default TabBar;
