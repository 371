import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

async function makeAPICall(body, { url }) {
  // thunkAPI.dispatch(showVehicle({type}))
  let token = localStorage.getItem("evtoken");
  var config = {
    method: "put",
    url: process.env.REACT_APP_USER_BACKEND_API + url,
    // url: process.uat.env.REACT_APP_USER_BACKEND_API + url,
    headers: {
      "Content-Type": "application/json",
      token: token ? token : "",
    },
    data: JSON.stringify(body),
  };

  try {
    // console.log('sfdasd');
    const response = await axios(config);
    response.data.mobile = body.mobile;
    return response.data;
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data.message === "Un-Authorized"
    ) {
      localStorage.removeItem("evtoken");
      localStorage.removeItem("preference");
      localStorage.removeItem("tempPreference");
      localStorage.removeItem("evdetails");
      localStorage.removeItem("recentVehicleSearch");
      window.location.href = "/signin";
    }
    if (!err.response) {
      throw err;
    }
    // console.log('sfdasd 4w');

    err.response.data.mobile = body.mobile;
    return err.response.data;
  }
}

// async function makeCheckProfileApiCall(body, {url}) {
//   let token = localStorage.getItem('evtoken');
//   var conficCheckProgile = {
//     method: 'put',
//     url: process.env.REACT_APP_USER_BACKEND_API + url,
//     headers: {
//       'Content-Type': 'application/json',
//       token: token ? token : '',
//     }
//     ,
//     data: JSON.stringify(body),
//   };
//   try{
//     const result = await axios(config);
//     result.data.mobile = body.mobile;
//     return result.data;
//   }catch(err) {
//     if (!err.response) {
//       throw err;
//     }
//     err.response.data.mobile = body.mobile;
//     return err.response.data;
//   }
// }

export const sendOtp = createAsyncThunk("user/login", async (data, {}) => {
  const url = "send-otp";
  return makeAPICall(data, { url: url });
});

export const getAppStatus = createAsyncThunk("app/check", async (data, {}) => {
  const url = "app-check";

  return makeAPICall({}, { url: url });
});

export const verifyOtp = createAsyncThunk(
  "user/otp",
  async (data, thunkAPI) => {
    const url = "verify-otp";
    return makeAPICall(data, { url: url });
  }
);

export const updateMobileProfile = createAsyncThunk(
  "user/update-profile",
  async (data, thunkAPI) => {
    const url = "update-mobile-profile";
    return makeAPICall(data, { url: url });
  }
);
export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (data, thunkAPI) => {
    const url = "update-profile";
    return makeAPICall(data, { url: url });
  }
);

export const sendPreference = createAsyncThunk(
  "user/preference",
  async (data, thunkAPI) => {
    // localStorage.setItem("preference", data.preference);
    // console.log(data);
    const body = {
      userPreference: data.preference,
      grNumber: data.grNumber,
      mobile: data.mobile,
      skipGrNumber: data.skipGrNumber || false,
    };
    const url = "set-preference";
    return makeAPICall(body, { url: url });
  }
);

const initialState = {
  status: null,
  otpStatus: { status: null, message: null },
  votpStatus: { status: null, message: null },
  pvotpStatus: { status: null, message: null },
  preferenceStatus: { status: null, message: null },
  updateProfile: { status: null, message: null },
  profile: { status: null, message: null, data: {} },
  data: null,
  mobileNo: null,
  updatedMobileNo: null,
  preference: localStorage.getItem("preference"),
  vehicleNo: localStorage.getItem("vehicleNo"),
};
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    loginmobile: (state, action) => {
      const { mobileNo } = action.payload;
      state.mobileNo = mobileNo;
    },
    resetLogin: (state, action) => {
      state.otpStatus.status = null;
      state.otpStatus.message = null;
      state.pvotpStatus = { status: null, message: null };
      state.updateProfile.status = null;
      state.updateProfile.message = null;
    },

    updateEVDatailsLocalStorage: (state, action) => {
      const data = action.payload || {};
      let userData = JSON.parse(localStorage.getItem("evdetails") || "{}");
      localStorage.setItem(
        "evdetails",
        JSON.stringify({ ...userData, ...data })
      );
    },
    getEVDatailsLocalStorage: (state, action) => {
      const key = action.payload;
      let userData = JSON.parse(localStorage.getItem("evdetails") || "{}");
      return userData[key];
    },
    userPreferenceEv: (state, action) => {
      const { preference } = action.payload;
      state.preference = preference;
    },
    userVehicleInfo: (state, action) => {
      const { vehicleNo } = action.payload;
      state.vehicleNo = vehicleNo;
    },
    userProfileUpdate: (state, action) => {
      const { updatedMobileNo } = action.payload;
      // console.log(action.payload);
      state.updatedMobileNo = updatedMobileNo;
    },
  },
  extraReducers: {
    [sendOtp.pending]: (state, action) => {
      state.otpStatus.status = "loading";
    },
    [sendOtp.fulfilled]: (state, action) => {
      // console.log({action});
      if (action.payload.message !== "Success") {
        state.otpStatus.status = "failed";
        state.otpStatus.message = action.payload.message;
      } else {
        state.otpStatus.status = "success";
        state.mobileNo = action.payload.mobile;
        state.otpStatus.data = { ...action.payload.data };
      }
    },
    [sendOtp.rejected]: (state, action) => {
      state.status = "failed";
    },
    [verifyOtp.pending]: (state, action) => {
      state.votpStatus.status = "loading";
      state.status = "loading";
    },
    [verifyOtp.fulfilled]: (state, action) => {
      if (action.payload.message !== "Success") {
        state.votpStatus.status = "failed";
        state.votpStatus.message = action.payload.message;
      } else {
        state.votpStatus.status = "success";
        state.otpStatus.status = null;

        state.votpStatus.message = action.payload.message;
        state.mobileNo = action.payload.mobile;
        if (action.payload.data.userPreference) {
          // localStorage.setItem(
          //   "preference",
          //   action.payload.data.userPreference
          // );
          state.preference = action.payload.data.userPreference;
        }
        let userData = {};
        // localStorage.setItem(
        //   "evdetails",
        //   JSON.stringify({ ...userData, ...action.payload.data })
        // );
        state.votpStatus.data = { ...action.payload.data };
      }
    },
    [verifyOtp.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getAppStatus.pending]: (state, action) => {
      state.profile.status = "loading";
      // console.log(action);
    },
    [getAppStatus.fulfilled]: (state, action) => {
      state.profile.status = "success";

      state.profile.data = { ...action.payload };
      let userData = JSON.parse(localStorage.getItem("evdetails") || "{}");
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.profile
      ) {
        // localStorage.setItem(
        //   "evdetails",
        //   JSON.stringify({ ...userData, ...action.payload.data.profile })
        // );
      }
    },
    [getAppStatus.rejected]: (state, action) => {
      state.profile.status = "failed";
    },

    [sendPreference.pending]: (state, action) => {
      state.preferenceStatus.status = "loading";
      // console.log(action);
    },
    [sendPreference.fulfilled]: (state, action) => {
      state.preferenceStatus.status = "success";
      // console.log(action);
      state.preferenceStatus.data = { ...action.payload };
    },
    [sendPreference.rejected]: (state, action) => {
      state.preferenceStatus.status = "failed";
    },

    //
    [updateProfile.pending]: (state, action) => {
      state.status = "loading";
      state.updateProfile.status = "loading";
    },
    [updateProfile.fulfilled]: (state, action) => {
      // state.status = "success";
      let resp = action.payload;
      state.data = { ...action.payload.data };
      if (!resp?.success) {
        state.updateProfile.status = "failed";
        state.updateProfile.message = "Something went wrong";
      } else {
        state.updateProfile.status = "success";
        state.updateProfile.message = "Profile updated successfully";
      }

      // let userData = JSON.parse(localStorage.getItem("evdetails") || "{}");
      // localStorage.setItem(
      //   "evdetails",
      //   JSON.stringify({ ...userData, ...state.data })
      // );
    },
    [updateProfile.rejected]: (state, action) => {
      state.status = "failed";
    },

    [updateMobileProfile.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateMobileProfile.fulfilled]: (state, action) => {
      let resp = action.payload;
      if (resp.data?.token) {
        localStorage.setItem("evtoken", resp.data.token);
      }
      if (action.payload.message !== "Success") {
        state.pvotpStatus.status = "failed";
        state.pvotpStatus.message = action.payload.message;
      } else {
        state.pvotpStatus.status = "success";
        state.otpStatus.status = null;

        state.pvotpStatus.message = action.payload.message;
        // state.mobileNo = action.payload.mobile;
        state.pvotpStatus.data = { ...action.payload.data };
        state.data = { ...action.payload.data };
        // let userData = JSON.parse(localStorage.getItem("evdetails"));
        // localStorage.setItem(
        //   "evdetails",
        //   JSON.stringify({ ...userData, mobile: state.mobileNo, ...state.data })
        // );
      }
    },
    [updateMobileProfile.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loginmobile,
  userPreferenceEv,
  userVehicleInfo,
  resetLogin,
  userProfileUpdate,
  updateEVDatailsLocalStorage,
} = usersSlice.actions;
export default usersSlice.reducer;
