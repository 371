import './state-regulation.scss';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';
import { useNavigate } from 'react-router-dom';
import { HandleScrollToTop } from '../../APIs/CommonLogics';
import { statesDetails } from '../../Helper/State-Details';

function StateRegulation({ title = '' }) {
  document.title = title;
  HandleScrollToTop();

  const navigate = useNavigate();

  return (
    <>
      <ResuableHeader header='Select Your State' />

      <div className='state-card-main'>
        <p className='state-card-text'>
          Check out incentives you get on purchase of EV in your state
        </p>
        {statesDetails.map((item, index) => {
          // console.log(item);
          return (
            <div key={index}>
              <div
                className='state-card'
                onClick={() => {
                  navigate(`/state/${item.state}`, { state: item });
                }}
              >
                {item.state}
              </div>
            </div>
          );
        })}
        <div class='src-source'>
          <a
            href='https://powermin.gov.in/en/content/electric-vehicle'
            target='_blank'
          >
            Source: Ministry of Power, GOI
          </a>
        </div>
      </div>
    </>
  );
}

export default StateRegulation;
