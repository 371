import React, { useState } from 'react';
import './text-blog.scss';

import { useNavigate } from 'react-router-dom';

import { blogContent } from '../../Helper/Text-Blogs';

const TextBlog = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  return (
    <>
      {blogContent.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              navigate(`/blogs/${item.blogId}`);
            }}
          >
            <div
              key={index}
              className='text-blogs-main'
              onClick={() => {
                setShow(!show);
              }}
            >
              <img className='thumbnail-img' src={item.blogImg} alt='' />
              <div>
                <p className='text-blogs-heading'>{item.heading}</p>
                <p className='text-blogs-desc'>{item.headingDesc}</p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TextBlog;
