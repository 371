import closeYellow from '../../Assets/Images/close-yellow.svg';
import BrakeMaintenance1 from '../../Assets/Images/BrakeMaintenance1.png';
import BrakeMaintenance2 from '../../Assets/Images/BrakeMaintenance2.png';
import BrakeMaintenance3 from '../../Assets/Images/BrakeMaintenance3.png';
import BrakeMaintenance4 from '../../Assets/Images/BrakeMaintenance4.png';
import StateRegulationCarousel from '../../Components/StateRegulationCarousel/StateRegulationCarousel';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';

const BrakeMaintenance = ({ title = '' }) => {
  document.title = title;
  const TaxBenefitsContent = [
    {
      cancelIcon: closeYellow,
      taxPara: 'Keep a check on Brake Fluid',
      taxImg: BrakeMaintenance1,
    },
    {
      cancelIcon: closeYellow,
      taxPara: 'Avoid high speed/rash driving whenever possible',
      taxImg: BrakeMaintenance2,
    },
    {
      cancelIcon: closeYellow,
      taxPara:
        'Wear and Tear of Disc-Brake in electric vehicles is lesser than conventional cars',
      taxImg: BrakeMaintenance3,
      batteryDesc:
        'Electric Vehicles use regenerative braking that uses electric motor to slow down vehicle',
    },
    {
      cancelIcon: closeYellow,
      taxPara:
        'Get regular service of your electric vehicle from a proficient technician',
      taxImg: BrakeMaintenance4,
      // check: true,
    },
  ];
  return (
    <div>
      <ResuableHeader header='Brake Maintenance Tips' />
      <StateRegulationCarousel carouselDataa={TaxBenefitsContent} />
    </div>
  );
};

export default BrakeMaintenance;
