import './calculator.scss';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';
import { useState, useEffect } from 'react';
import CE from '../../Assets/Images/CE.svg';
import { HandleScrollToTop } from '../../APIs/CommonLogics';
import { numberFormat } from '../../utils/Helper';

const Calculator = ({ title = '' }) => {
  document.title = title;
  const [noVehicle, setNoVehicle] = useState('');
  const averageValue = 3;
  const [revenue, setRevenue] = useState('0');
  const [electricityCost, setElectricityCost] = useState(0);
  const [profitDay, setProfitDay] = useState(revenue - electricityCost);
  const [yearProfit, setYearProfit] = useState(0);
  const [priceProduct, setPriceProduct] = useState(14000);
  const [payBackPeriod, setPayBackPeriod] = useState(0);

  useEffect(() => {
    calc();
  },[]);

  function calc() {
    const revenueCost = averageValue * 15 * noVehicle;
    const eleCost = averageValue * 7 * noVehicle;
    const profit = revenueCost - eleCost;
    setRevenue(revenueCost);
    setElectricityCost(eleCost);
    setProfitDay(profit);
    setYearProfit(profit * 365);
    setPayBackPeriod(
      noVehicle === '' ? 0 : ((priceProduct / yearProfit) * 365).toFixed(0)
    );
  }

  const setValueNoVehicle = async e => {
    setNoVehicle(e.target.value);
  };

  const handleChange = e => {
    // console.log(e.target.value);
    setPriceProduct(e.target.value);
  };
  HandleScrollToTop();

  return (
    <>
      <ResuableHeader
        header='Calculate Earnings'
        url='/dashboard'
        cardImg={CE}
        bgc='#2F0E53'
        headerDesc='See how much you can earn on setting up a charging station'
        cardYes={true}
      />
      <section className='calculator-info'>
        <div>
          <div className={'calculator-details'}>
            <div className='cal-text'>
              No. of vehicles
              <span>(vehicles charged /day)</span>
            </div>
            <div className='cal-number'>
              <input
                type='number'
                className='cal-input rv_custom dark-grey'
                placeholder='0'
                value={noVehicle}
                onChange={setValueNoVehicle}
              />
              {/* <hr className="hr-width" /> */}
            </div>
          </div>
        </div>

        <div>
          <div className={'calculator-details'}>
            <div className='cal-text'>
              Electricity used
              <span>(Avg. units consumed /vehicle)</span>
            </div>
            <div className='cal-number'>
              <input
                type='number'
                disabled
                value={averageValue}
                className='cal-input rv_custom dark-grey'
              />

              {/* <hr className="hr-width" /> */}
            </div>
          </div>
        </div>

        <div>
          <div className={'calculator-details'}>
            <div className='cal-text'>
              Revenue per day
              <span>(₹ 15 /unit charged)</span>
            </div>
            <div className='cal-number'>
              <input
                type='text'
                value={`₹ ${numberFormat(revenue)}`}
                className='cal-input rv_custom light-grey'
                disabled
              />
            </div>
          </div>
        </div>

        <div>
          <div className={'calculator-details'}>
            <div className='cal-text'>
              Electricity cost per day
              <span>(₹ 7 /unit paid)</span>
            </div>
            <div className='cal-number'>
              <input
                type='text'
                value={`₹ ${numberFormat(electricityCost)}`}
                className='cal-input rv_custom light-greylight-grey'
                disabled
              />

              {/* <hr className="hr-width" /> */}
            </div>
          </div>
          <hr />
        </div>

        <div>
          <div className='calculator-details'>
            <div className='cal-text'>Profit /day</div>
            <div className='cal-number'>
              <input
                type='text'
                value={`₹ ${numberFormat(profitDay)}`}
                className='cal-input rv_custom light-grey'
                disabled
              />
            </div>
          </div>
        </div>

        {/*  */}

        <div>
          <div className={'calculator-details-pink'}>
            <div className='cal-text'>Profit /year</div>
            <div className='cal-number'>
              <input
                type='text'
                value={`₹ ${numberFormat(yearProfit)}`}
                className='cal-profit-input rv_custom_pink light-greylight-grey'
              />
            </div>
          </div>
        </div>

        <div>
          <div className={'calculator-details'}>
            <div className='cal-text'>Setup cost</div>
            <div className='cal-number'>
              <select onChange={handleChange} value={priceProduct}>
                <option selected value='14000'>
                  ₹ 14,000
                </option>
                <option value='5500'>₹ 5,500</option>
              </select>

              {/* <hr className="hr-width" /> */}
            </div>
          </div>
          <hr />
        </div>

        <div>
          <div className={'calculator-details'}>
            <div className='cal-text'>Pay back period</div>
            <div className='cal-number'>
              <div className='cal-input rv_custom light-grey'>
                {numberFormat(payBackPeriod)} days
              </div>

              {/* <hr className="hr-width" /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Calculator;
