import { useEffect } from 'react';
import CarApi from './CarApi.json';
import BikeApi from './BikeApi.json';
import ScooterApi from './ScooterApi.json';

export const VehicleAPI = type => {
  if (type === 'car') {
    const car = CarApi.data.products;
    return car;
  }
  if (type === 'bike') {
    const bike = BikeApi.data.products;
    return bike;
  }
  if (type === 'scooter') {
    const scooter = ScooterApi.data.products;
    return scooter;
  }
};

export const HandleScrollToTop = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, []);
};
export const HandleScrollToBottom = () => {
  // alert("acrool")
  useEffect(() => {
    window.scrollTo({
      bottom: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
};

export const HandleHideScroll = () => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);
};
