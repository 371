import React from 'react';
import './StickyHeader.scss';

const StickyHeader = props => {
  return (
    <>
      <div className='sticky-header'>{props.children}</div>
    </>
  );
};

export default StickyHeader;
