import './kazam-mini.scss';
import kazamMini from '../../Assets/Images/kazam-mini.png';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';
import { HandleScrollToTop } from '../../APIs/CommonLogics';

const kazammini = ({ title }) => {
  document.title = title;
  HandleScrollToTop();
  return (
    <>
      <ResuableHeader header='Install' />
      <section className='product-details-wrapper'>
        <div className='product-describe'>
          <div className='product-describe-img'>
            <img className='kazam-img' src={kazamMini} alt='Kazam-Mini' />
          </div>
          <div className='product-description'>
            <div className='product-name'>kazam Mini</div>
            <div className='product-rate'>₹ 5,500.00</div>
          </div>
        </div>
        <div className='description'>
          <p className='desc-heading'>Description</p>
          <div className='list'>
            <ul className='product-ul-list'>
              <li className='product-list'>3.3 KW AC Smart Charging Station</li>
              <li className='product-list'>Wi-fi Based IOT Enabled</li>
              <li className='product-list'>
                Supports: Electric Car, Electric Bike, Electric Scooter
              </li>
              <li className='product-list'>
                3 Pinpoint, 16A Socket Charger, GSM(optional)
              </li>
            </ul>
          </div>
        </div>
      </section>

      <p className='tech-spec-para'>Technical Specifications</p>

      <section className='technical-specifications-wrapper'>
        <div className='kazam-product-details'>
          <div className='kazam-product-data'>IP65 certified</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Shock Resistant</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Theft Proof</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Water Resistant</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Tamper Proof</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />
        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Plastic Body</div>
          <div className='kazam-product-value'>PC-ABS blend</div>
        </div>
        <hr className='border-line' />
        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Color</div>
          <div className='kazam-product-value'>
            Off white &<br />
            Black combination
          </div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Voice Enabled</div>
          <div className='kazam-product-value'>NO</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Ip(max)</div>
          <div className='kazam-product-value'>20 Amp</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Screen /display</div>
          <div className='kazam-product-value'>NO</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Vp(max)</div>
          <div className='kazam-product-value'>280 V</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Length</div>
          <div className='kazam-product-value'>20 cm</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Width</div>
          <div className='kazam-product-value'>19 cm</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Depth</div>
          <div className='kazam-product-value'>9 cm</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Power Ranging</div>
          <div className='kazam-product-value'>3.3 kW, AC</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Smart LED</div>
          <div className='kazam-product-value'>Multi Colored</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>
            All Weather
            <br />
            functionality
          </div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Connectivity</div>
          <div className='kazam-product-value'>BLE / Wi-Fi</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>MCB and Fuse</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>
            Energy Measurement
            <br />
            Accuracy
          </div>
          <div className='kazam-product-value'>99%</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Phase</div>
          <div className='kazam-product-value'>Single phase</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Support</div>
          <div className='kazam-product-value'>
            on-demand
            <br />
            via app/web
          </div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>OTA Support</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Schedulable</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Socket</div>
          <div className='kazam-product-value'>
            Universal /<br /> Industrial
          </div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>
            Kazam Charging
            <br />
            Management Software
          </div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>
            Kazam Load
            <br />
            Management Software
          </div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Cloud Based</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>OCPP Enabled</div>
          <div className='kazam-product-value'>
            YES,
            <br />
            Global OCPP Protocol
          </div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>OCPI Enabled</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Rates Differentials</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Multiple Dashboards</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>GPS Enabled</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Real Time Visibility</div>
          <div className='kazam-product-value'>YES</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Warranty</div>
          <div className='kazam-product-value'>1 Year</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Socket Standards</div>
          <div className='kazam-product-value'>IS1293 : 2019</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Electrical Safety</div>
          <div className='kazam-product-value'>IEC 60898-1</div>
        </div>
        <hr className='border-line' />

        <div className='kazam-product-details'>
          <div className='kazam-product-data'>Weight</div>
          <div className='kazam-product-value'>1.2 kg</div>
        </div>
      </section>

      <p className='kazam-interested-para'>
        If you are interested, please visit www.kazam.in
      </p>
    </>
  );
};

export default kazammini;
