import { HandleScrollToTop } from '../../APIs/CommonLogics';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';
import installIcon from '../../Assets/Images/Install.svg';
import { productContent } from '../../Routes/Dashboard-Route';
import { NavLink } from 'react-router-dom';
import './install.scss';

const install = ({ title = '' }) => {
  document.title = title;
  HandleScrollToTop();
  const proDetails = productContent;
  return (
    <>
      <ResuableHeader
        header='Install'
        cardImg={installIcon}
        bgc='#4D0D80'
        headerDesc='Setup charging units at your own place'
        cardYes={true}
      />
      {proDetails.map((details, index) => {
        return (
          <section key={index} className='product-details-wrapper'>
            <div className='product-describe'>
              <div className='product-describe-img'>
                <img
                  className='kazam-img'
                  src={details.productImg}
                  alt='Kazam-3.3'
                />
              </div>
              <div className='product-description'>
                <div className='product-name'>{details.productName}</div>
                <div className='product-rate'>{details.productRate}</div>
              </div>
            </div>
            <div className='description'>
              <p className='desc-heading'>Description</p>
              <div className='list'>
                <ul className='product-ul-list'>
                  <li className='product-list'>{details.listOne}</li>
                  <li className='product-list'>{details.listTwo}</li>
                  <li className='product-list'>{details.listThree}</li>
                  <li className='product-list'>{details.listFour}</li>
                </ul>
              </div>
            </div>
            <div className='know-more-link'>
              <NavLink to={details.moreDetailLink}>
                <button type='button' className='know-more-btn'>
                  Know more
                </button>
              </NavLink>
            </div>

            <div className='hr-rule'>{details.removehr && <hr />}</div>
          </section>
        );
      })}
      <br/><br/>
    </>
  );
};

export default install;
