import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,   
}

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers:  {
        openLoader: (state, action) => {
            state.isOpen = true
        },
        closeLoader: (state, action) => {
            state.isOpen = false
        }
    }
})

export const {openLoader, closeLoader} = loaderSlice.actions

export default loaderSlice.reducer