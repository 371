import { createSlice } from "@reduxjs/toolkit";
import CarApi from "../APIs/CarApi.json";
import BikeApi from "../APIs/BikeApi.json";
import ScooterApi from "../APIs/ScooterApi.json";
const defaultPriceFilter = {
  range: { min: 1, max: 2000 },
  value: { min: 1, max: 2000 },
  step: 1,
  stepValue: 100000,
  label: "Lacs",
  isFilterApplied: false,
};
let defaultFilter = {
  brand: [...new Set(CarApi.data.products.map((d) => d.make_org_name))]
    .sort()
    .map((d) => ({ name: d, isChecked: false, isFilterApplied: false })),
  price: defaultPriceFilter,
};

// let d=[...new Set(d.data.products.map(d=> d.make_org_name))].sort().map(d=>({name:d,isChecked:false}))

let defaultBike = {
  brand: [...new Set(BikeApi.data.products.map((d) => d.make_org_name))]
    .sort()
    .map((d) => ({ name: d, isChecked: false, isFilterApplied: false })),
  price: {
    range: { min: 1, max: 2000 },
    value: { min: 1, max: 2000 },
    step: 1,
    stepValue: 1000,
    label: "K",
    isFilterApplied: false,
  },
};

let defaultScooter = {
  brand: [...new Set(ScooterApi.data.products.map((d) => d.make_org_name))]
    .sort()
    .map((d) => ({ name: d, isChecked: false, isFilterApplied: false })),
  price: {
    range: { min: 1, max: 2000 },
    value: { min: 1, max: 2000 },
    step: 1,
    stepValue: 1000,
    label: "K",
    isFilterApplied: false,
  },
};

const initialState = {
  filter: {
    car: defaultFilter,
    bike: defaultBike,
    scooter: defaultScooter,
    isFilterApplied: false,
  },
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setBrandFilter: (state, action) => {
      const { data, type } = action.payload;
      // console.log(data, type);
      state.filter[type].brand = [...data.brand];
    },
    clearFilter: (state, action) => {
      // console.log(state);
      const { type, brand } = action.payload;
      // console.log(brand);
      let defaultValuesObj = {
        range: { min: 1, max: 2000 },
        value: { min: 1, max: 2000 },
        step: 1,
        stepValue: 100000,
        label: "Lacs",
        isFilterApplied: false,
      };
      state.filter[type].price = { ...defaultValuesObj };
      state.filter[type].brand = state.filter[type].brand.map((d) => {
        d.isChecked = false;
        // console.log(d);
        return d;
      });
    },
    setPriceRange: (state, action) => {
      const { data, type } = action.payload;
      const min = data.min;
      const max = data.max;
      const obj = { min, max };
      // console.log({min,max})
      state.filter[type].price = {
        ...state.filter[type].price,
        value: obj,
        isFilterApplied: data?.isFilterApplied,
      };
    },
    toggleFilterApplyState: (state, action) => {
      state.isFilterApplied = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBrandFilter,
  clearFilter,
  setPriceRange,
  toggleFilterApplyState,
} = filterSlice.actions;

export default filterSlice.reducer;
