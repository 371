import React from 'react';

import insight from '../../Assets/Images/launching-soon-icon.svg';

import LScharging from '../../Assets/Images/LS-EV-charging.svg';
import LSRTO from '../../Assets/Images/LS-RTO.svg';
import LSCommunity from '../../Assets/Images/LS-EV-Community.svg';
import LSRSA from '../../Assets/Images/coming-soon-rsa.svg';

import { useNavigate } from 'react-router-dom';

import './launching-soon.scss';
const LaunchingSoon = () => {
  const navigate = useNavigate();

  const waitBeforeShow = path => {
    setTimeout(() => {
      navigate(path);
    }, 500);
  };

  let items = [
    {
      title: 'EV Charging Station Slot Booking',
      desc: 'Book slot for charging your vehicle at your nearest charging station & avoid waiting in queues.',
      image: LScharging,
      bgColor: '#1D5363',
    },
    {
      title: 'Road Side Assistance',
      desc: 'Service to take care of your vehicle in an unfortunate event of vehicle breakdown or accident.',
      image: LSRSA,
      bgColor: '#CC7400',
    },
    {
      title: 'EV Community',
      desc: 'It’s an evolving space & you need peer guidance from people like you to take the leap of faith.',
      image: LSCommunity,
      bgColor: '#1A2073',
    }
  ];
  return (
    <>
      <div className='clickable-wrapper-block'>
        <section className='text-headers'>
          <div className='insight-text'>
            <img className='insight-img' src={insight} alt='' />
            <span className='insight-text-sub'>Launching Soon</span>
          </div>
        </section>
        <section className='click-card-container clickable-cards'>
          {items.map((d, i) => {
            return (
              <div
                 key={i}
                className='lsclick-card-one'
                style={{ backgroundColor: `${d.bgColor}` }}
              >
                <div className='ls-click-main'>
                  <img
                    src={d.image}
                    className='ls-inner_image_one'
                    alt='Scooter'
                  />
                  <div className='lsclick-card-one-para'>{d.title}</div>
                </div>
                <div className='ls-desc'>{d.desc}</div>
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
};

export default LaunchingSoon;
