import "./App.scss";
import "./index.scss";
import Planning from "./Components/PlanningTrip/Planning";
import Location from "./Components/Location/Location";
import LocationCard from "./Components/LocationCard/LocationCard";
import { ErrorBoundary } from "react-error-boundary";

import TextBlog from "./Pages/TextBlog/text-blog";
import VideoBlog from "./Pages/VideoBlog/video-blog";
import HomePage from "./Pages/HomePage/home-page";
import Nostation from "./Components/NoStationFound/Nostation";
import PageTitle from "./constants/pagestitle.json";

import StationOnWay from "./Components/StationOnWay/StnOnWay";
import Splash from "./Pages/Splash/splash";
import Blogs from "./Pages/Blogs/blogs";
import FindVehicle from "./Components/FindVehicle/FindVehicle";
import CostOfVehicle from "./Components/CostOfVehicle/CostOfVehicle";
import StateRegulation from "./Pages/StateRegulation/state-regulation";
import StateRegulationCarousel from "./Components/StateRegulationCarousel/StateRegulationCarousel";
import TaxBenefit from "./Pages/TaxBenefit/tax-benefit";
import Install from "./Pages/Install/install";
import Calculator from "./Pages/Calculator/calculator";

import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import BatteryMaintenance from "./Pages/BatteryMaintenance/battery-maintenance";
import BrakeMaintenance from "./Pages/BrakeMaintenance/brake-maintenance";
import WheelMaintenance from "./Pages/WheelMaintenance/wheel-maintenance";
import VehicleInfo from "./Components/VehicleInfo/VehicleInfo";
import KazamThree from "./Pages/KazamThree/kazam-three";
import KazamMini from "./Pages/KazamMini/kazam-mini";

import TextBlogDetails from "./Pages/TextBlogDetails/text-blog-details";
import VideoPreview from "./Pages/VideoBlog/video-preview";

import SignUp from "./Pages/UserAuthentication/sign-up";
import SignIn from "./Pages/UserAuthentication/sign-in";
import OtpVerification from "./Pages/UserAuthentication/otp-verification";
import UserProfile from "./Pages/UserProfile/user-profile";
import Test from "./Pages/test";
import ProfileVerification from "./Pages/UserProfile/user-profile-verification";

import UserPreference from "./Components/UserPreference/UserPreference";

import { useDispatch, useSelector } from "react-redux";
import UserVehicleInformation from "./Components/UserVehicleInformation/UserVehicleInformation";
import { useEffect } from "react";
import ReactGA from "react-ga";
import NewSplash from "./Pages/NewSplash/NewSplash";
import FCV from "./Pages/FCV/FCV";
import FCVDetails from "./Pages/FCV/FCV-Details";
import FCVLanding from "./Pages/FCV/FCV-Landing";
import CarLoader from "./Components/Loader/CarLoader";

import AutoComplete from "./Components/Autocomplete/Autocomplete";
import CalculatorNew from "./Pages/Calculator/CalculatorNew";
import { useNavigate } from "react-router-dom";

import CheckVehicleInfo from "./Components/CheckVehicleInfo/CheckVehicleInfo";
import VehicleRegistrationDetails from "./Components/CheckVehicleInfo/VehicleRegistrationDetails/VehicleRegistrationDetails";
import EChallan from "./Components/EChallan/EChallan";
import EChallanStatus from "./Components/EChallan/EChallanStatus/EChallanStatus";
import axios from "axios";
import store from "./store";
import { encrypt } from "./service/encryption";

function App() {
  const loggedIn = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //fallback component
  function ErrorFallback({ error }) {
    const navigate = useNavigate();
    const location = useLocation();
    return (
      <div className="error-page">
        <div className="div">
          <h2>We are not able to fetch</h2>
          <p>please try after something</p>

          <button
            className="go_back"
            onClick={() => {
              if (location.pathname === "/evinindia")
                return navigate("/signin", { replace: true });
              navigate(-1);
              setTimeout(() => {
                window.location.reload();
              }, 200);
            }}
          >
            Go Back
          </button>
        </div>
        {/* <pre style={{ color: 'red' }}>{error.message}</pre> */}
      </div>
    );
  }
  function errorHandle(error, errorInfo) {
    // console.log(error.name)
  }
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      if (err.response.status === 401) {
        // Access Token was expired
        store?.dispatch({ type: "USER_LOGOUT" });
        localStorage.removeItem("evtoken");
        navigate("/signin", { replace: true });
        return Promise.reject(err);
      }
      return Promise.reject(err);
    }
  );
  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_APPID}`);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    if (localStorage.getItem("evtoken") || loggedIn) {
      const payload = encrypt(
        JSON.stringify({
          projectName: "allthingsev",
          password: "kbkw$5&%allthingsevprod3#$b",
        })
      );
      axios
        .post(`${process.env.REACT_APP_GOOGLE_lOCATION_API}auth`, payload)
        .then((res) => {
          localStorage.setItem("GSauthToken", JSON.stringify(res?.data?.token));
        })
        .catch((error) => {
          alert("Something went wrong, Please sign in again");
          navigate("/signin", { replace: true });
          dispatch({ action: "USER_LOGOUT" });
        });
    }
  }, [loggedIn]);
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandle}>
        <Routes>
          <Route
            replace
            path="/evinindia"
            element={<HomePage title={PageTitle.home} />}
          />
          <Route
            path="/findelectricvehicle"
            element={<FindVehicle title={PageTitle.findvehicle} />}
          />

          <Route
            path="/findelectricvehicle/:type/:id"
            element={<VehicleInfo title={PageTitle.findvehicle} />}
          />
          <Route
            path="/evchargingstations"
            element={<Location title={PageTitle.location} />}
          />
          <Route
            path="/taxbenefitsofev"
            element={<TaxBenefit title={PageTitle.tax} />}
          />
          <Route path="/" element={<Splash title={PageTitle.splash} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn title={PageTitle.signin} />} />
          <Route
            path="/verification"
            element={<OtpVerification title={PageTitle.verification} />}
          />
          <Route path="*" element={<Navigate to={"/signin"} />} />
          {loggedIn ? (
            <>
              {/* New Splash Screen */}
              {/* MainPage */}
              <Route path="/" element={<Splash title={PageTitle.splash} />} />
              <Route path="*" element={<Navigate to={"/evinindia"} />} />
              <Route
                path="/userVehicleInfo"
                element={
                  <UserVehicleInformation title={PageTitle.userVehicleInfo} />
                }
              />
              <Route
                replace
                path="/userPreference"
                element={<UserPreference title={PageTitle.userPreference} />}
              />
              <Route
                path="/CarLoader"
                element={<CarLoader title={PageTitle.location} />}
              />
              <Route
                path="/nostationfound"
                element={<Nostation title={PageTitle.nostationfound} />}
              />
              <Route
                path="/stationsonway"
                element={<StationOnWay title={PageTitle.stationsonway} />}
              />
              {/* <Route path='/pincode' element={<Pincode />}  /> */}

              <Route
                path="/planatrip"
                element={<Planning title={PageTitle.planatrip} />}
              />
              <Route
                path="/planatrip/locationonway"
                element={<LocationCard title={PageTitle.planatrip} />}
              />

              {/* Install Sections */}
              <Route
                path="/install"
                element={<Install title={PageTitle.install} />}
              />
              <Route
                path="/kazamthree"
                element={<KazamThree title={PageTitle.kazamthree} />}
              />
              <Route
                path="/kazammini"
                element={<KazamMini title={PageTitle.kazammini} />}
              />

              {/* <Route
            path='/earningcalculator'
            element={<Calculator title={PageTitle.earningcalculator} />}
          /> */}
              <Route
                path="/earningcalculator"
                element={<CalculatorNew title={PageTitle.earningcalculator} />}
              />

              <Route
                path="checkvehicleinfo"
                element={
                  <CheckVehicleInfo title={PageTitle.checkvehicleinfo} />
                }
              />
              <Route
                path="/checkRegistrationDetails"
                element={<VehicleRegistrationDetails />}
              />
              <Route
                path="/e-challan"
                element={<EChallan title={PageTitle.eChallan} />}
              />
              <Route
                path="/e-challan-status"
                element={<EChallanStatus title={PageTitle.eChallan} />}
              />
              {/* TCO Vehicle */}
              {/* <Route
            path='/tcocalculator'
            element={<CostOfVehicle title={PageTitle.tcocalculator} />}
          /> */}
              <Route
                path="/tcocalculator"
                element={<FCV title={PageTitle.tcocalculator} />}
              />

              <Route
                path="/FCVLanding"
                element={<FCVLanding title={PageTitle.tcocalculator} />}
              />
              <Route
                path="/state"
                element={<StateRegulation title={PageTitle.state} />}
              />
              <Route
                path="/state/:id"
                element={<StateRegulationCarousel title={PageTitle.state} />}
              />

              <Route
                path="/battery"
                element={<BatteryMaintenance title={PageTitle.battery} />}
              />
              <Route
                path="/break"
                element={<BrakeMaintenance title={PageTitle.break} />}
              />
              <Route
                path="/wheel"
                element={<WheelMaintenance title={PageTitle.wheel} />}
              />

              {/* Blogs Section */}
              {/* <Route path='/blogs' element={<Blogs title={PageTitle.blogs} />} /> */}

              <Route path="/blogs" element={<Blogs title={PageTitle.blogs} />}>
                {/* <Route path='/blogs/' element={<TextBlog />} />
            <Route path='/blogs/video' element={<VideoBlog />} /> */}
              </Route>

              <Route
                path="/blogs/:id"
                element={<TextBlogDetails title={PageTitle.blogs} />}
              />
              <Route
                path="/blogs/video/:id"
                element={<VideoPreview title={PageTitle.blogs} />}
              />
              <Route
                path="newsplash"
                element={<NewSplash title={PageTitle.home} />}
              />
              <Route
                path="/user-profile"
                element={<UserProfile title={PageTitle.blogs} />}
              />
              <Route
                path="/profile-verification"
                element={
                  <ProfileVerification title={PageTitle.pverification} />
                }
              />
              <Route path="/FCV" element={<FCV title={PageTitle.cov} />} />
              <Route
                path="/FCV/:id"
                element={<FCVDetails title={PageTitle.covdetails} />}
              />

              <Route
                path="/AutoComplete"
                element={
                  <AutoComplete
                    suggestions={[
                      "Alligator",
                      "Bask",
                      "Crocodilian",
                      "Death Roll",
                      "Eggs",
                      "Jaws",
                      "Reptile",
                      "Solitary",
                      "Tail",
                      "Wetlands",
                      "Blessing",
                    ]}
                  />
                }
              />
              <Route
                path="/CalculatorNew"
                element={<CalculatorNew title={PageTitle.blogs} />}
              />
            </>
          ) : (
            <>
              {/* <Route path="/" element={<Splash title={PageTitle.splash} />} />
              <Route path="/signup" element={<SignUp />} />
              <Route
                path="/signin"
                element={<SignIn title={PageTitle.signin} />}
              />
              <Route
                path="/verification"
                element={<OtpVerification title={PageTitle.verification} />}
              />
              <Route path="*" element={<Navigate to={"/signin"} />} /> */}

              {/* <Route path='/test' element={<Test title={PageTitle.signin} />} /> */}
            </>
          )}
        </Routes>
      </ErrorBoundary>
    </>
  );
}

export default App;
