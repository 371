import "./UserProfile.scss";
import ptb from "../../Assets/Images/UP1_icon.svg";
import ete from "../../Assets/Images/UP2_icon.svg";
import aoe from "../../Assets/Images/UP3_icon.svg";
import { useState, useEffect, useRef } from "react";
import Loader from "../../Components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "../../Assets/Images/Edit_icon1.svg";
import { useSelector, useDispatch } from "react-redux";
import { sendOtp, updateProfile, resetLogin } from "../../slice/loginSlice";
import ResuableHeader from "../../Components/ResuableHeader/ResuableHeader";
import {
  sendPreference,
  updateEVDatailsLocalStorage,
} from "../../slice/loginSlice";
import { Box, Modal } from "@mui/material";
import OtpVerification from "./user-profile-verification";
import { updateUserDetails } from "../../slice/userDetailsSlice";
import { setToken } from "../../slice/auth";
import { encrypt } from "../../service/encryption";

const UserProfile = ({ title = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { vehicleNoFromUserVehInfoComp, userPreference, data } = state || {};
  document.title = title;
  const {
    mobile: mobileno_FromLS,
    name: tName,
    email: tEmail,
    grNumber: tGrNumber,
    userPreference: tUserPreference,
  } = useSelector((state) => state.userDetails.user);

  const [name, setName] = useState(tName);
  const [email, setEmail] = useState(tEmail);
  const [mobileno, setMobileno] = useState(mobileno_FromLS);
  const [grNumber, setGrNumber] = useState(
    vehicleNoFromUserVehInfoComp || tGrNumber || data || ""
  );
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [emailDisabled, setEmailDisabled] = useState(true);
  const [mobileDisabled, setMobileDisabled] = useState(true);
  const [profileUpdated, setProfileUpdated] = useState("");
  const [isValid, setIsValid] = useState(tEmail ? true : false);
  const [isBlur, setIsBlur] = useState(false);
  const [isVehicleValid, setIsVehicleValid] = useState(
    tGrNumber ? true : false
  );
  const [isVehicleBlur, setIsVehicleBlur] = useState(false);
  const [isMobileNoValid, setIsMobileNoValid] = useState(
    mobileno_FromLS ? true : false
  );
  const [onSubmitErrorList, setOnSubmitErrorList] = useState({
    isMobileError: false,
    isVehicleError: false,
    isEmailError: false,
  });
  const [showAllErrorOnSubmit, setShowAllErrorOnSubmit] = useState(false);
  const [isMobileFocused, setIsMobileFocused] = useState(false);
  const [isEditingStarted, setIsEditingStarted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [otpVerificationResponse, setOtpVerificationResponse] = useState(null);

  const { pvotpStatus } = useSelector((state) => state.users);

  let list = [
    { label: "Planning to buy an EV?", value: "planningToBuy", image: ptb },
    {
      label: "Already own an EV",
      value: "alreadyOwned",
      image: ete,
    },
    { label: "Earn through EV", value: "earnThroughEv", image: aoe },
  ];

  // Validation Function
  function validateName(str) {
    if (/^[a-zA-Z\s]+$/.test(str)) {
      setError(false);
      return true;
    }
    // setError('Enter Valid Name');
  }

  function validateMobileNumber(str) {
    if (/^[1-9][0-9]{9}$/.test(str)) return true;
    return false;
    // setError('Enter Valid Number');
  }

  const EmailBlurHandler = (str) => {
    if (str?.length > 0) {
      setIsBlur(true);
    } else {
      setIsBlur(false);
      setOnSubmitErrorList((prev) => {
        let obj = prev;
        obj.isEmailError = false;
        return { ...obj };
      });
    }
    // if (str !== "") {
    //   if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(str)) {
    //     setIsValid(true);
    //   } else {
    //     setIsValid(false);
    //   }
    // } else {
    //   setIsValid(true);
    // }
  };

  const vehicleBlurHandler = (str) => {
    if (str?.length > 0) {
      setIsVehicleBlur(true);
    } else {
      setIsVehicleBlur(false);
      setOnSubmitErrorList((prev) => {
        let obj = prev;
        obj.isVehicleError = false;
        return { ...obj };
      });
    }
    if (
      /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$/.test(str) ||
      /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/.test(str)
    ) {
      setIsVehicleValid(true);
    } else {
      setIsVehicleValid(false);
    }
  };

  const [loader, setLoader] = useState(false);

  const validateEmail = (str) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(str)) return true;
    return false;
  };

  const validateVehicleNo = (str) => {
    if (
      /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$/.test(str) ||
      /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/.test(str)
    )
      return true;
    return false;
  };
  const checkForValidateChange = (type, str) => {
    switch (type) {
      case "mobile":
        if (validateMobileNumber(str)) {
          setBtnDisabled(false);
          setIsMobileNoValid(true);
          setOnSubmitErrorList((prev) => {
            let obj = prev;
            obj.isError = false;
            return { ...obj };
          });
          return true;
        } else {
          setIsMobileNoValid(false);
        }
        break;
      case "name":
        if (validateName(str)) {
          setOnSubmitErrorList((prev) => {
            let obj = prev;
            obj.isError = false;
            return { ...obj };
          });
          setBtnDisabled(false);
          return true;
        }
        break;
      case "email":
        if (validateEmail(str)) {
          setBtnDisabled(false);
          setIsValid(true);
          setOnSubmitErrorList((prev) => {
            let obj = prev;
            obj.isError = false;
            return { ...obj };
          });
          return true;
        } else {
          setIsValid(false);
        }
        break;
      case "vehicle":
        if (validateVehicleNo(str)) {
          setBtnDisabled(false);
          setIsVehicleValid(true);
          setOnSubmitErrorList((prev) => {
            let obj = prev;
            obj.isError = false;
            return { ...obj };
          });
          return true;
        } else {
          setIsVehicleValid(false);
        }
        break;
      default:
        break;
    }
  };

  const handleOnChange = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (!e.target.value) setMobileno(e.target.value);
    if (!re.test(e.target.value)) {
      return;
    }
    // const reg = /^[0-9\b]+$/;
    setIsEditingStarted(true);
    setOnSubmitErrorList((prev) => {
      let obj = prev;
      obj.isChanges = true;
      return { ...obj };
    });
    if (!e.target.value) {
      setBtnDisabled(false);
      setOnSubmitErrorList((prev) => {
        let obj = prev;
        obj.isError = false;
        return { ...obj };
      });
    }
    checkForValidateChange("mobile", e.target.value);
    setMobileno(e.target.value);
  };

  // useEffect(() => {
  //   if (
  //     (mobileno && validateMobileNumber(mobileno)) ||
  //     (name && validateName(name) && email && validateEmail(email)) ||
  //     (grNumber && validateVehicleNo(grNumber))
  //   ) {
  //     setBtnDisabled(false);
  //   } else {
  //     setBtnDisabled(true);
  //   }
  // }, [email, grNumber, mobileno, name]);

  const { otpStatus, updateProfile: updateProfileTrack } = useSelector(
    (state) => state.users
  );
  const { viewedCOVScreen } = useSelector((state) => state.cov);

  useEffect(() => {
    if (otpStatus.status) {
      if (otpStatus.status !== "success") {
        setLoader(false);
        // alert(JSON.stringify(otpStatus));
        setProfileUpdated(otpStatus.message);
      } else {
        setLoader(false);
        navigate("/profile-verification", { state: { name, email } });
      }
    }
    return () => {
      // dispatch(resetLogin());
    };
  }, [otpStatus.status, otpStatus.message, dispatch, navigate, name, email]);

  useEffect(() => {
    if (updateProfileTrack.status) {
      setLoader(false);
      setProfileUpdated(updateProfileTrack.message);
    }
  }, [
    updateProfileTrack.status,
    updateProfileTrack.message,
    dispatch,
    navigate,
  ]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetLogin());
  //   };
  // }, [dispatch]);

  const mobileField = !error ? "text-boxes" : "show-error";

  const [isDisabled, setIsDisabled] = useState(true);
  const mobileRef = useRef();

  const Reset = () => {
    setMobileno("");
    setIsDisabled(false);
    // Focus input field
    setTimeout(() => {
      mobileRef.current.focus();
    }, 100);
  };

  const { mobileNo, preference } = useSelector((state) => state.users);
  const { preferenceStatus } = useSelector(
    (state) => state.users.preferenceStatus
  );

  let myPreference = localStorage.getItem("preference");

  const [activePreference, setActivePreference] = useState(
    userPreference || tUserPreference
  );

  const onBoarding = async (obj) => {
    setActivePreference(obj.value);

    if (obj.value === "alreadyOwned") {
      if (grNumber && isVehicleValid) return;
      // localStorage.setItem("tempPreference", obj.value);
      // dispatch(updateEVDatailsLocalStorage({ userPreference: obj.value }));
      dispatch(updateUserDetails({ userPreference: obj.value }));
      window.history.pushState("", "", "/user-profile");
      navigate("/userVehicleInfo", {
        replace: true,
        state: {
          grNumber,
          from: "user-profile",
        },
      });
    }
  };

  const selectedPreference = list.filter(
    (e) => e.value === (userPreference || tUserPreference)
  );

  const list2 = list.filter(
    (e) => e.value !== (userPreference || tUserPreference)
  );
  const handleSubmit = (e) => {
    let Error = 0;
    let inChanges = 0;
    if (
      btnDisabled &&
      (isValid || !email) &&
      (isVehicleValid || !grNumber) &&
      (isMobileNoValid || !mobileno)
    ) {
      setShowAllErrorOnSubmit(true);
      setOnSubmitErrorList((prev) => {
        let obj = prev;
        obj.isChanges = false;
        return { ...obj };
      });
      return;
    }
    if (inChanges) return;
    if (btnDisabled && (!isValid || !isVehicleValid || !isMobileNoValid)) {
      setShowAllErrorOnSubmit(true);
      setOnSubmitErrorList((prev) => {
        let obj = prev;
        obj.isError = true;
        return { ...obj };
      });
      return;
    }

    if (
      btnDisabled &&
      ((!isValid && email) ||
        (!isMobileNoValid && mobileno) ||
        (!isVehicleValid && grNumber))
    ) {
      setShowAllErrorOnSubmit(true);
      setOnSubmitErrorList((prev) => {
        let obj = prev;
        obj.isError = true;
        return { ...obj };
      });
      return;
    } else if (!mobileno && !btnDisabled) {
      setShowAllErrorOnSubmit(true);
      setOnSubmitErrorList((prev) => {
        let obj = prev;
        obj.isError = true;
        return { ...obj };
      });
      return;
    }

    if (!mobileno) {
      setShowAllErrorOnSubmit(true);
      setOnSubmitErrorList((prev) => {
        let obj = prev;
        obj.isMobileError = true;
        return { ...obj };
      });
      setIsMobileNoValid(false);
      Error = 1;
    }
    if (!btnDisabled) {
      if (mobileno && checkForValidateChange("mobile", mobileno)) {
        setShowAllErrorOnSubmit(false);
      } else {
        setShowAllErrorOnSubmit(true);
        setOnSubmitErrorList((prev) => {
          let obj = prev;
          obj.isMobileError = true;
          return { ...obj };
        });
        Error = 2;
      }
      if ((email && checkForValidateChange("email", email)) || !email) {
        setShowAllErrorOnSubmit(false);
      } else {
        if (email) setShowAllErrorOnSubmit(true);
        setOnSubmitErrorList((prev) => {
          let obj = prev;
          obj.isEmailError = true;
          return { ...obj };
        });
        Error = 3;
      }
      if (
        (grNumber && checkForValidateChange("vehicle", grNumber)) ||
        !grNumber
      ) {
        setShowAllErrorOnSubmit(false);
      } else {
        if (grNumber) setShowAllErrorOnSubmit(true);
        setOnSubmitErrorList((prev) => {
          let obj = prev;
          obj.isVehicleError = true;
          return { ...obj };
        });
        Error = 4;
      }
    }
    if (Error) return;
    if (btnDisabled) return;
    //update or add new number and update decision maker
    if (mobileno !== mobileno_FromLS) {
      setShowModal(true);
      return;
    }
    if (!loader) {
      e.preventDefault();
      if (mobileno === mobileno_FromLS) {
        setLoader(true);
        dispatch(
          updateProfile(
            encrypt(
              JSON.stringify({
                mobile: mobileno_FromLS,
                name: name,
                email: email,
                grNumber: grNumber,
              })
            )
          )
        );
        // if (activePreference !== "alreadyOwned") {
        dispatch(
          updateEVDatailsLocalStorage({ userPreference: activePreference })
        );
        dispatch(
          sendPreference({
            preference: activePreference,
            mobile: mobileno,
            skipGrNumber: grNumber ? true : false,
            grNumber,
          })
        );
        // }
        setLoader(false);
      } else {
        setLoader(true);
        dispatch(sendOtp({ mobile: mobileno }));
        setLoader(false);
      }
      setBtnDisabled(true);
    }
  };
  useEffect(() => {
    if (updateProfileTrack?.status === "success") {
      dispatch(
        updateUserDetails({
          name,
          email,
          mobile: mobileno,
          grNumber,
          skipGrNumber: grNumber ? false : true,
          userPreference: activePreference,
          viewedCOVScreen,
        })
      );
    }
    if (pvotpStatus?.status === "success") {
      dispatch(setToken({ token: localStorage.getItem("evtoken") }));
      dispatch(
        updateUserDetails({
          name,
          email,
          mobile: mobileno,
          grNumber,
          skipGrNumber: grNumber ? false : true,
          userPreference: activePreference,
          viewedCOVScreen,
        })
      );
      setBtnDisabled(true);
      setIsDisabled(true);
    } else if (pvotpStatus?.status === "failed") {
      mobileRef.current.focus();
    }
    if (
      updateProfileTrack?.message ||
      otpStatus?.message ||
      pvotpStatus?.message
    ) {
      setTimeout(() => {
        dispatch(resetLogin());
      }, 2000);
    }
  }, [
    activePreference,
    dispatch,
    email,
    grNumber,
    mobileno,
    name,
    otpStatus?.message,
    pvotpStatus?.message,
    pvotpStatus?.status,
    updateProfileTrack?.message,
    updateProfileTrack?.status,
    viewedCOVScreen,
  ]);
  useEffect(() => {
    if (userPreference && vehicleNoFromUserVehInfoComp)
      return setBtnDisabled(false);
  }, [userPreference, vehicleNoFromUserVehInfoComp]);

  // useEffect(() => {
  //   const getMobile = window.localStorage.getItem("evdetails"); // need api to fetch user details
  //   const {
  //     mobile: mobileno_FromLS,
  //     name: tName,
  //     email: tEmail,
  //     grNumber: tGrNumber,
  //   } = JSON.parse(getMobile);
  //   setMobileno_FromLS(mobileno_FromLS);
  //   setTname(tName);
  //   setEmail(tEmail);
  //   setTGrNumber(tGrNumber);
  //   setName(tName);
  //   setTemail(tEmail);
  //   setGrNumber(tGrNumber);
  //   setMobileno(mobileno_FromLS);
  //   setIsMobileNoValid(mobileno_FromLS ? true : false);
  //   setIsValid(tEmail ? true : false);
  //   setIsVehicleValid(tGrNumber ? true : false);
  // }, []);

  return (
    <>
      <ResuableHeader header={"My Profile"} />
      <p style={{ padding: "1px 25px", color: "dark-grey" }}>
        Changes will be lost if not saved or updated.
      </p>
      <main className="signup-main">
        <section className="input-boxes-main">
          <span className={mobileField}>
            <input
              type="text"
              className="input-boxes"
              placeholder="Enter Name"
              value={name}
              disabled={loader}
              onChange={(e) => {
                setOnSubmitErrorList((prev) => {
                  let obj = prev;
                  obj.isChanges = true;
                  return { ...obj };
                });
                setIsEditingStarted(true);
                setBtnDisabled(false);
                checkForValidateChange("name", e.target.value);
                setName(e.target.value);
              }}
            />
          </span>
          <span
            className={
              mobileField && isDisabled ? "text-boxDisable" : `text-boxes`
            }
          >
            <span className="input-group-addon">+91</span>
            <input
              type="tel"
              className={`input-boxes`}
              placeholder="Enter Mobile Number"
              required
              pattern="[0-9]+"
              autoComplete="off"
              maxLength={10}
              value={mobileno}
              onChange={handleOnChange}
              ref={mobileRef}
              disabled={isDisabled}
              onFocus={(e) => {
                setIsMobileFocused(true);
              }}
              onBlur={(e) => {
                setIsMobileFocused(false);
                if (mobileno === mobileno_FromLS) setIsDisabled(!isDisabled);
              }}
            />
            {!isMobileFocused &&
              mobileno === mobileno_FromLS &&
              isMobileNoValid && (
                <img
                  src={EditIcon}
                  alt=""
                  onClick={() => {
                    setIsDisabled(!isDisabled);
                    setTimeout(() => mobileRef.current.focus(), 0);
                  }}
                />
              )}
            {/* {mobileno !== mobileno_FromLS && isMobileNoValid && (
              <>
                <button
                  className="verify-otp-button"
                  onClick={(e) => setShowModal(true)}
                >
                  Verify OTP
                </button>
              </>
            )} */}
          </span>
          {/* {mobileno !== mobileno_FromLS && isMobileNoValid && (
            <p className="error">
              Please click verify otp to update newly entered number
            </p>
          )} */}
          {!isMobileNoValid && (
            <p className="error">
              {mobileno
                ? "Please enter valid mobile number"
                : "Please fill this mandatory field"}
            </p>
          )}
          <span
            className={
              mobileField && emailDisabled && email && false
                ? "text-boxDisable"
                : `text-boxes`
            }
          >
            <input
              type="email"
              className="input-boxes"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => {
                setIsEditingStarted(true);
                if (!e.target.value) {
                  setOnSubmitErrorList((prev) => {
                    let obj = prev;
                    obj.isError = false;
                    return { ...obj };
                  });
                  setBtnDisabled(false);
                }
                checkForValidateChange("email", e.target.value);
                EmailBlurHandler(e.target.value);
                setEmail(e.target.value);
                setOnSubmitErrorList((prev) => {
                  let obj = prev;
                  obj.isChanges = true;
                  return { ...obj };
                });
              }}
              // onBlur={() => EmailBlurHandler(email)}
            />
          </span>

          {isBlur && !isValid && (
            <p className="error">Please enter valid email Id</p>
          )}

          <span
            className={
              mobileField && mobileDisabled && false
                ? "text-boxDisable"
                : `text-boxes`
            }
          >
            <input
              type="text"
              className="input-boxes"
              placeholder="eg. MH04JJ1234"
              value={grNumber}
              onChange={(e) => {
                let value = e.target.value?.toUpperCase();
                if (!value) {
                  setOnSubmitErrorList((prev) => {
                    let obj = prev;
                    obj.isError = false;
                    return { ...obj };
                  });
                  setBtnDisabled(false);
                }
                setIsEditingStarted(true);
                checkForValidateChange("vehicle", value);
                vehicleBlurHandler(value);
                setGrNumber(value);
                setOnSubmitErrorList((prev) => {
                  let obj = prev;
                  obj.isChanges = true;
                  return { ...obj };
                });
              }}
              // onBlur={(e) => vehicleBlurHandler(e.target.value)}
            />
          </span>

          {isVehicleBlur && !isVehicleValid && (
            <p className="error">Please enter valid vehicle number</p>
          )}

          <div className="userPreference_profile">
            Your Preference
            <section className="click-card-container clickable-cards">
              <div
                className={
                  activePreference === selectedPreference[0].value
                    ? "bordercolor1"
                    : "bordercolor2"
                }
                onClick={() => {
                  onBoarding(selectedPreference[0]);
                }}
              >
                <img src={selectedPreference[0].image} alt="icons" />
                <span>{selectedPreference[0].label}</span>
              </div>
              {list2.map((d, index) => {
                return (
                  <>
                    <div
                      key={index}
                      id=""
                      // className="UserPreference_planning"
                      className={
                        activePreference === d.value
                          ? "bordercolor1"
                          : "bordercolor2"
                      }
                      onClick={() => {
                        setBtnDisabled(false);
                        onBoarding(d);
                      }}
                    >
                      <img src={d.image} alt="icons" />
                      <span>{d.label}</span>
                    </div>
                  </>
                );
              })}
            </section>
          </div>

          {/* <UserPreference /> */}
        </section>
        <div style={{ marginBottom: "15px" }}>
          {showAllErrorOnSubmit && (
            <>
              {/* {!isValid && onSubmitErrorList?.isEmailError && (
                <p className="error">Please enter valid email Id</p>
              )}
              {!isVehicleValid && onSubmitErrorList?.isVehicleError && (
                <p className="error">Please enter valid vehicle number</p>
              )}
              {!isMobileNoValid && onSubmitErrorList?.isMobileError && (
                <p className="error">
                  {mobileno
                    ? "Please enter valid mobile number"
                    : "Mobile number is mandatory"}
                </p>
              )} */}
              {onSubmitErrorList?.isChanges === false && (
                <p className="error">There are no changes done to update.</p>
              )}
              {onSubmitErrorList?.isError && (
                <>
                  {mobileno ? (
                    <p className="error">
                      Please fill all field correctly or empty incorrect field
                    </p>
                  ) : (
                    <p className="error">Mobile number is mandatory field</p>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div style={{ marginBottom: "18px" }}>
          {updateProfileTrack?.message && (
            <div className="profile-updated">{updateProfileTrack.message}</div>
          )}

          {otpStatus?.message && (
            <div className="profile-updated">{otpStatus.message}</div>
          )}
          {pvotpStatus?.message && (
            <div className="profile-updated">
              {pvotpStatus?.message === "Success"
                ? "Profile updated successfully"
                : pvotpStatus?.message ||
                  "Failed to update profile. Please try again"}
            </div>
          )}
        </div>
        {btnDisabled ? (
          <button className="btn-disabled" onClick={(e) => handleSubmit(e)}>
            Update
          </button>
        ) : (
          <button
            onClick={(e) => handleSubmit(e)}
            className={
              otpStatus.status === "loading" ||
              updateProfileTrack.status === "loading"
                ? "btn-disabled"
                : "btn-continue"
            }
          >
            {otpStatus.status === "loading" ||
            updateProfileTrack.status === "loading" ? (
              <Loader />
            ) : (
              "Update"
            )}
          </button>
        )}
      </main>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{ height: "100%", width: "100%", backgroundColor: "#141414" }}
      >
        <>
          <OtpVerification
            dataToUpdate={{
              name,
              email,
              newMobile: mobileno,
              grNumber,
              mobile: mobileno_FromLS,
              userPreference: activePreference,
              viewedCOVScreen,
            }}
            showHeader={false}
            sendOtpOnCompLoad={true}
            otpVerificationResponse={setOtpVerificationResponse}
            onCancel={setShowModal}
          />
        </>
      </Modal>
    </>
  );
};

export default UserProfile;
