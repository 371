import React from 'react';
import './FCV-Landing.scss';
import ChargingCost from '../../Assets/Images/fcv-charging-cost.svg';
import Insurance from '../../Assets/Images/fcv-insurance.svg';
import ServiceCost from '../../Assets/Images/fcv-service-cost.svg';
import TyrechangeCost from '../../Assets/Images/fcv-tyrechange-cost.svg';
import LossinValue from '../../Assets/Images/fcv-lossinvalue.svg';
import CostofoOwnership from '../../Assets/Images/fcv-costofownership.svg';
import { viewScreen } from '../../slice/covSlice';
import { useDispatch } from 'react-redux';
import ResuableHeader from '../../Components/ResuableHeader/ResuableHeader';

const FCVLanding = ({ onClick }) => {
  const serviceDetails = [
    { id: 1, image: ChargingCost, content: 'Charging Cost' },
    { id: 2, image: Insurance, content: 'Insurance' },
    { id: 3, image: ServiceCost, content: 'Service Cost' },
    { id: 4, image: TyrechangeCost, content: 'Tyre Change Cost' },
  ];
  const dispatch = useDispatch();
  const btnContinue = () => {
    dispatch(viewScreen({ key: true }));
    // console.log('viewScreen');
  };
  // console.log(onClick);
  return (
    <>
    <ResuableHeader header='Cost Of Vehicle' />
      <main className='FCV-Landing-main'>
        <section className='FCV-Landing-card'>
          Cost of Vehicle is an estimate of the total cost to own a car for a
          five year period. It includes all the expenses spent on charging cost,
          insurance, maintenance, repairs, service as well as the losses
          incurred due to depreciation of the car at the end of the same period.
        </section>

        <section className='FCV-Landing-services-main'>
          {serviceDetails.map((items, i) => {
            return (
              <div className='FCV-Landing-services' key={i}>
                <img src={items.image} alt='' />
                <span className='FCV-Landing-content'>{items.content}</span>
              </div>
            );
          })}
        </section>

        <section className='FCV-Landing-btnPlus'> + </section>

        <section className='FCV-Landing-loss-invalue'>
          <img src={LossinValue} alt='' />
          <span className='loss-invalue-text'>
            Loss in Value
            <span className='loss-invalue-light'>(Depreciation)</span>
          </span>
        </section>

        <section className='FCV-Landing-btnPlus'> = </section>

        <section className='FCV-Landing-loss-invalue'>
          <img src={CostofoOwnership} alt='' />
          <span className='loss-invalue-text'>Total cost of ownership</span>
        </section>

        <section className='FCV-Landing-btnMain'>
          <button className='FCV-Landing-btn' onClick={onClick}>
            Continue
          </button>
        </section>
      </main>
    </>
  );
};

export default FCVLanding;
