import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import close from "../../Assets/Images/Close-icon.svg";
import { applyFilter } from "../../slice/vehicle";
import { setBrandFilter, setPriceRange, clearFilter } from "../../slice/filter";
import { HandleHideScroll } from "../../APIs/CommonLogics";
import MultiRangeSlider from "multi-range-slider-react";
import debounce from "lodash.debounce";
import { Checkbox, Modal, Typography } from "@mui/material";
import { Slider as SliderMui } from "@mui/material";
import { makeStyles } from "@mui/styles";

import "./Filter.scss";
import { useEffect } from "react";
import { useCallback } from "react";
import { Box } from "@mui/system";

const Slider = ({ min, max, type, saveInStore }) => {
  const { range, value, step, label } = useSelector(
    (state) => state.filter.filter[type || "car"]?.price
  );
  // const { range, value, step, label } = filter.price;
  const [minValue, set_minValue] = useState(value.min);
  const [maxValue, set_maxValue] = useState(value.max);
  const [values, setValues] = useState([value.min, value.max]);
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .Mui-active": {
        boxShadow: "none",
      },
      "&.MuiSlider-root": {
        color: "#14AE5C",
      },
      "& .MuiSlider-thumb": {
        border: "2px solid #14AE5C",
        background: "white",
      },
      "& .MuiSlider-rail": {
        background: "#2e8a2b",
      },
      "& .MuiSlider-valueLabelOpen": {
        background: "#264325",
      },
    },
  }));
  const classes = useStyles();
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
    debouncedSave({ min: e.minValue, max: e.maxValue });
  };
  const debouncedSave = useRef(
    debounce((nextValue) => saveInStore(nextValue), 100)
  ).current;

  const setPriceRange = (val) => {
    if (type === "car") {
      if (val > 99) {
        return val / 100 + " Crore";
      } else {
        return val + " " + label;
      }
    } else {
      if (val > 99) {
        return val / 100 + " Lac";
      } else {
        return val + " " + label;
      }
    }
  };

  const handleChange = (e, number) => {
    setValues(number);
    debouncedSave({ min: number[0], max: number[1] });
    // saveInStore({ min: number[0], max: number[1] });
  };
  useEffect(() => {
    if (value) {
      setValues([value.min, value.max]);
      // set_minValue(value.min);
      // set_maxValue(value.max);
    }
  }, [value]);

  return (
    <div className="App">
      <p style={{ paddingLeft: "8px" }}>By Price</p>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <div style={{ width: "100%", margin: "0 10px" }}>
          <SliderMui
            classes={classes}
            // getAriaLabel={() => "Temperature range"}
            min={range.min}
            max={range.max}
            value={values}
            onChange={handleChange}
            valueLabelDisplay="on"
            // getAriaValueText={(value) => `${value}°C`}
            valueLabelFormat={(x) => {
              if(type==="car"){
                if (x <= 100) {
                  return `₹ ${x} L`;
                } else {
                  x = (x * 0.01).toFixed(2);
                  return `₹ ${x} Cr`;
                }
              } else if(type==="bike" || type==="scooter"){
                if (x <= 100) {
                  return `₹ ${x} K`;
                } else {
                  x = (x * 0.01).toFixed(2);
                  return `₹ ${x} L`;
                }
              }
            }}
          />
        </div>
      </div>
      {/* <MultiRangeSlider
        min={range.min}
        max={range.max}
        step={step}
        ruler={false}
        label={false}
        preventWheel={false}
        minValue={minValue}
        maxValue={maxValue}
        onInput={(e) => {
          handleInput(e);
        }}
        style={{ color: "green" }}
      /> */}
      <div className="sliderLabel">
      <div>{`₹ ` + setPriceRange(range.min)}</div>
        <div>{`₹ ` + setPriceRange(range.max)}</div>
      </div>
      {/* <p style={{ marginTop: "20px" }}>
        Price Range :{" "}
        {`${value.min}L - ${value.max}`}
      </p> */}
    </div>
  );
};

const Filter = ({ onClose, type }) => {
  HandleHideScroll();

  const filter = useSelector((store) => store.filter.filter[type || "car"]);
  const price = useSelector(
    (store) => store.filter.filter[type || "car"].price
  );
  const [localPriceOnMount] = useState(price);
  const [openModal, setIsOpenModal] = useState(false);

  // const filteredVehicle = useSelector(
  //   (state) => state.vehicle.brandFilter[type || "car"]
  // );
  const vehicaleArray = useSelector(
    (store) => store.filter.filter[type || "car"]?.brand
  );
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    bgcolor: "black",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
  };

  const { stepValue, value, range } = filter.price;
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { value, checked } = e.target;
    let brand = filter.brand.map((user) =>
      user.name === value ? { ...user, isChecked: checked } : user
    );
    dispatch(setBrandFilter({ data: { brand }, type: type }));
  };

  const checkIfSelctedFilterNotApplied = () => {
    const unAppliedFilter = filter.brand.filter(
      (item) =>
        (item.isChecked && !item.isFilterApplied) ||
        (!item.isChecked && item.isFilterApplied)
    );
    return unAppliedFilter?.length > 0;
  };

  const saveInStore = useCallback(
    ({ min, max }) => {
      dispatch(
        setPriceRange({
          data: { min, max, isFilterApplied: false },
          type: type,
        })
      );
    },
    [dispatch, type]
  );

  // console.log('render main');
  return (
    <>
      <div className="modelName">
        {/* <div className='modal-header'>
        <div>Find Vehicle</div>
        <div
          className='modal-close'
          onClick={() => {
            dispatch(
              clearFilter({
                brand: filter.brand
                  .filter(d => d.isChecked)
                  .map(d => d.name.toLowerCase()),
                value: {
                  min: range.min,
                  max: range.max,
                },
                type: type,
              })
            );
          }}
        >
          Reset
        </div>
      </div> */}
        <div className="modal-header">
          <div>Filter</div>
          <img
            src={close}
            alt="Close-btn"
            onClick={() => {
              if (checkIfSelctedFilterNotApplied()) {
                setIsOpenModal(true);
              } else if (localPriceOnMount?.value?.min !== price?.value?.min) {
                setIsOpenModal(true);
              } else {
                onClose();
              }
              // dispatch(
              //   clearFilter({
              //     brand: filter.brand
              //       .filter((d) => d.isChecked)
              //       .map((d) => d.name.toLowerCase()),
              //     value: {
              //       min: range.min,
              //       max: range.max,
              //     },
              //     type: type,
              //   })
              // );
              // dispatch();
              // applyFilter({
              //   brand: filter.brand
              //     .filter((d) => d.isChecked)
              //     .map((d) => d.name.toLowerCase()),
              //   price: {
              //     min: value.min * stepValue,
              //     max: value.max * stepValue,
              //   },
              //   type: type,
              // })

              // if (isResetClicked) {
              // }
              // if (checkIfSelctedFilterNotApplied()) {
              //   let brand = filter.brand.map((item) => {
              //     let arr = [];
              //     if (item.isFilterApplied && !item.isChecked) {
              //       arr.push({ ...item, isChecked: true });
              //     } else if (item.isChecked && !item.isFilterApplied) {
              //       arr.push({ ...item, isChecked: false });
              //     } else {
              //       arr.push({ ...item });
              //     }
              //     return arr;
              //   });
              //   brand = brand.flat(2);
              //   dispatch(setBrandFilter({ data: { brand }, type: type }));
              // }
              // if (!price?.isFilterApplied) {
              //   dispatch(
              //     setPriceRange({
              //       data: {
              //         min: localPriceOnMount?.value?.min,
              //         max: localPriceOnMount?.value?.max,
              //         isFilterApplied: true,
              //       },
              //       type: type,
              //     })
              //   );
              // }
              // onClose();
            }}
          />
        </div>
        <hr style={{ margin: "0px 20px" }} />
        <div>
          <div className="buy-brand">
            <p className="brand-text" style={{ padding: "20px 20px 0px 20px" }}>
              By Brand
            </p>

            <div className="checkbox-block">
              {vehicaleArray?.map((brand, index) => {
                return (
                  <div key={index + brand.name} className="checkbox-flex">
                    {/* <input
                    type='checkbox'
                    className='filter-checkbox'
                    value={brand.name}
                    checked={brand.isChecked}
                    onChange={handleChange}
                  /> */}
                    <Checkbox
                      sx={{
                        color: "#646464",
                        "&.Mui-checked": {
                          color: "#14AE5C",
                        },
                      }}
                      className="filter-checkbox"
                      value={brand.name}
                      checked={brand.isChecked}
                      onChange={handleChange}
                    />
                    <span className="checkBoxLabel">{brand.name} </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="filter_detail">
          <hr className="hrBorder" />
          <div>
            <Slider
              type={type}
              saveInStore={saveInStore}
              min={filter.price.min}
              max={filter.price.max}
            />
          </div>
          <hr className="hrBorder" />
          <div className="selection-btn-wrapper">
            <div className="selection-btn">
              <div className="vehicle-close-wrapper">
                {/* <button
              className='vehicle-reset-btn'
              onClick={() => {
                dispatch(
                  applyFilter({
                    brand: filter.brand
                      .filter(d => d.isChecked)
                      .map(d => d.name.toLowerCase()),
                    price: {
                      min: value.min * stepValue,
                      max: value.max * stepValue,
                    },
                    type: type,
                  })
                );
                onClose();
              }}
            >
              Cancel
            </button> */}
                <button
                  className="vehicle-reset-btn"
                  onClick={() => {
                    dispatch(
                      clearFilter({
                        brand: filter.brand
                          .filter((d) => d.isChecked)
                          .map((d) => d.name.toLowerCase()),
                        value: {
                          min: range.min,
                          max: range.max,
                        },
                        type: type,
                      })
                    );
                  }}
                >
                  Reset
                </button>
              </div>
              <div className="vehicle-apply-wrapper">
                <button
                  className="vehicle-apply-btn"
                  onClick={() => {
                    // console.log({ stepValue });
                    dispatch(
                      applyFilter({
                        brand: filter.brand
                          .filter((d) => d.isChecked)
                          .map((d) => d.name.toLowerCase()),
                        price: {
                          min: value.min * stepValue,
                          max: value.max * stepValue,
                        },
                        type: type,
                      })
                    );
                    let brand = filter.brand.map((user) =>
                      user.isChecked
                        ? { ...user, isFilterApplied: true }
                        : { ...user, isFilterApplied: false }
                    );
                    dispatch(setBrandFilter({ data: { brand }, type: type }));
                    dispatch(
                      setPriceRange({
                        data: {
                          min: price?.value?.min,
                          max: price?.value?.max,
                          isFilterApplied:
                            price?.value.min > 0 ||
                            (price?.value?.max !== 2000 &&
                              price?.value.max < 2000),
                        },
                        type: type,
                      })
                    );
                    onClose();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <Modal open={openModal} onClose={() => setIsOpenModal(false)}>
        <Box
          sx={{ width: "100%", border: "none", outline: "none", ...modalStyle }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to discard your changes?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You modified some filters. what would you like to do with these
            changes?
          </Typography>
          <Box sx={{ display: "flex", mt: 5 }}>
            <button
              className="vehicle-reset-btn"
              onClick={() => {
                //for vehicle
                let brand = filter.brand.map((item) => {
                  let arr = [];
                  if (item.isFilterApplied && !item.isChecked) {
                    arr.push({ ...item, isChecked: true });
                  } else if (item.isChecked && !item.isFilterApplied) {
                    arr.push({ ...item, isChecked: false });
                  } else {
                    arr.push({ ...item });
                  }
                  return arr;
                });
                brand = brand.flat(2);
                dispatch(setBrandFilter({ data: { brand }, type: type }));
                //for price
                dispatch(
                  setPriceRange({
                    data: {
                      min: localPriceOnMount?.value?.min,
                      max: localPriceOnMount?.value?.max,
                      isFilterApplied: true,
                    },
                    type: type,
                  })
                );
                onClose();
              }}
              style={{ padding: "13px 18px 13px 18px", marginRight: "10px" }}
            >
              Discard Changes
            </button>
            <button
              className="vehicle-apply-btn"
              onClick={() => {
                dispatch(
                  applyFilter({
                    brand: filter.brand
                      .filter((d) => d.isChecked)
                      .map((d) => d.name.toLowerCase()),
                    price: {
                      min: value.min * stepValue,
                      max: value.max * stepValue,
                    },
                    type: type,
                  })
                );
                let brand = filter.brand.map((user) =>
                  user.isChecked
                    ? { ...user, isFilterApplied: true }
                    : { ...user, isFilterApplied: false }
                );
                dispatch(setBrandFilter({ data: { brand }, type: type }));
                dispatch(
                  setPriceRange({
                    data: {
                      min: price?.value?.min,
                      max: price?.value?.max,
                      isFilterApplied:
                        price?.value.min > 0 ||
                        (price?.value?.max !== 2000 && price?.value.max < 2000),
                    },
                    type: type,
                  })
                );
                onClose();
              }}
              style={{ padding: "13px 18px 13px 18px" }}
            >
              Apply Changes
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Filter;
