import Style from "./otp-verification.module.scss";
import ResuableHeader from "../../Components/ResuableHeader/ResuableHeader";
import OTPInput from "otp-input-react";
import { useState, useEffect } from "react";
import backArrow from "../../Assets/Images/arrow-back.svg";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtp, loginmobile, sendOtp } from "../../slice/loginSlice";
import { useNavigate } from "react-router-dom";

import { setToken } from "../../slice/auth";
import axios from "axios";
import { updateUserDetails } from "../../slice/userDetailsSlice";
import { encrypt } from "../../service/encryption";
// import { browserHistory } from 'react-router';

const OtpVerification = ({ title = "" }) => {
  // window.gtag('event', 'OtpVerification', { method: 'Google' });
  // document.title = title;
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [OTP, setOTP] = useState("");
  const [remain, setRemain] = useState(false);

  const { mobileNo, votpStatus } = useSelector((state) => state.users);
  const loggedIn = useSelector((state) => state.auth.token);

  const handleInputChange = (event) => {
    setOTP(event);
  };

  useEffect(() => {
    if (OTP.length === 4) {
      dispatch(verifyOtp({ mobile: mobileNo, otp: OTP, id: "EV_" + mobileNo }));
    }
    return () => {};
  }, [OTP, dispatch, mobileNo]);

  // useEffect(() => {
  //   if (votpStatus.status) {
  //     if (votpStatus.status !== "success") {
  //       setError(votpStatus.message || votpStatus.status);
  //     } else {
  //       dispatch(setToken(votpStatus.data));
  //       // api call for
  //       if (
  //         votpStatus.data.userPreference &&
  //         votpStatus.data.userPreference.length > 2
  //       ) {
  //         navigate("/evinindia", { replace: true });
  //       } else {
  //         // navigate(to);
  //         // navigate(votpStatus.data.to, { replace: true });
  //       }
  //     }
  //   }
  //   return () => {
  //     // dispatch(resetLogin())
  //   };
  // }, [
  //   votpStatus.status,
  //   votpStatus.data,
  //   votpStatus.message,
  //   dispatch,
  //   navigate,
  // ]);
  useEffect(() => {
    if (votpStatus?.status === "failed") {
      setError(votpStatus?.message);
    }
    if (votpStatus?.status === "success") {
      setError("OTP verified successfully");
      dispatch(setToken(votpStatus.data));
    }
    if (votpStatus?.status === "loading") {
      setError("loading");
    }
  }, [dispatch, votpStatus]);
  useEffect(() => {
    if (loggedIn) {
      axios
        .put(process.env.REACT_APP_USER_BACKEND_API + "check-profile", "", {
          headers: { token: loggedIn },
        })
        .then((resp) => {
          let data = resp?.data;
          if (data?.success) {
            dispatch(updateUserDetails({ ...data.data }));
            navigate("/evinindia", { replace: true });
          }
        })
        .catch((error) => {
          alert("Something went wrong, Please sign in again");
          navigate("/signin", { replace: true });
          dispatch({ action: "USER_LOGOUT" });
        });
    }
  }, [dispatch, loggedIn, mobileNo, navigate]);

  const handleResend = () => {
    setRemain(false);
    // dispatch(sendOtp({ mobile: mobileno }));
    dispatch(sendOtp({ mobile: mobileNo }));
    // console.log('resend called');
  };

  return (
    <>
      {/* <ResuableHeader /> */}
      <section className={Style["terms-header"]}>
        <span
          className={Style["btn-close"]}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={backArrow} alt="close" />
        </span>
      </section>
      <main className={Style["otp-main"]}>
        <section className={Style["confirm-otp-main"]}>
          <p className={Style["confirm-text"]}>Confirm OTP</p>
          <p className={Style["otp-text"]}>
            OTP has been sent to you on your <br /> mobile number
          </p>
        </section>

        <section id="otp-verification_otp" className={Style["otp-box"]}>
          <OTPInput
            value={OTP}
            onChange={handleInputChange}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            secure={false}
            maxTime={true}
            className="otp_value"
            inputStyles={{
              width: "4em",
              height: "3.5em",
              border: "1px solid #14AE5C",
              borderRadius: 5,
              fontWeight: "bold",
              fontSize: 16,
              // color: '#e41e26',
              color: "white",
              backgroundColor: "#1b2028",
              outline: "none",
            }}
            inputClassName={"data"}
          />
          {error && <p className={Style["errorMessage"]}>{error}</p>}

          {!remain && (
            <Timer
              onStart={() => {}}
              onStop={() => {
                setRemain(true);
              }}
            />
          )}
          {remain && (
            <div className={Style["receive-text"]}>
              <span className={Style["did-received"]}>
                Didn't receive an OTP?
              </span>
              <span
                className={Style["resend-text"]}
                onClick={() => {
                  handleResend();
                }}
              >
                Resend
              </span>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default OtpVerification;

const Timer = ({ onStart, onStop }) => {
  const [seconds, setSeconds] = useState(30);
  let timer = null;
  useEffect(() => {
    if (seconds > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      onStop();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [seconds]);
  return (
    <div className={Style["otp-count"]}>
      Resend OTP in : <span style={{ color: "#14AE5C" }}>{seconds} </span>
    </div>
  );
};
