import Header from "../../Components/Header/Header";
import DashBoard from "../../Pages/DashBoard/dash-board";
import InsightSection from "../InsightSection/insight-section";
import TotalEV from "../../Pages/TotalEV/TotalEV";
import HdfcergoLogo from "../../Assets/Images/hdfcergo-logo.png";
import LaunchingSoon from "../../Pages/InsightSection/launching-soon";
const HomePage = ({ title = "" }) => {
  document.title = title;

  return (
    <>
      <Header />
      <TotalEV />
      <DashBoard />
      <InsightSection />
      <LaunchingSoon />
      {/* <section className='powered-by'>
        <span className='powered-by-text'>Powered by </span>
        <img className='hdfcergo-logo' src={HdfcergoLogo} alt='' />{' '}
      </section> */}
       <footer>
        <div className="droom-footer-tab">
          <div className="droom-text">
            <p>Powered by</p>
          </div>
          <div className="droom-image">
            <img className="userIcon" src={HdfcergoLogo} alt="Droom-Logo" />
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomePage;
