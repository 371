import Blog01 from '../Assets/Images/BLog_01.png';
import Blog02 from '../Assets/Images/blog_2.png';
import Blog03 from '../Assets/Images/Blog3.png';
import Blog04 from '../Assets/Images/Blog4.png';
import Blog05 from '../Assets/Images/Blog5.png';
import Blog06 from '../Assets/Images/Blog6.png';
import Blog07 from '../Assets/Images/Blog7.png';
import Blog08 from '../Assets/Images/Blog8.png';
import Blog09 from '../Assets/Images/Blog9.png';
import Blog10 from '../Assets/Images/Blog10.png';
import Blog11 from '../Assets/Images/Blog11.png';
import Blog12 from '../Assets/Images/Blog12.png';
import Blog13 from '../Assets/Images/Blog13.png';
import Blog14 from '../Assets/Images/Blog14.png';
import Blog15 from '../Assets/Images/Blog15.png';
import Blog16 from '../Assets/Images/Blog16.png';

export const blogContent = [
  // Blog 1
  {
    blogId: 1,
    answer: (
      <>
        <p>
          <strong>Top 5 Electric Vehicle Stocks to Buy Right Now</strong>
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/F2dEMNRshskPJn.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          I'm sure you've asked yourself: "What stocks would be the best in the
          Indian electric vehicle market?" In which Indian stock company could
          investors be interested? And even more! This blog will provide you
          with all the answers.
        </p>
        <p>
          <strong>
            What's Going On With The Electric Vehicle Industry In India?
          </strong>
        </p>
        <p>
          People have recently begun to recognize the need for electric vehicles
          in India, a country with alarming emission levels. The sharp increase
          in public and government participation in sustainable mobility signals
          the start of India's green revolution. Higher levels of electric
          vehicle (EV) stock in India and Indian EV component stock for battery
          manufacturers would result from this fresh capital inflow.
        </p>
        <p>
          By 2023, all three-wheelers will be battery-powered, and the majority
          of two-wheelers will follow suit by 2025. With the Indian government's
          heightened focus on green mobility, it's realistic to expect the EV
          market to grow and expand India&rsquo;s electric car fleet. With such
          widespread acceptance and development in India's EV market, the year
          2021 can be considered the optimal period to invest in electric
          vehicle stocks in India.&nbsp;
        </p>
        <p>
          <strong>
            Indian Electric Vehicle Stocks Will Grow By Leaps And Bounds In The
            Coming Decade.
          </strong>
        </p>
        <p>
          India has many strong electric vehicles and battery firms that stand
          out for market leadership, The top five electric vehicle stocks in
          India include:
        </p>
        <p>
          <strong>1. Mahindra &amp; Mahindra Limited</strong>
        </p>
        <p>
          By launching Mahindra Reva in India in early 2000, the Mahindra group
          set the wheels in motion for its electric revolution. With the EV
          manufacturing plant in Bangalore, it has moved beyond the needs of
          both consumers and businesses and is now into many other market
          segments. The future expansion into the electric vehicle market,
          especially concerning battery development, is expected to be managed
          by Mahindra &amp; Mahindra Limited.&nbsp;
        </p>
        <p>
          Mahindra has introduced two new compact urban electric car models: the
          Mahindra e2o and the Mahindra e2o Plus. Stocks in Mahindra &amp;
          Mahindra Limited can represent an excellent investment opportunity
          right now.&nbsp;
        </p>
        <p>Market cap: 93,967.00</p>
        <p>Dividend yield: 0.31%</p>
        <p>PE Ratio: -165.90</p>
        <p>Share Price: 794.60&nbsp;</p>
        <p>
          <strong>2. Tata Motors Ltd.&nbsp;</strong>
        </p>
        <p>
          Tata Motors had a hand in revolutionizing the Indian electric car
          market with the launch of the Nexon EV. Tata Motors introduced an
          innovative feature in their new electrified vehicles: ZAPTRON, which
          generates power through kinetic braking and recharges the battery
          while on the move.
        </p>
        <p>
          Tata Motors currently sells three hybrid vehicles: the Tigor EV, Nano
          EV, and Tiago electric. Projected to dominate the EV market as it is
          growing its R&amp;D globally and in India. Besides automobiles, Tata
          Motors manufactures some of the nation's most numerous heavy-duty
          electric buses, dump trucks, and military vehicles in India.
        </p>
        <p>The share price of Tata Motors Limited is as follows:</p>
        <p>Market cap : 109,885</p>
        <p>Dividend yield : 0.00%</p>
        <p>PE Ratio : -7.00</p>
        <p>Share Price : 331.95</p>
        <p>
          <strong>3. Maruti Suzuki Ltd.</strong>
        </p>
        <p>
          Maruti Suzuki is a market leader in India's auto sector, with a wide
          reach and strong levels of emotional attachment. It has the highest
          level of trust among Indian customers, and its EV-specific stocks
          expected to rise once it begins production.
        </p>
        <p>
          By mid-2021, the company plans to introduce an electric version of the
          famous Maruti Suzuki wagon R. By that time, it is prudent to keep a
          close eye on Maruti Suzuki's share price.
        </p>
        <p>The share price of Maruti Suzuki India Ltd is as follows:</p>
        <p>Market cap : 2,05,475</p>
        <p>Dividend yield : 0.00%</p>
        <p>PE Ratio :&nbsp;46.82</p>
        <p>Share Price :&nbsp; 6,786.00</p>
        <p>
          <strong>4. Ashok Leyland Ltd.</strong>
        </p>
        <p>
          Ashok Leyland is India's leading manufacturer of electric buses,
          trucks, and security equipment. It has developed the first electric
          bus with flash-charge technology.
        </p>
        <p>
          It permits vehicles to fit on Indian highways, making it ideal for
          heavy-duty electric vehicles. Circuit, Circuit S, and HYBUS are just a
          few of Ashok Leyland's iconic launches. Ashok Leyland will disrupt the
          heavy electric vehicle segment in India with ABB TOSA technology.
        </p>
        <p>Price of Ashok Leyland Ltd's stock:</p>
        <p>Market cap : 35,593.27</p>
        <p>Dividend yield : -71.64</p>
        <p>PE Ratio :&nbsp;0.41%</p>
        <p>Share Price :&nbsp;121.30</p>
        <p>
          <strong>5. Amara Raja Batteries Ltd</strong>
        </p>
        <p>
          Battery manufacturing companies can be considered the top stock picks
          for electric vehicle battery manufacturers stocks in India because
          batteries are the most important part of electric vehicles. Batteries
          must be replaced at regular intervals, implying that EV battery sales
          will remain consistent.&nbsp;
        </p>
        <p>
          Amara Raja Batteries, India's second-largest battery producer, is
          planning to open a Lithium-ion battery manufacturing plant soon. In
          partnership with the Delhi government, the company plans to construct
          many charging stations. Amara Raja Batteries will be a big player in
          India's electric vehicle stocks, making it an ideal stock to keep an
          eye on.
        </p>
        <p>Price of Amara Raja Batteries Ltd:</p>
        <p>Marketing Cap:&nbsp;13,401.95</p>
        <p>Dividend yield : 1.40%</p>
        <p>PE Ratio : 22.55</p>
        <p>Share Price : 784.20</p>
        <p>
          Now that you know the list of the top electric vehicle (EV) stocks in
          India with prominent car manufacturers in the EV segment, their
          current assignments, and anticipated developments. You shouldn't wait
          to invest in it! This will cause electrification in the auto industry
          to recede for the foreseeable future.
        </p>
      </>
    ),
    blogImg: Blog01,
    heading: 'Top 5 EV Stocks to Buy Right Now',
    headingDesc: "I'm Sure you asked Yourself...",
  },

  // Blog 2
  {
    blogId: 2,
    answer: (
      <>
        <p>
          <strong>&nbsp;&nbsp;Myths about Electric Vehicles in India</strong>
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/663NSZXYPwNRqf.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          <em>
            <strong>
              &nbsp;&ldquo;Three ingredients of a luxury lifestyle design are
              time ,income and mobility&rdquo; - Tim Ferris.
            </strong>
          </em>
        </p>
        <p>
          Mobility is an industry which has seen a lot of breakthrough
          interventions since the day wheels were invented. Electric vehicles
          are one such disruptive discovery in the mobility industry. Global
          electric vehicle sales are accelerating in a tremendous phase in
          recent years . Advancements in battery technologies , implementation
          of charging stations in public places , initiative by the government
          and multinational companies play a vital role in the growth of
          electric vehicle adoption.
        </p>
        <p>
          Although there are certain myths regarding electric vehicles
          pertaining in our country which hinder people from making a choice.
          Let us clear out some of the most common myths prevailing in the
          upcoming paragraphs.
        </p>
        <p>
          <strong>1. Electric vehicles are meant only for short trips:</strong>
        </p>
        <p>
          People consider electric vehicles as a short commute option and are
          confused whether it can fulfil their daily commute needs. Range
          anxiety is one of the predominant hindrances in the selection of
          electric vehicles over conventional vehicles. According to a recent
          study the average commute distance of an Indian is around 15-20 kms
          one way . Electric two wheeler brands in India offer an average range
          of around 60-70 kms on a single charge. Electric four wheelers on the
          other hand have a range of around 200 kms on a single charge. This
          clearly shows that electric vehicles can be more than that of a short
          commute option. They are evolving as a best fit solution for inter
          city and inter state commute.
        </p>
        <p>
          <strong>2. Limited charging options:</strong>
        </p>
        <p>
          People tend to have a mindset that once they leave their home with
          their electric vehicles they are left with limited options to charge
          them. The practical scenarios state a different story. There are
          thousands of public charging stations , fleets widespread around the
          country. Parking lots of shopping malls, workspaces , and restaurants
          have charging stations.
        </p>
        <p>
          The Government of India is partnering with various private companies ,
          emerging startups to improve the charging infrastructure. The Ministry
          of power has prepared guidelines according to which there will be at
          least one charging station for every 25 km in highways. The Ministry
          of Heavy Industries along with the Ministry of Petroleum and power
          aims to install 22,000 charging stations in petrol pumps in various
          parts of the country. This clearly proves that the hurdles in charging
          electric vehicles will diminish down the lane.
        </p>
        <p>
          <strong>3. Need for frequent replacement of batteries:</strong>
        </p>
        <p>
          Many people believe that if they buy an electric vehicle they have to
          spend a hefty sum on replacing the batteries frequently. Battery
          manufacturers have been doing a lot of research on improving the life
          of batteries and have consistently improved in the same. Most of the
          prominent electric vehicle manufacturers provide an average warranty
          of about 4-5 years for their batteries and it is a fact the mentioned
          range extends when the vehicle is used better.
        </p>
        <p>
          Moreover certain manufacturers like Ather provide a buy back guarantee
          for their batteries wherein you could get an assured buy back for the
          used batteries. Apart from this there are battery recycling firms who
          can provide you a fair deal for your used batteries.
        </p>
        <p>
          <strong>4. Hard to use and maintain:</strong>
        </p>
        <p>
          As people have been using IC engine vehicles over the years , they
          have a belief that it is difficult to use and maintain electric
          vehicles. On the contrary, features and the performance of electric
          vehicles gives a next level user experience. Current electric vehicles
          are built with exceptional features which attributes to the overall
          user experience. Users are allowed to understand their ride patterns
          and what could be done to increase the drivetrain efficiency.
          Manufacturers also get to have a better understanding of driving
          patterns and design the future versions accordingly.
        </p>
        <p>
          <strong>5. Finite choices:</strong>
        </p>
        <p>
          People believe that there are only quite a few choices of electric
          vehicles to choose from. Right from early age startups to
          multinational companies have been working in the electric vehicle
          ecosystem for a while and have provided us with plenty of options to
          choose from. The user can choose his electric vehicle according to
          speed , acceleration , range , price , aesthetics etc. Owning an
          electric superbike will turn into reality in the upcoming years. Urban
          cities like Bengaluru have an option wherein people are allowed to use
          electric vehicles on a rental or subscription basis so that they can
          get comfortable with it.
        </p>
        <p>
          <em>
            <strong>&ldquo;Progress is impossible without change&rdquo;</strong>
          </em>
        </p>
        <p>
          Every sector is subjected to change at regular intervals, and certain
          myths act as hurdles in the path of change. But by referring to the
          electric vehicle sales in India over the past few years we can clearly
          see that the industry has progressed significantly and will continue
          to thrive in the upcoming years.&nbsp;
        </p>
      </>
    ),
    blogImg: Blog02,
    heading: 'Myth about EV in India',
    headingDesc: 'What stock would be the...',
  },
  // Blog 3
  {
    blogId: 3,
    answer: (
      <>
        <p>
          <strong>Which Is Better: Electric Bikes or Electric Scooters?</strong>
        </p>
        <p>
          Comparing the electric bike to the electric scooter, is it reasonable?
          The variety of models of one or the other and the diversity of
          practices could make this question incongruous. And yet, when
          considering leaving your car in the garage or avoiding public
          transport, both solutions must be considered. The electric bike or
          scooter can not only replace conventional means of transport, but they
          also have advantages. They allow you to choose between saving time,
          avoiding crowds, resuming physical activity, or all at the same time.
          Therefore, the question of the choice of one or the other arises, and
          the arguments to decide between them are numerous. A broader
          consideration could include speed bikes or larger engines, but it
          should start with the basics: scooter or bikes?
        </p>
        <h2>Which is the fastest?</h2>
        <p>
          Leaving your car in the garage in favor of a bicycle or a scooter can
          only be done on one condition: a guarantee of speed allowing you to
          envisage a journey between home and work at an acceptable time. On the
          strict criterion of the speed of movement, it is enough to refer to
          the law to decide between our two suitors. A scooter (50 cc
          equivalent) is limited to 45 km/h. As for the electrically assisted
          bikes, its motor stops at 25 km/h, forcing the cyclist to pedal if he
          wants to exceed this limit.
        </p>
        <p>
          Result: the electric scooter wins this round but it is a relative
          victory. Indeed if its theoretical speed is 45 km/h, the opportunities
          to reach this peak are very variable according to the course. In urban
          areas, with high traffic density, this high speed is utopian.
        </p>
        <h2>What is the most convenient to ride?</h2>
        <p>
          Speed ​​isn't everything. In an urban environment, maneuverability,
          acceleration, and generally the ability to quickly get out of a
          crossroads are important criteria for evaluating which of the two
          machines is the most comfortable in town. In reality the advantage of
          one or the other will depend on the route you will take. The scooter
          will most often have the advantage of acceleration and top speed
          provided the ride allows the electric scooter to express itself fully.
          Three to four times lighter, the electric bikes are in essence more
          manageable and allow more to sneak between cars. If it cannot compete
          in terms of pure speed, it has the advantage of being able to
          circulate on cycle paths and bus lanes, which generally allows it to
          close the gap with its competitor. If these are not numerous enough,
          their number tends to increase as the authorities become aware of the
          importance of setting up a good infrastructure for cyclists.
          Consequently, even if in theory, the electric scooter can go almost
          two sees faster than the bicycle this difference does not occur in
          urban areas. On the contrary, on a journey of about 10 km, the
          difference generally observed is about 5 minutes. On the other hand,
          the scooter is much more comfortable to use. On the one hand, it
          offers a larger and more pleasant surface to install than a slightly
          rough saddle, but also because its wider wheels absorb more shocks.
          Finally, it is almost always equipped with a damping system, which is
          not necessarily the case on bicycles, especially those that favor
          speed.
        </p>
        <p>
          Result: It is possible to prefer the scooter on certain very rolling
          journeys or when traffic is less important, but by its versatility and
          its ability to use reserved lanes, the bicycle has an undeniable
          advantage in town. What could tip the balance one way or the other is
          not only the type of journey but also its duration.
        </p>
        <h2>Travel time: the adjustment variable</h2>
        <p>
          Now that the qualities on the road of the two contenders are known, it
          is worth asking whether the type of course and its length has an
          impact on the choice of one or the other. It would indeed seem that
          the number of kilometers to be covered is as important as the
          conditions in which the journey will be made.
        </p>
        <p>
          Indeed, the advantages of the scooter (top speed and acceleration)
          prove to be more and more useful as the distance increases. On very
          short urban journeys, the electric scooter will have much more
          difficulty in asserting its speed and will be just as penalized by its
          weight and its traffic or parking restrictions. Conversely, the
          shorter the trip, the more valuable time the electric bike will save.
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For a journey
          of less than 5 km: On this kind of short journey, especially in urban
          areas, an electric bike will always be faster than the volute scooter.
          Above all, once you arrive at your destination, you won't waste time
          finding a parking space.
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For a journey
          between 5 and 15 km: It is on this type of journey that the question
          of choice is the most relevant. In this case, everything will depend
          on the profile of the trip. The more fast or rolling portions it
          includes, the more the scooter will use. Conversely, the more bus
          lanes and cycle paths there are on your route, the more the gap will
          narrow in favor of the electric bike.
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For a journey
          longer than 15 km: On the longest journeys, electric scooters have
          more opportunities to assert their qualities. Nevertheless, the eBike
          should not be excluded from the discussion. It can correspond to more
          athletic user profiles, for example, or simply compensate for a lack
          of public transport interconnections. Finally, as an example, when we
          test bikes, our typical route in daily use is 20 km, which we cover in
          about 55 minutes.
        </p>
        <p>
          Result: impossible to determine a clear winner on this point. The main
          adjustment variable for this comparison must be determined on a
          case-by-case basis, depending on the type of journey and the profile
          of each user. Just keep in mind that the longer the trip, the more
          points the electric scooter scores and vice versa.
        </p>
        <h3>What is the most convenient for parking?</h3>
        <p>
          Not everyone has a garage at home or a bike room at work, so the
          problem of parking your car must be considered from the moment of
          purchase. In this regard, the electric bike has an undeniable
          advantage since it can be parked almost anywhere as long as it does
          not interfere with traffic or street furniture. On the contrary, the
          electric scooter must be parked in specific places. Failure to comply
          with this obligation exposes its owner to a fine.
        </p>
        <p>Result: the bike easily wins the parking game.</p>
        <h2>Autonomy: not just a question of batteries</h2>
        <p>
          As with all types of electric vehicles, the advertised range is
          theoretical. It depends on factors as diverse as the rider's weight,
          the type of route, the outside temperature, or the tire pressure.
        </p>
        <p>
          However, it is possible to define an average which for a mid-range
          electric scooter would be around 65 km. Some scooter models also have
          a second slot for an additional battery, thus considerably increasing
          their autonomy. On an electrically assisted bicycle at an equivalent
          price, the autonomy is much higher and varies between 80 and 100 km
          depending on the type of battery and its integration. Obviously, in
          both cases, it is possible to extend this average value by adapting
          your driving or by opting for a lighter assistance mode (the famous
          &ldquo;eco&rdquo; modes). Finally, in case of battery failure, there
          is no other way than to push your scooter with the strength of your
          arms. On this point, the bike will always have an advantage. On the
          one hand because at any time its user can choose to save its battery
          by using its legs, and on the other hand because, in the event of a
          breakdown, it is always possible to pedal until the house.
        </p>
        <p>
          Result: the autonomy match switches quite logically in favor of the
          electric bikes which not only have greater reserves but is also an
          alternative in the event of a breakdown.
        </p>
        <h2>Verdict:</h2>
        <p>
          In the end, the choice between the bike or the electric scooter will
          depend mainly on your needs. Depending on your budget, the parking
          conditions but above all the type of route you will have to take, the
          first could well be more advantageous than the second and vice versa.
          The electric bikes will always be more versatile, less restrictive to
          handle and park but also slower and less comfortable than a scooter.
          This difference in top speed mainly has an impact on journeys longer
          than 15km, but for a more classic route, the two means of transport
          have arguments to make. Ultimately, if the two solutions allow you to
          leave your car in the garage and avoid public transport, the electric
          bike retains, in our opinion, an incomparable advantage: that of
          health. Indeed, by encouraging physical exercise and letting its pilot
          precisely adjust the level of assistance, the electric bike renders a
          much more valuable service to its owner than a few minutes wasted on a
          straight line.&nbsp;
        </p>
      </>
    ),
    blogImg: Blog03,
    heading: 'Which is better Electric Bikes',
    headingDesc: "I'm Sure ou asked Yourself...",
  },
  // Blog 4
  {
    blogId: 4,
    answer: (
      <>
        <p>
          <strong>
            If All Gas Stations Are Replaced By Electric Stations, Will Electric
            Cars Be Reliable?
          </strong>
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/6rD3QVWv3HdGhA.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          Although electric vehicles are a relatively new phenomenon for many of
          us, the majority of us know someone who owns one, whether it's a
          Nissan Leaf, BMW I series, or Tesla.
        </p>
        <p>
          Batteries and charging have been on the periphery of discussions about
          EVs for several years now, and two of the biggest challenges we
          continue to hear about are batteries and charging. Both of these
          issues contribute to one of the top four adoption barriers: range
          anxiety.
        </p>
        <p>
          Tesla, like many, has pushed the boundaries of both battery technology
          and vehicle design in recent years, achieving a range comparable to
          many kinship diesel cars on the market today.
        </p>
        <h2>Refueling: Electric Vehicles vs Internal Combustion Vehicles</h2>
        <p>
          The internal combustion engine has existed long enough for every
          driver (and would-be driver) to comprehend how these vehicles require
          fuel.
        </p>
        <p>
          The majority of people will fill their tanks to the brim and beyond
          the orange warning light before venturing to refuel. Of course, there
          are additional reasons to keep your tank topped off more frequently
          than that, but the majority of us would not consider adding a liter to
          the tank now simply because we can.
        </p>
        <p>
          However, many EV owners must consider this, particularly if they own a
          vehicle with a shorter range or slower charging capabilities.
        </p>
        <h2>Do petrol stations still have a place in an electric future?</h2>
        <p>
          There is a lot of discussion about democratizing charging and how
          blockchain technology can be used to facilitate energy sharing and the
          creation of networks of residential and commercial property charging
          points.
        </p>
        <p>
          When we visit the filling station today, it takes no more than a
          minute or two to fill the tank and a few additional minutes to
          complete the transaction. Charging batteries takes significantly
          longer, and even if we assume we will not stay for a full charge
          (which could take hours), the time required to top-up the car will be
          significantly longer than it is today.
        </p>
        <p>
          With this in mind, we'll see an increase in charging points in areas
          where people naturally spend more time, such as shipping centers and
          restaurants, as well as an evolution of filling stations to encourage
          longer dwell times.
        </p>
        <p>
          Filling stations will evolve into destinations in their own right, as
          we are already seeing. Numerous forecourts are already filling the
          void left by neighborhood convenience stores, and many are
          redeveloping their space to include coffee shops, restaurants, and
          even areas for people to open their laptops and settle in for a little
          work.
        </p>
        <p>
          As filling stations' fuel mix evolves to include more alternative
          fuels (such as electric and hydrogen), they will need to adapt their
          offerings to meet new customer needs while also attracting new types
          of customers.
        </p>
        <h2>With a shift in gas stations, Will EV become more convenient?</h2>
        <p>&nbsp;</p>
        <p>
          As the cost of electric vehicles has decreased, more consumers have
          purchased them than ever before. And as battery technology advances,
          EVs will become more appealing to drivers concerned about their daily
          commute being limited to 100 or 200 miles. As the number of EVs
          increases, we will also require a means of powering those EVs.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/7c3xuX5k6SXofW.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          Today, the majority of our electricity is generated by coal-fired
          power plants, which pollute just as much as the gasoline we are
          attempting to phase out. If we are able to phase out our gas-guzzlers
          in the coming years, we will undoubtedly be moving toward a more
          environmentally friendly world powered by clean, renewable energy.
        </p>
        <h2>The Bottom Line</h2>
        <p>
          For an increasing number of people, electric vehicles have already
          supplanted gas-guzzlers, and as technology advances, they will gain
          popularity. Until battery technology improves, the majority of us will
          continue to make trips to the pump, but with advancements in
          technology, our gas guzzlers may become obsolete.
        </p>
      </>
    ),
    blogImg: Blog04,
    heading: 'If All Gas Stations Are Replaced By',
    headingDesc: 'Although electric vehicles are...',
  },
  // Blog 5
  {
    blogId: 5,
    answer: (
      <>
        <p>
          <strong>All about "OTUA Electric" &amp; "Booz" on Shark Tank</strong>
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/AyKMcRWiYqP1aP.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          After the American reality show Shark Tank, Shark Tank India started
          for the entrepreneurs of India. The first Indian version of the show
          aired on Sony Entertainment TV on December 20, 2021. On Monday, Sony
          Entertainment Television started broadcasting the first program of 30
          episodes. According to Sony TV, the show has 62,000 contestants who
          participated from all over India, 198 of whom were selected to send
          ideas to sharks. Shark Tank India judges are Ashneer Grover, MD, and
          Co-Founder of BharatPe; Anupam Mittal, Founder &amp; CEO of People
          Group; Aman Gupta, Co-Founder &amp; CMO of boAt; Vineeta Singh, CEO
          &amp; Co-Founder of SUGAR Cosmetics; Namita Thapar, Executive Director
          at Emcure Pharma; Ghazal Alagh, Co-Founder &amp; CIO of Mamaearth; and
          Peyush Bansal, Founder &amp; CEO of Lenskart.com.
        </p>
        <h2>About startup EV OTUA in Shark Tank India</h2>
        <p>
          Dendera Technologies Private Limited the maker of OTUA electric, a
          three-wheel electric vehicle, presented their startup idea in Episode
          17 of Season 1 of Shark Tank India. It's a well-designed three-wheeled
          heavy vehicle.&nbsp;Dandera Technologies Private Limited is a startup
          founded in 2020 in Gurgaon, Haryana. Name OTUA has been taken from the
          word AUTO. This product designs mobility with the latest eco-friendly
          technology that could change the future. Dandera's company goal is to
          "Reinvent Mobility with sustainable cutting-edge technology". This
          vehicle uses a DanTECH battery which is in sync with India's climatic
          conditions.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/5zKqc6thvQj637.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          Founders of OTUA electric are engineers, Kshitij Bajaj,&nbsp;and Kanav
          Manchanda. The startup company was founded in 2007 but was not
          recognized until 2020. Its electro head is Ayush Bajaj and he has 30
          years of design and implementation experience.&nbsp;This OTUA product
          has been available since 2020, but it is not yet available in the
          market to buy. The founder claims that this product will be available
          to users by the end of 2022.
        </p>
        <p>
          The OTUA founder was demanding Rs 1 crore in exchange for a 1% equity
          from Sharks. Shark Asneel Grover offered Rs 1 Lakhs for 1% Equity
          &amp; Rs 99 Lakh Debt @ 12% Interest which is accepted by the founders
          of OTUA.
        </p>
        <h3>About OTUA</h3>
        <p>
          It has a closed Uni-Body, lighter and stronger body. It is equipped
          with a DanTECH patented Battery with a max capacity of 900 Kgs. It has
          over 120 Cubic Feet Of Cabin Volume and over 150 KMS range in a single
          charge. It is designed with a 1+6 Seating Capacity with a large
          legroom space. It has an independent suspension system for a smoother
          ride. It is also equipped with a 2 side open deck.
        </p>
        <h2>About startup Booz in Shark Tank India</h2>
        <p>
          Booz is an electric scooter based on an app featured in the first
          episode of Shark Tank India. If the demand for e-scooter in the Asian
          market rises sharply, it could be an innovative feature of the
          start-up industry. By joining Booz Shark Tank India, he has brought in
          a lot of money. Booz is the first app-based electric scooter in South
          Asia and, according to its founders, this scooter is available at an
          affordable price. With a unique idea, Booz rents electric scooters
          everywhere, including business parks, residential areas, educational
          parks, tourist spots, public places, hotels, clubs, and resorts. Booze
          provides its users a monthly subscription that lets you use everything
          from chargers to plugs. It offers Booze Pay Per Way rides across the
          country.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/3zgvo3zxYABh69.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          Rutvij Dasadia is the founder of Booz and a SAEINDIA member. He is an
          engineer from the background of Gujarat Technological University. He
          also won the Top 500 CEO award. He studied the fundamentals of machine
          learning at the University of London. He has worked for many
          motorcycle companies such as Menza Motors Pvt. Limited, Volvo Cars and
          the Next Feature transport. In 2017, he founded Booze Scooters in
          Ahmedabad.
        </p>
        <p>
          The Booz founder was demanding Rs 40 lakhs in exchange for a 15%
          equity from Sharks. Shark Asneel Grover and Vineeta Singh offered Rs
          40 Lakhs for 50% Equity which was accepted by the founder of Booz.
        </p>
        <h3>About Booz</h3>
        <p>
          This company provides electric scooters to end users along with
          charging stations, spare parts, personnel training, and a mobile
          application. When you rent a scooter, you also get a charger and a
          plug to charge the scooter. To use this feature, you need to register
          with the official app service. You can download this app from the App
          Store and Play Store. You will also get its franchise by signing a few
          documents and a commitment to start the business within 30 days.
        </p>
        <h2>Wrapping Up</h2>
        <p>
          In the Indian EV market, many startups are entering this field. The
          country will become the fourth largest electric vehicle market in the
          next 20 years, with startups taking advantage. Some major EV startups
          in the EV industry are Ola Electric Scooter, Okinawa, Bounce, BluSmart
          Mobility, Exponent Energy, and so on. With the emerging EV market and
          new electric vehicles, electric vehicle infrastructure is also growing
          rapidly. EVCS infrastructure giants like Kazam EV, TATA Power are
          entering this field to provide EV solutions to the users of India.
          They are installing chargers to all major spots across the country to
          make EVCS accessible to all with just a single click. They also help
          micro-business, shop owners, restaurants, malls, and builders to
          attract new customers by providing charging solutions to them. We hope
          that new EV startups and EVCS providers will definitely bring a
          drastic change in the Indian EV market.
        </p>
      </>
    ),
    blogImg: Blog05,
    heading: `All about "OTUA Electric" & "Booz" on Shark Tank`,
    headingDesc: 'After the American reality show Shark Tank...',
  },
  // Blog 6
  {
    blogId: 6,
    answer: (
      <>
        <p>
          <strong>AC Charger vs DC Charger</strong>
        </p>
        <img src={Blog06} alt='' />
        <p>
          &nbsp;When you understand the terminology related to charging electric
          vehicles, you will probably come across the abbreviations AC or DC,
          and the more common terms "fast" and "slow" charging are also often
          used. Let's see what it is and how they differ.
        </p>
        <p>Let's start with a school physics course:</p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AC (Alternate
          Current) - Alternating Current,
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DC (Direct
          Current) - Direct Current
        </p>
        <p>
          The household and industrial outlets you encounter on a daily basis
          provide alternating current. You meet a constant in everyday life when
          it comes to personal household appliances, in which there are AAA
          batteries and accumulators and other common ones.
        </p>
        <p>
          An EV battery is made up of many interconnected cells, often
          reminiscent of home appliance batteries, that are charged with DC only
          and therefore feedback DC. When charging, a rectifier is used that
          converts alternating current to direct current. All-electric vehicles
          have such a device "on board".
        </p>
        <p>
          But in view of the dimensions of these converters (devices grow in
          size and weight with power), trying to fit a massive device on the
          chassis of an electric car that can pass and convert enough power to
          charge in an hour, or even more so in 10-20 minutes, does not make any
          sense. Therefore, rectifiers installed directly in an electric vehicle
          or "on-board chargers" have a limited capacity, while powerful and
          massive converters remain "overboard".
        </p>
        <p>
          <strong>Slow AC Chargers</strong>
        </p>
        <p>
          AC or slow charging stations are just "smart" AC switches that feed
          the available AC (without affecting or converting it in any way) via
          cable into an electric car to an onboard charger. They control the
          charging process via special signal wires and implement the following
          important functions:
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coordination
          of the charging process with an electric vehicle, only after which the
          current is turned on, and at the end of charging it is turned off;
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Power control
          - indicates the maximum allowable current to the electric vehicle and
          controls it so that the electric vehicle does not overload the network
          and does not "cut down" the machines;
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Safety - is
          responsible for the emergency shutdown of current, in case of
          detection of its leakage on the charging cable or station body, as
          well as in the electric vehicle itself. Tracking the quality and
          performance of grounding.
        </p>
        <p>
          Advanced AC charging stations often have additional features: kilowatt
          counters, delay timers, intelligent load management functions (dynamic
          balancing), connection to cloud management and monitoring systems, and
          many others.
        </p>
        <p>
          <strong>Fast DC Charges</strong>
        </p>
        <p>
          Powerful AC converters (40-200 kW and even more) remain standing on
          the ground and are connected directly to the battery through special
          connectors that contain power and signal cables. What is the DC
          station responsible for?
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coordinates
          the charging process with the electric car and only after coordination
          turns on the current, turns it off at the end;
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Limits and
          regulates the current on commands from the BMS (Battery Management
          System - battery management system) in order to carry out the charging
          process without overloads and overheating;
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monitors the
          safety of the charging process - turns off the current in the event of
          current leaks on the charging cable or station body, in the electric
          vehicle itself, or grounding problems occur;
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monitor the
          temperature of the connectors to avoid overheating.
        </p>
        <p>
          All DC charging stations have meters, balancing functions, and connect
          to cloud management, monitoring, and settlement systems using the
          worldwide OCPP ( Open Charge Point Protocol).
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mode 2 is
          charging through a small portable AC station;
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mode 3 -
          charging with a more powerful current through a stationary one;
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mode 4 are
          already DC charging stations.
        </p>
        <p>
          <strong>Wrapping Up</strong>
        </p>
        <p>
          The current DC charging standard for electric vehicles is designed for
          batteries with an operating voltage of 450 V. This is the current
          vision of the situation by electric vehicle designers. But studies are
          already underway showing that to increase the efficiency and driving
          performance of electric vehicles, it will be necessary to increase the
          battery voltage, up to 900 V. It is also expected that batteries in
          electric vehicles will be replaced by supercapacitors soon. Both
          events will require rework or simply replacement of DC charging
          station equipment. At the same time, AC charging stations will be able
          to service both 900V battery-powered cars and supercapacitor electric
          vehicles without any problems. Therefore, the development of networks
          of charging stations on the alternating current will be of interest to
          investors for a long time to come. Such stations are not only
          inexpensive but also protect the investment, as they are compatible
          with the electric vehicles of the future.
        </p>
      </>
    ),
    blogImg: Blog06,
    heading: 'AC Charger vs DC Charger',
    headingDesc: 'When you understand the terminology...',
  },
  // Blog 7
  {
    blogId: 7,
    answer: (
      <>
        <p>
          <strong>Sales of EV in Jan 2022 India</strong>
        </p>
        <img src={Blog07} alt='' />
        <p>
          The industry has been leading EV sales across the country for a long
          time as the industry experienced good growth in January 2022, while EV
          sales generally declined this month. The total number of electric
          vehicle registrations in January 2022 decreased by 5% compared to the
          previous month due to the third wave caused by Omicron-related
          restrictions. However, the number of registrations has tripled
          compared to January 2021.
        </p>
        <p>
          According to official statistics from the Ministry of Road Transport,
          total electric vehicle sales in January 2022 (all shares combined) are
          less as compared to sales of 50,906 units in December 2021 and more
          than January 2021 with sales of 16,214 units in January 2021. A total
          of 48,130 units were sold in Jan 2022.
        </p>
        <h2>Two-wheelers sales in Jan 2022</h2>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/82emHFfeKmkWMx.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          The total number of registered high-speed electric motorcycles or
          scooters increased from 24,725 in December 2021 to 27,563 in January
          2022, an increase of 11% from the previous month and five times more
          than in January 2021 accounted 93% of sales of the total electric
          motorcycles registered from the giant automakers of the country. Hero
          Electric is the largest player with 7,763 electric motorcycle
          registrations this month, a second and third position acquired by
          Okinawa with 5,611 and Ampere vehicles with 4,218 sales of EV
          respectively which makes all three automakers total sales contributing
          to two-thirds E2W sales in Jan 2022. Electric scooters are the first
          choice for consumers and they are the mainstay of sales. We will see
          several launches in the coming months, especially in the high-speed
          scooter segment by traditional ICE experts like Bajaj Auto all set to
          launch a new electric two-wheeler in the market.
        </p>
        <h2>Three-wheelers sales in Jan 2022</h2>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/6rpBUuT6fzZBZQ.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          The number of three-wheeler electric passenger vehicles and
          three-wheeler electric vehicle cargo was registered at around 18The
          electric vehicle industry is off to a great start in 2022. ,176 in
          January 2022, down 22% from the previous month, compared to 23,373
          registered in December 2021. However, it increased by 71% year on year
          as per forecasting. January 2022 electric vehicle registration
          consisted of passenger-type electric three-wheelers and electric
          motorcycles and scooters, which combined contributed to 89.8% of
          monthly registrations. This is followed by electric three-wheelers
          with a 3.8% contribution.
        </p>
        <h2>Four-wheelers sales in Jan 2022</h2>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/7RajudakAa4KBe.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          Electric vehicle sales in January 2022 were 1,479 units, down 41% from
          the previous month, but EV registrations increased by 138% from the
          same month last year. This month, Tata Motors increased electric
          vehicle registration ith 91% sales in January 2022. Sales this month
          were led by PMI Electro, which accounted for 66% of total electric bus
          sales in January 2022, followed by Olectra Greentech with 18% sales
          and JBM Auto with 16% sales in Jan 2022. The total number of electric
          four wheelers registrations in January 2022 was around 1,460, compared
          to 2,522 sales in December 2021.
        </p>
        <h2>Key Highlights</h2>
        <p>
          1. Out of all the regions and geographies, Uttar Pradesh still has the
          highest monthly electric vehicle sales in January 2022, accounting for
          20% of the total sales in India. Maharashtra came in second with a 12%
          share, followed by Karnataka with 10%, Tamil Nadu with 9%, Delhi with
          7%, and Rajasthan with 7% sales of EV in Jan 2022.
        </p>
        <p>
          2. As of January 2022, the combined sales of the seven major electric
          three-wheelers manufacturers in the passenger and cargo sectors
          accounted for 36% of the total E3W market. YC EV ​​has the highest
          market share at 8.9%, followed by Mahindra Electric Mobility with
          7.6%, Saera Electric Auto with 4.8%, Champion Polyplast with 3.9%,
          Dilli Electric with 3.9%, Unique International Special with sales of
          3.4%, and Best Way groups with 3.5% sales in Jan 2022.
        </p>
      </>
    ),
    blogImg: Blog07,
    heading: 'Sales of EV in Jan 2022 India',
    headingDesc: 'The industry has been leading EV...',
  },

  // Blog 8
  {
    blogId: 8,
    answer: (
      <>
        <p>
          <strong>
            Komaki XGT KM v/s Ampere Magnus - Top Speed, Price, Specs, Image,
            Comparison and More
          </strong>
        </p>
        <img src={Blog08} alt='' />
        <p>&nbsp;</p>
        <p>
          The electric scooter has become the new means of transport in urban
          areas, whether in addition to the car and public transport or as a
          mainstay. For walking around town, running errands, or for work, this
          two-wheeled electric vehicle has been widely adopted by city dwellers
          who are delighted with its simplicity and efficiency.
        </p>
        <p>The advantages of an electric scooter in the city:</p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;avoid endless
          traffic jams in big cities,
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;be independent
          of bus and metro timetables,
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;no longer
          looking for a place to park your car,
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;save fuel and
          parking.
        </p>
        <p>
          On the other hand, caution is required on the road because you do not
          have "bodywork" like all two-wheelers. For daily use and as a main
          means of transport, Kazam advises you to opt for a scooter with good
          autonomy, a speed of 25-35 km/h, and is capable of climbing slopes
          easily. The electric scooter makes it possible to do without the car
          for short trips and to save time when traveling on foot. To ease your
          choice, Kazam makes a fair comparison between Komaki XGT KM and Ampere
          Magnus below. It will help you to choose the best scooter between
          these two as per your need and choice.
        </p>
        <p>
          <strong>Komaki XGT KM and Ampere Magnus</strong>
        </p>
        <p>
          The Komaki XGT KM gives you a sporty look with a design pattern
          similar to the Yamaha Ray-ZR and Honda Dio. The Komaki XGTKM is a
          user-friendly electric travel scooter for your daily commute within a
          city.
        </p>
        <p>
          The Ampere Magnus is an affordable electric scooter inspired by the
          Ampere V48. The removable battery is located in the luggage
          compartment under the seat. However, the company still claims that the
          scooter can store a helmet.
        </p>
        <p>
          <strong>Komaki XGT KM and Ampere Magnus-Design</strong>
        </p>
        <p>
          Komaki XGT KM has an&nbsp;Overall Length of&nbsp;1,820 mm, Overall
          Width of 670 mm, and an Overall Height of 1,100 mm. It has a big
          comfortable seat with storage of helmet+battery. XGT has a 1415mm
          wheelbase and it weighs approx 94 kgs. It comes in a contemporary
          design with a sleek body finish.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/25DGJ8zLDq4CLC.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          Unlike its cheaper line of range, Magnus uses a relatively streamlined
          approach to design. The result is slim shoulders and a pleated face
          for a more luxurious look. A thick headlamp is held in the front panel
          and a turn signal is attached directly to the front apron. The Ampere
          Magnus is 1920 mm long, 685 mm wide, and 1120 mm high. The wheelbase
          is 1390mm and the ground clearance is 147mm. It weighs 150 kgs
          maximum. Ampere Magnus comes with three color options: Metallic Red,
          Graphite Black, and Galactic Gray.
        </p>
        <p>
          <strong>Komaki XGT KM and Ampere Magnus-Specs</strong>
        </p>
        <p>
          It has a Telescopic Shock Absorber front suspension and Hydraulic
          Shock Absorber rear suspension. The Komaki XGT KM is equipped with a
          60V motor. The Komaki XGT KM charges the 60V / 20-30Ah battery fully
          in 6-8 hours. It gives a range of 85 km per charge. It has a disc
          front brake and drums rear brake equipped with alloy wheels.
        </p>
        <p>
          The Magnus is equipped with a 38.25 Ah removable 60V lithium battery
          that can be charged from a 5 amp wall outlet of your home or office.
          With a range of 121 km on a single charge, it offers drivers a
          comfortable ride at an affordable price, while powerful batteries
          deliver more power and better performance. It also offers
          best-in-class travel speeds of up to 53 km/h and a class-leading 1200
          W motor. Magnus has different modes: economy mode and power mode. Its
          top speed is 25 km/hour.
        </p>
        <p>
          <strong>Komaki XGT KM and Ampere Magnus-Features</strong>
        </p>
        <p>
          Komaki XGT is equipped with various advanced features like an
          anti-theft alarm, digital odometer, digital speedometer, and digital
          trip meter. It has multiple sensors to give you a comfortable and
          efficient ride. Some other features include a smart dashboard, self
          diagnose, emergency repair switch, and much more.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/4hQSNpcAfy8oAE.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          In terms of hardware, the new Ampere Magnus features a collection of
          digital instruments that display important information such as
          mileage, battery level, and a digital odometer. Unlike the high-end
          class, scooters have LEDs lights in the headlights and taillights. It
          also has a keyless entry feature, anti-theft alarm, spacious seats,
          and 450mm of foot space. The new electric scooter will also feature
          large under-seat storage and standard lighting. For safety in the back
          seat, the scooter has a decent footrest and handlebars.
        </p>
        <p>
          <strong>Komaki XGT KM and Ampere Magnus-Price</strong>
        </p>
        <p>Komaki XGT KM has a price range of approximately Rs. 43,000.</p>
        <p>Ampere Magnus has a price range of Rs. 49,999 - 73,990.</p>
        <p>
          <strong>Wrapping Up</strong>
        </p>
        <p>
          To choose the best electric scooter for your daily commute from the
          many models available, you have to ask yourself the right questions to
          define your needs. The more powerful the scooter, with good autonomy
          and comfort (shock absorbers, wheel size, saddle), the heavier it will
          be. Lighter scooters will be easier to use and more easily
          transportable but less powerful. Digital Dashboard in both the scooter
          above gives information such as speed, battery life, kilometers
          traveled, or even the speed mode. Both scooters above have a good
          lighting system. Ampere Magnus's top speed is 50 km while XGT has 85
          km on a single charge. We hope this article will help you to choose a
          suitable scooter for your daily commute.
        </p>
      </>
    ),
    blogImg: Blog08,
    heading: 'Komaki XGT KM v/s Ampere Magnus',
    headingDesc: 'The electric scooter has become the new...',
  },
  // Blog 9
  {
    blogId: 9,
    answer: (
      <>
        <p>
          <strong>
            Indians' top three budget scooters: Hero Electric Optima, Bounce
            Infinity E1 &amp; Hero Electric Flash
          </strong>
        </p>
        <img src={Blog09} alt='' />
        <p>
          &nbsp;Electric Scooters are one of the favorite modes of transport of
          the Indians! The trend is towards the electric scooter, which is
          increasingly replacing thermal scooters. It is the major advantages of
          this mode of transport that make the electric scooter a particularly
          interesting solution. Focus on this new way of getting around! The
          electric scooter is much less polluting than a thermal scooter which
          rejects a lot of carbon dioxide in the air, but also a large number of
          fine particles. This is why users are increasingly turning to a new
          mode of transport, the electric scooter. Let's see which one is the
          suitable electric scooter for your daily commute out of these three
          and obviously pocket-friendly!!
        </p>
        <h2>Hero Electric Optima</h2>
        <p>
          Hero Electric Optima was launched in India on October 16, 2020, along
          with Hero's 3 city speed range scooters: Hero Nyx-HX, Hero Optima-HX,
          and Hero Photon-HX. Hero Electric Optima is an environmentally
          friendly electric scooter with 4 variants. Its low-speed variants are
          Optima LI and Optima LA, and its high-speed variants are Optima ER and
          Optima E5.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/F72s5KPJPYniB9.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          Design: The Hero Electric Optima is a non-traditional electric scooter
          with a high matte red finish with high-quality plastic. The overall
          design of the scooter is elegant, simple, and unusual. It has a
          digital console that displays battery status, speed, and odometer. All
          four variables have an elegant design and spacious, long seats.
          Electric scooters have dual shock absorbers and a telescopic fork at
          the front and rear respectively with drum brakes.
        </p>
        <p>
          Specifications: The Optima Low-Speed ​​series is equipped with a 250W
          BLDC motor with Li-ion and lead-acid batteries, while the Optima
          High-Speed ​​series is equipped with a 600W/1200W BLDC hub motor. The
          E5 has a single battery and the ER has a dual battery.&nbsp;Talking
          about autonomy in a single charge, The Low-Speed ​​​​series offers 50
          to 65 km autonomy while the E5 and ER offer a range of 55 km and 110
          km respectively. Hero Electric Optima features include fuel status,
          low fuel indicator, digital speedometer, tachometer, low battery
          status indicator, clock, trip meter, and many more.
        </p>
        <p>Price Range: Rs.51,578 (ex-showroom price)</p>
        <h2>Bounce Infinity E1</h2>
        <p>
          The Bounce Infinity E1 comes with a rechargeable battery and charger
          or membership-based model. The membership option to replace the
          battery makes the Bounce Infinity E1 the most affordable e-scooter at
          this price. Customers who opt for a membership package will not
          receive a battery or charger. However, the customers with a
          subscription will be provided with access to the battery replacement
          station set up by the company that will allow you to easily swap your
          batteries. These battery charging stations will be set up in various
          strategic locations across India. Bounce owners with dead batteries
          can visit these battery swapping stations to replace a dead battery
          with a full one and eliminate the struggle of charging the Electric
          scooter.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/2DyPeYFqA4hgUy.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          Design: It has a contemporary and ergonomic design. Bounce Infinity E1
          scooter comes up with five color options: Sporty Red, Sparkle Black,
          Pearl White, Comet Gray, and Desat silver. Its design is somewhere
          similar to the most retro scooters available in the market today, the
          Bounce Infinity E1 was designed as a future definition of retro
          scooters. In addition, Bounce offers several standard Infinity E1
          design options. The taillights in particular are reminiscent of some
          of the Japanese scooters that many of us adore.
        </p>
        <p>
          Specifications: The new Bounce Infinity E1 is equipped with a BLDC
          motor with a maximum torque of 83 Nm. The scooter has a top speed of
          65 km/h and accelerates from 0 to 40 km/h in just 8 seconds. The
          scooter has two riding modes: eco-friendly mode and powerful mode. The
          battery that comes with the scooter is a 48V 39Ah lithium-ion battery,
          which lasts 85 km under normal scenarios .Bounce says its unique
          battery replacement technology saves customers earnings up to 40% over
          traditional petrol or diesel-powered scooters. Bounce has rust-proof
          alloy wheels, Handlebar-mounted LED Projector Headlamp, taillights,
          inbuilt storage for helmets. The scooter also has a digital
          speedometer for odometer reading, battery status, speed, and much
          more. The scooter also offers features such as cruise control, drag
          and drop mode, geofencing, reverse mode, anti-theft system, and a long
          seat for two. This unique feature of battery swapping is new to the
          people of the country.
        </p>
        <p>Price: Rs. 45,099 (ex-showroom price)</p>
        <h2>Hero Electric Flash</h2>
        <p>
          Hero Electric Flash is the entry-level electric scooter offered by
          Hero in the Indian EV market. Hero Flash has been offered to the
          buyers in three classy color options: white, red, and gray. Hero Flash
          looks good in its current style. Alloy wheels and glossy paintwork
          give this scooter an elegant look. In addition to an under-seat
          storage compartment, the flash comes with a carrying luggage space for
          added convenience.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/BLA1kaWp7eYZqW.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          Design: In terms of design, the front apron is largely a wide
          headlight with indicators on the handlebar.On the other hand, alloy
          wheels give an elegant look to this scooter.Anyway, the flash is a
          scooter that looks good in any case. In terms of the front look, the
          scooter is lightweight with a total weight of 87kg and ground
          clearance of 165mm. In terms of mechanical hardware, the flash comes
          with 16x3" front and rear tyres. In fact, both tyres come with a
          diameter of 16 inches. Braking functions are performed with drum
          brakes on each corner.
        </p>
        <p>
          Specification: The flash is equipped with an electric motor that
          develops a top speed of 25 km/h and a range of approximately 65 km in
          a single charge. Hero Electric Flash has a 250W electric motor with a
          48V 20Ah battery. It also supports automatic transmissions. In terms
          of range, the Hero electric flash has a range of 50 km on the single
          charge for the lead-acid battery model and gives a range of 65 km on a
          single charge for the lithium-ion battery model. The headlights and
          taillights use traditional halogen bulbs. The new Hero Electric Flash
          comes with a collection of digital instruments that display digital
          speed and fuel status.
        </p>
        <p>Price: Rs. 46,662 (ex-showroom price)</p>
        <h2>Wrapping Up</h2>
        <p>
          The government is actively promoting electric vehicles (EVs) in India
          to save the environment. This presents automobile companies with a
          great opportunity to take benefits from India's fast-growing electric
          scooters market. The government is working on the infrastructure
          needed for electric vehicles. It stands ready to help companies lay a
          foundation for the production of electric vehicles and their parts in
          India. Therefore, the government is implementing the FAME-II electric
          vehicles subsidy system across the country, and we expect to install
          as many public charging stations as possible in the next 10 years. So
          above is a list of the top 3 budget electric scooters available in
          India today with a price range under Rs.50,000. &nbsp;
        </p>
      </>
    ),
    blogImg: Blog09,
    heading: "Indians' top three budget scooters",
    headingDesc: 'Electric Scooters are one of the favorite...',
  },
  // Blog 10
  {
    blogId: 10,
    answer: (
      <>
        <p>
          <strong>Top 5 Upcoming Electric Scooters in 2022</strong>
        </p>
        <img src={Blog11} alt='' />
        <p>
          &nbsp;The world is going electric. All automobile majors are fighting
          for a share in the emerging market. When Ather launched its first
          electric scooter, it set a benchmark for the competition. With major
          players like Bajaj, Hero, Yamaha &amp; Suzuki ready with their EVs, it
          is a win-win for consumers with respect to options, prices, efficiency
          &amp; design.
        </p>
        <p>
          E-Commerce giants Amazon &amp; Flipkart have promised to go 100%
          electric in their logistics fleet by 2030. Zomato also announced
          similar plans to support sustainable last-mile delivery. Another
          encouraging story is that of Ola Electric. Ola&rsquo;s entry into
          India&rsquo;s two-wheeler segment has helped the EV revolution get a
          much-needed boost after the pandemic.
        </p>
        <p>
          Let's have a look at 5 upcoming electric scooters in 2022 which are
          likely to create the most buzz amongst consumers:
        </p>
        <h2>1. Hero eMaestro:</h2>
        <p>
          Hero unveiled the eMaestro concept in 2020. It gets the
          Maestro&rsquo;s bodywork, suspension &amp; wheels. The EV is in the
          final stages of testing and is expected to launch in the first quarter
          of 2022. Hero MotoCorp is known for its competitive pricing, so we can
          expect the scooter to be priced below Rs.1 lakh.
        </p>
        <p>
          The eMaestro will sport a permanent magnet motor and a lithium-ion
          battery pack. Since Hero MotoCorp owns a sizeable stake in
          Bangalore-based Ather Energy, it is expected that eMaestro will use
          the Ather 450X&rsquo;s battery pack which offers a range of 85 km
          &amp; 6kW motor. The expected top speed is 70 kmph.
        </p>
        <p>
          It is expected to take-on rival two-wheeler brands especially Bajaj
          Chetak Electric &amp; TVS iQube.
        </p>
        <p>Expected Price: Rs.1 Lakh. Expected Launch: March 2022.</p>
        <h2>2. TVS Creon:</h2>
        <p>
          TVS Creon is an electric scooter concept that was unveiled at the 2018
          Auto Expo. At the moment, TVS has only one electric scooter in its
          portfolio, the iQube Electric. The Creon is expected to sport a 12 kW
          motor, which is good for a 0-60 kmph time of about 5 seconds. The
          battery pack will offer a range of 80 km and can be charged to 80% in
          1 hr.
        </p>
        <p>
          Like all TVS vehicles, the Creon will also be manufactured in its
          Hosur, Tamil Nadu plant. It is likely to get TVS Jupiter&rsquo;s body
          but a new face.
        </p>
        <p>Expected Price: Rs.1.2 Lakhs. Expected Launch: March 2022.</p>
        <h2>3. Vespa Elettrica:</h2>
        <p>
          Piaggio India is expected to introduce a Vespa electric scooter by
          mid-2022. The Elettrica concept was showcased at the 2020 Auto Expo.
          The scooter will feature the retro Vespa design. It is expected to
          host a 4 kW DC motor. The battery pack will take 4 hrs to fully charge
          and is also equipped with the Kinetic Energy Recovery System (KERS).
          Two riding modes: Eco and Power, are expected to hit the Indian
          market. The battery pack will offer a range of up to 100 km.
        </p>
        <p>
          The price is expected to be costlier than the competition since the
          brand is considered premium.
        </p>
        <p>Expected Price: Rs.1.5-2 Lakh. Expected Launch: July 2022.</p>
        <h2>4. Yamaha EC-05:</h2>
        <p>
          The Yamaha e-scooter is one of the most anticipated electric scooters
          in India. The EC-05 will feature 2 removable batteries, a 100 km range
          and a top speed of 90 kmph. It&rsquo;ll take 3 hrs to fully charge.
          Yamaha claims the scooter can clock 0-50 kmph in under 4 seconds.
          Yamaha will also provide a home charger called the GoCharger with the
          scooter.
        </p>
        <p>
          According to fresh media reports, Yamaha &amp; a Bangalore-based
          vehicle rental company Drivezy have tied up. Drivezy is planning to
          add the EC-05 to its fleet. Initially, it aims to import 5000 units of
          the e-scooter.
        </p>
        <p>
          The EC-05 is already available in Taiwan. It is likely to be launched
          in India &amp; Europe at the same time.
        </p>
        <p>Expected Price: Rs.80,000-1 Lakh. Expected Launch: July 2022.</p>
        <h2>5. Husqvarna Vektorr Electric:</h2>
        <p>
          Husqvarna or &lsquo;Husky&rsquo; as it is lovingly called, is a
          legendary Swedish brand that is coming up with an electric scooter
          called the Vektorr. Based on the Bajaj Chetak electric, the Husqvarna
          brings its own unique taste to the EV segment. The Vektorr has been
          designed by Kiska Design Studio owned by KTM. The scooter is only
          available in a black &amp; white colour combination.
        </p>
        <p>
          The Vektorr will be powered by a 4 kW motor, with a maximum speed of
          45 kmph, making it obvious that the scooter is meant for small
          distances only. The battery range will be 95 km and its charging will
          take around 5 hrs. The scooter will be manufactured at Bajaj
          Auto&rsquo;s factory.
        </p>
        <p>Expected Price: Rs.1.5 Lakh. Expected Launch: March 2022.</p>
        <h2>Other Notable Mentions:</h2>
        <p>
          Apart from the above, there are a few other electric scooters that may
          hit the roads in 2022. Hero Electric AE-29, Okinawa Cruiser, Super
          Soco Cumini, Bird ES1+, Honda PCX Electric, Lambretta G Special
          Electric, Ultraviolette F77, Revolt RV1, Unnamed LML e-scooter.&nbsp;
        </p>
      </>
    ),
    blogImg: Blog11,
    heading: 'Top 5 Upcoming Electric Scooters in 2022',
    headingDesc: 'The world is going electric. All autombie...',
  },
  // Blog 11
  {
    blogId: 11,
    answer: (
      <>
        <p>
          <strong>Top 5 Upcoming Electric Cars in 2022</strong>
        </p>
        <img src={Blog10} alt='' />
        <p>
          The global automobile industry is witnessing a gradual shift to
          Electric Vehicles in recent years. The Indian car market is no
          exception and this momentum is likely to surge with upcoming launches
          in 2022. Let&rsquo;s have a look at the top 5 most anticipated
          electric cars getting launched in 2022.
        </p>
        <h2>1. Mahindra eKUV 100:</h2>
        <p>
          Mahindra has elaborate plans to dominate the electric mobility space.
          They showcased a few EVs at the Auto Expo in New Delhi in 2020. The
          most promising of those was an electric version of KUV 100. The eKUV
          100 will carry most of its features from the KUV 100.
        </p>
        <p>
          Mahindra had disclosed some specifications at the Auto Expo. Powering
          the eKUV 100 is a 40 kW electric motor that draws energy from a 15.9
          kWh battery pack. A range of around 140 km can be expected on a single
          charge. While using a regular AC charger, the battery pack takes about
          6 hrs to get fully charged. Using the DC fast charger, the battery
          gets charged in 1 hr.
        </p>
        <p>
          The eKUV 100 will be positioned as an entry-level EV competing with
          Tata Tiago electric, Maruti Suzuki WagonR electric &amp; Tata Punch
          electric.
        </p>
        <p>
          <em>Expected Price</em>: Rs.8-10 lakhs.&nbsp;
          <em>Expected Launch</em>: First Quarter 2022.
        </p>
        <h2>2. Tata Sierra EV:</h2>
        <p>
          Tata Motors unveiled the Sierra electric concept car at Auto Expo in
          New Delhi in 2020. It is a modern version of the old classic Sierra
          SUV that we loved.
        </p>
        <p>
          It is likely to feature the Ziptron EV powertrain, which gives a
          &lsquo;mileage&rsquo; of 400 km on a single charge. Ziptron is Tata
          Motor&rsquo;s state-of-the-art EV technology boasting of an efficient
          high voltage system, long-range, fast-charging capability battery with
          8 yrs warranty. It takes 1 hr to achieve an 80% charge.
        </p>
        <p>
          With Tata Motors reaping more-than-decent benefits with the initial
          success of Tata Nexon EV &amp; Tata Tigor EV, it will be interesting
          to see how soon it launches the Sierra.
        </p>
        <p>
          <em>Expected Price</em>: Rs.14-18 lakhs.&nbsp;
          <em>Expected Launch</em>: First Quarter 2022.
        </p>
        <h2>3. Nissan Leaf:</h2>
        <p>
          Now in its second generation, the Leaf was listed as the world&rsquo;s
          all-time top-selling plug-in electric car until it was surpassed by
          the Tesla Model
        </p>
        <p>
          The current generation gets power from a 110 kW electric motor paired
          with a 40 kWh battery pack. There is a high-performance variant that
          comes with a 62 kWh battery pack, but it is unlikely to come to India.
          The 40 kWh battery pack takes around 7 hrs to get fully charged with
          240 V charging equipment and 18 hrs with a regular adaptor. Its range
          is scaled at 400 km from a full charge.
        </p>
        <p>
          Nissan is interested in assembling its best selling EV at its plant in
          Chennai.
        </p>
        <p>
          Despite being present in India for quite some time, Nissan is yet to
          grab a significant market share here. But it is trying to revamp its
          strategy with new &amp; interesting products. Nissan Magnite is an
          example of that attempt and it has seen a good response from buyers.
        </p>
        <p>
          <em>Expected Price</em>: Rs.30 lakhs.&nbsp;<em>Expected Launch</em>:
          First Quarter 2022.
        </p>
        <h2>4. Volvo XC40 Recharge:</h2>
        <p>
          The all-electric XC40 Recharge is headed to India in early 2022. The
          SUV which is Volvo&rsquo;s first all-electric model will get a
          twin-battery setup with a range of 418 km. It will have a 78 kWh
          battery pack. The SUV is claimed to sprint from 0-100 kmph in less
          than 5 seconds and a top speed of 180 kmph. It will support fast
          charging, up to 150 kW, charging the SUV to 80% in 40 mins.
        </p>
        <p>
          The car&rsquo;s cabin is made up of sustainable materials, to reduce
          the car&rsquo;s carbon footprint over its lifecycle, for instance, the
          interior carpeting is made entirely from recycled plastic.
        </p>
        <p>
          The SUV will be imported as a completely built unit. It was expected
          to be launched last year but was delayed due to the global
          semiconductor shortage. It will rival other luxury electric SUVs like
          the Audi e-Tron, Jaguar iPace &amp; Mercedes EQC.
        </p>
        <p>
          <em>Expected Price</em>: Rs.60 Lakh<em>. Expected Launch</em>: First
          Quarter 2022.
        </p>
        <h2>5. Tesla Model Y:</h2>
        <p>
          Tesla will launch the Model Y as its debut car in India. Earlier, the
          Model 3 was being considered but the plan seems to be shelved since
          its low ground clearance became a major hindrance during testing.
        </p>
        <p>
          The Model Y is powered by a 75 kWh battery and 2 electric motors. A
          range of 507 km was recorded. It goes from 0 to 100 kmph in 5 seconds
          and reaches a top speed of 217 kmph. The Model Y can charge to 80%
          through a DC fast charger in 30 mins or to 100% via an 11 kW AC
          charger in 7.5 hrs. It promises a range of 525 km per charge.
        </p>
        <p>
          The Model Y was spotted undergoing high altitude and cold-weather
          testing before its launch in Manali in October 2021.
        </p>
        <p>
          <em>Expected Price</em>: Rs.80 Lakhs.&nbsp;<em>Expected Launch</em>:
          March 2022.
        </p>
        <h2>Other Notable Mentions:</h2>
        <p>
          Apart from the above, there are plenty more to look forward to in
          2022. We are likely to see the launch of Audi Q4 e-Tron, BMW iX,
          Hyundai Kona Electric Facelift, Kia EV6, Mercedes Benz EQS, Porsche
          Taycan, Tata Tiago EV, Tata Altroz EV, Mahindra eXUV 300, Maruti
          Suzuki WagonR Electric, Maruti Suzuki Futuro-e, Ford Mustang Mach E,
          Renault K-ZE, Renault Zoe, ORA R2, MG ZS EV, Tesla Model X, Tata Punch
          EV.&nbsp;
        </p>
      </>
    ),
    blogImg: Blog10,
    heading: 'Top 5 Upcoming Electric Cars in 2022',
    headingDesc: 'The global automobile industry is witnessing...',
  },
  // Blog 12
  {
    blogId: 12,
    answer: (
      <>
        <p>
          <strong>Strom Motors R3 v/s Datsun redi-GO</strong>
        </p>
        <img src={Blog12} alt='' />
        <p>
          In recent years the purchase of electric cars could appear risky, both
          for the reduced autonomy of the models offered on the market and for
          the decidedly high purchase price. Today this choice may not seem so
          risky when we talk about Strom Motors R3 v/s Datsun Redi-GO. Do you
          like Strom Motors R3 but also want to buy a Datsun Redi-GO? Choosing
          between both Electric Cars is so difficult? Don't worry, we are here
          to help you!!
        </p>
        <p>
          As with any motor vehicle, electric cars are spread over different
          market sectors to meet different expectations. In this post, we will
          discuss design, performance, price, autonomy, features, and so on.
        </p>
        <h2>Strom Motors R3-The Personal Electric Car</h2>
        <p>
          Mumbai-based startup Strom Motors presents Strom R3 in India for its
          car lovers. With the emerging electric vehicles in the Indian market,
          car manufacturer Strom is also ready to try its luck in the EV
          industry. This two-door electric vehicle has a reverse trike
          configuration with rear wheels. This vehicle is ideal for metropolitan
          cities like Mumbai, Delhi, and Bangalore. The new Strom R3 offers
          three variants of the Pure, Current, and Bolt, as well as 200 KMS
          autonomy. It has a mileage of 0.40/KM. When the Strom R3 is released
          later this year, it will cost around Rs 3 lakh across the country.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/Yc9YbmgKoUMYX.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <h3>Strom Motors R3 Specification</h3>
        <p>
          In terms of specification, the Strom R3 maintains a power of 17.4 hp.
          It has been designed with a torque of 48 Nm to make up for the
          shortfall. If we talk about transmission operations in Storm R3, it is
          performed by a single-speed planetary transmission. It is equipped
          with a highly efficient 13 kW (17.4 hp) motor and is connected to a
          single-speed planetary gearbox. You can charge Strom R3 fully in
          approximately 6-8 hours with a standard charger. It also has an
          optional quick charge feature that can charge your battery to 80% in 2
          hours. This vehicle also uses a very unique braking technology which
          is called electronic regenerative. It makes your ride comfortable and
          safer at the same time.
        </p>
        <h3>Strom Motors R3 Design</h3>
        <p>
          The Strom R3 has an avant-garde design, a giant front bumper, LED
          headlights for clear visibility at night, two-tone white paint, and a
          sunroof to enjoy your ride more closely by sitting in your car. The R3
          also has 12-inch alloy wheels and an outside mirror (ORVM). It is
          available in 4 color variants: electric blue, neon blue, red, and
          black. The interior is designed for city travel, making it ideal for
          two adults. The company offers two seating positions, two front seats,
          and a long back seat ideal for three. Strom R3 is equipped with
          various comfort specs. Designed by a Mumbai novice, the Strom R3 is
          not a car, but a tricycle that seems to be inspired by Morgan Garage
          UK.
        </p>
        <h3>Strom Motors R3 Features</h3>
        <p>
          Strom R3 has been equipped with a range of key and safety features. It
          has an infotainment system with a 7-inch touchscreen with a voice
          control User Interface. Its infotainment system is compatible with
          FM/USB systems. If you are a music lover while traveling, here is a
          piece of good news for you. R3 offers you 20GB onboard storage for
          your favorite travel songs with obviously Google maps and navigation
          features. You can also connect this with Wifi and 3G network. It has a
          remote camera with a Remote keyless feature and also a Remote Assisted
          Parking System.
        </p>
        <h3>Strom Motors R3 Price</h3>
        <p>
          You have an option to Pre-book this. Its price range starts from 3
          lakh rupees which ranges upto 4.5 lakh rupees with 80% less
          maintenance.
        </p>
        <h2>Datsun Redi-GO</h2>
        <p>
          Known as the country's first 'urban crossover', the Datsun Redi-GO has
          been based on the CMF-A platform, which shares the same foundation as
          the Renault Kwid. Redi-Go shares the platform with Kwid and is 25kg
          lighter than its predecessor. The Datsun Redi Go and Renault Kwid are
          powered by the same 0.8-litre petrol engine developed by the
          Renault-Nissan alliance.
        </p>
        <h3>Datsun Redi-GO Specification</h3>
        <p>
          The Datsun Redi-Go is a 5-seat hatchback. This is one of the best
          cars. Datsun Redi-Go is equipped with an automatic petrol engine. The
          movement of both petrol engines is 799 cc and 999 ccs, and the fuel
          capacity is 28 litres. It is equipped with automatic transmission.
          Miles from 22.5 to 23.0 km/h depending on fuel type and type. The
          Datsun Redi-Go is 3429 mm long, 1560 mm wide, and has a wheelbase of
          2348 mm. The maximum power of the Datsun Redi-Go is 67 bhp at 5500 rpm
          and the maximum torque at 4250 rpm is 91 Nm.
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/6PC8KWyjpY8T2P.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <h3>Datsun Redi-GO Design</h3>
        <p>
          Redi-GO is smaller than Kwid but bigger. The wheels are located over
          the entire edge of the body. The elevated position creates a cool
          cabin that appears large enough for less than 5 passengers. Its length
          is 3,429 mm, width is 1,560 mm, and Height is 1,541 mm. The Redigo is
          larger than before, with a large chrome mesh on the panel. The
          straight L-shaped DRL LED with rounded fog lamps makes the dashboard
          stand out even more. The bump is now black compared to older models.
          Externally, there are no changes other than the design of the new
          wheel-cover. At the rear, the car features the new iconic LED icon.
        </p>
        <h3>Datsun Redi-GO Features</h3>
        <p>
          Compared to the previous model, the standard model has a modern
          design. The car is equipped with a touchscreen infotainment system in
          the center of the dashboard with a vent above the system and air
          conditioning controls at the bottom. The control panel has a different
          silver finish for a sleek, modern look. In terms of features, it
          offers an 8-inch touchscreen that supports Apple CarPlay and Android
          Auto. It has a background camera, removable packaging tray, and
          internal ORVM settings. For safety, this car is equipped with dual
          airbags, ABS with EBD, and rear parking sensors. In terms of engines,
          the Redigo has two petrol engines of 0.8 and 1.0 liters. The first can
          produce 54 horsepower at 5600 rpm and 72 Nm at 4250 rpm, and the
          second has 67 horsepower. at 5500 rpm and 91 Nm at 4250 rpm.
          Five-speed manual transmission is standard, but AMT units are only
          available in the 1.0L version.
        </p>
        <h3>Datsun Redi-GO Price</h3>
        <p>
          Its price has been started from ₹3.98 lakh and ranges upto 4.96 Lakhs
          available in 6 variants across the country.
        </p>
        <h2>Wrapping Up</h2>
        <p>
          The Datsun GO is equipped with features such as a 12V outlet. Strom
          Motors R3 has a keyless entry, navigation system, 12V socket, and
          advanced features like voice recognition. In addition, the Datsun GO
          has a multifunctional display with 5 doors and 5 seats. Strom R3's
          total weight is 859 kg and the wheelbase is 2450 mm. The R3, on the
          other hand, is a hatchback with two doors and a seat, a fuel tank with
          a luggage capacity of 300 liters. Overall, both the cars are equipped
          with many advanced features that will definitely blow your mind. We
          hope that this article will help you to choose the perfect car for
          your perfect journey. You just need to evaluate and analyze all the
          parameters and buy the ideal car for you.
        </p>
      </>
    ),
    blogImg: Blog12,
    heading: 'Strom Motors R3 v/s Datsun redi-GO',
    headingDesc: 'In recent years the purchase of electric cars...',
  },
  // Blog 13
  {
    blogId: 13,
    answer: (
      <>
        <p>
          <strong>
            How to become an electric vehicle (EV) charger installer?
          </strong>
        </p>
        <img src={Blog13} alt='' />
        <p>
          In most developed countries,&nbsp;any qualified electrician is
          authorized to install EV charging points if they have the specific
          skills for the hardware, although an increasing number of
          manufacturers &amp; businesses expect the professionals to have
          additional training certifications for EV charger installation.
        </p>
        <h2>Academy of EV Technology (AEVT):</h2>
        <p>
          Academy of EV Technology (AEVT) is an emerging institution with
          training locations in Howrah (Kolkata), Mumbai &amp; Kotdwar
          (Uttarakhand). The academy&rsquo;s mission is to meet the needs of the
          emerging EV industry in India, by training a new generation of skilled
          electricians, technicians &amp; EV auto-mechanics.
        </p>
        <p>
          They offer a certificate course for EV Charging Station installing.
          The course covers the following:
        </p>
        <p>1. Charging Station site assessment.</p>
        <p>2. Charging Station design &amp; costing.</p>
        <p>3. Safety protocols.</p>
        <p>4. Installing the charger.</p>
        <p>5. Government rules &amp; protocols.</p>
        <p>6. A practical module on roadside public charging stations.</p>
        <p>7. A practical on charging point installation.</p>
        <p>
          The total fee for this course is Rs.14999 (online class fees) +
          Rs.1999 (hands-on/practical fees). Post completion, the candidate will
          get a recognized certificate &amp; free registration in a dedicated
          service desk. The academy is backed by a trust which is in the process
          to start a dedicated service desk that will provide startups with
          trained technicians, engineers &amp; advisors.
        </p>
        <p>
          The academy also provides customized corporate training covering a
          variety of courses tailor-made for the EV industry.
        </p>
        <h2>Current India Scenario:</h2>
        <p>
          In India, finding trained personnel for EV charger installation is a
          task in itself. The average electrician may not be able to do the job
          with his skill set. Most companies providing EV charging solutions
          like Tata Power, Kazam, etc provide site assessment &amp; installation
          when you go for their products. The Kazam EV charging point setup
          process is shown below:
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/9Qk7QopQmWqykf.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          MG Motors India, in a way to provide charging solutions to MG ZS EV
          buyers, has tied up with eChargeBays, a Delhi-based startup. The aim
          is to assist MG customers in setting up charging points in their
          homes. They provide solutions including sending trained electricians
          for installation &amp; demonstration of EV chargers and subsequent
          servicing if needed. This shows there is a lack of trained personnel
          for EV charger installation in India and also, the potential scope for
          professionals who want to enter this field.
        </p>
        <p>
          There are a number of online platforms offering e-courses for
          understanding EV charging stations and how they are installed. But
          only a few offer hands-on training. The need for EV charging station
          installers can be fulfilled by either including a module on the topic
          in engineering courses or in Industrial Training Institutes (ITIs).
          Apart from this, uncertified &amp; semi-skilled electricians should
          also be offered an opportunity to become a part of the EV revolution
          by means of a free or subsidized upskilling course, which helps them
          learn about EV charging stations' functionality.
        </p>
        <p>
          As western countries began EV adoption much earlier than us, we have a
          lot to learn from them. Countries like India, which currently deploys
          a limited number of EVs, can take valuable lessons about manpower
          needs from countries like the US, Japan, etc.
        </p>
        <h2>The US Scenario:</h2>
        <p>
          In California, a charging station company had a hard time finding
          people to fix broken-down stations. They were contacting electrical
          contractors who took 7-14 days to send electricians on-site. Last
          year, a startup named &lsquo;ChargerHelp&rsquo; launched an app that
          diagnoses problems with charging stations and sends technicians, which
          are trained by the company.
        </p>
        <p>
          Such solutions have been coming up in the US ever since the numbers of
          EVs &amp; charging stations started increasing.
        </p>
        <p>
          Another example is the efforts of a union of electricians in the US
          which is making sure that the existing workforce is ready to take on
          EV-related work. An upskilling course &lsquo;Electric Vehicle
          Infrastructure Training Program&rsquo; (EVITP) has trained around 3000
          electricians all across the US to install &amp; maintain EV charging
          stations.&nbsp;The EVITP curriculum, developed via a partnership
          amongst unions, auto manufacturers, utility and educational
          institutions, provides electricians with instructions and hands-on
          training to install both residential &amp; public charging stations.
        </p>
        <p>
          The EVITP course content can be a guide for all Indian stakeholders
          involved in the EV ecosystem, if and when they wish to formally start
          a training program to prepare the future EV charger/charging
          station/charging point installers.
        </p>
        <h2>EVITP course overview:</h2>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/7HQdifjFTmw12W.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          A recommendation in the US Senate stated that funding for the setting
          up of EV charging infrastructure must mandatorily involve the clause
          that installation &amp; maintenance work is done by certified workers
          who are qualified to deal with the hardware. The charging equipment
          should be installed by certified workers and government should play an
          active role in supporting required training &amp; certification
          programs.
        </p>
        <h2>Conclusion:</h2>
        <p>
          The &lsquo;markets of tomorrow&rsquo; have led to a growing need for
          reskilling &amp; upskilling workers via training &amp; courses. A
          forum committed to electrifying European transport &lsquo;Platform for
          Electromobility&rsquo; predicts huge job increases in this field,
          especially in charging infrastructure for electric vehicles.
        </p>
      </>
    ),
    blogImg: Blog13,
    heading: 'How to become an Electric Vehicle',
    headingDesc: 'In most developed countries, any qualified...',
  },
  // Blog 14
  {
    blogId: 14,
    answer: (
      <>
        <p>
          <strong>
            How can Micro Entrepreneurs use Kazam EV Charging Stations to expand
            their network?
          </strong>
        </p>
        <img src={Blog14} alt='' />
        <p>
          The microenterprise is an individual enterprise intended for small
          structures such as the auto-entrepreneur and micro-entrepreneur whose
          activity is capped and operation simplified. The creation of a
          micro-enterprise requires completing the formalities of a sole
          proprietorship. The micro-entrepreneur carries out his activity under
          a very precise statute: that of the micro-enterprise. By definition,
          it is an entrepreneur who falls under the micro regime in the social
          field and also on the fiscal level. Small companies, such as
          micro-enterprises, have to face greater difficulties, facing a highly
          competitive market dominated by very large companies. However, being
          part of this category of business has several advantages such as
          policies and financial aids. Various examples of Micro-Entrepreneurs
          are carpenters, shop owners, plumbers, small farmers, mechanics,
          vendors, and so on.
        </p>
        <p>
          <strong>New opportunities for Micro Entrepreneurs</strong>
        </p>
        <p>
          The use of electric cars is starting to become more popular today. The
          objectives of the government are particularly ambitious on the
          subject. The policies have come in reinforcement to boost the adoption
          of electric cars, their development will be undeniable. A
          Micro-Entrepreneur that decides to use the Kazam EV charging station
          for its business will benefit from a myriad of advantages. First of
          all, it reflects a more positive image of the brand, which is
          responsible, innovative, and attentive to its customers. This will
          help to expand your business among EV users or drivers. The public is
          particularly sensitive to environmental issues. By investing in the
          establishment of a public charging station, the microenterprise
          attracts the attention of its customers and can even target a new
          audience which eventually targets the new segment of the market.
          Microenterprises must contribute to the preservation of the ecology
          and they are often singled out on this point. To stand out, they,
          therefore, have an interest in showing concrete actions that are
          manifested by the installation of electric car terminals.
        </p>
        <p>
          From a commercial point of view, having Kazam EV Chargers in one's car
          park contributes to the diversification of the small businesses'
          services and activities. It is indeed possible to make these Kazam
          Charging Station pay to derive a profit from them. Thanks to the
          positive experience he has had with Kazam Chargers, the customer will
          come back more regularly to take advantage of its service. This is a
          great way for a Micro-Entrepreneur to stand out from the competition.
          Moreover, when recharging their vehicle, the user will have to wait
          between thirty minutes to two hours depending on the power of the
          terminal. During this time, he will take the opportunity to do his
          shopping or take a look for the business, which increases enterprise
          sales and turnover more generally. By installing a charging station in
          their car park, enterprises are at the same time in tune with the new
          policies that are constantly evolving to promote electromobility in
          the country. Finally, making such an investment is also a way for
          microenterprises to take advantage of state or central aid.
        </p>
        <p>
          <strong>Which charging station to choose?</strong>
        </p>
        <p>
          The trickiest question is choosing the type of charging station to
          install. Several criteria must be taken into consideration, starting
          with the power. Given that the stations will be subject to intensive
          use and that users do not have much time to recharge the vehicle, it
          is advisable not to bet on low-power chargers such as those of 7.4 kW
          or 11 kW. These normal recharges are still quite slow compared to the
          needs of users who do not spend more than one to two hours in shopping
          centers. It is, therefore, preferable to turn to chargers of 22 kW or
          even 50 kW. A second criterion guiding the choice of chargers relates
          to the functionalities associated with the equipment. Ideally, it
          should be equipped with IoT-enabled chargers like Kazam Mini and Kazam
          3.3, connectivity (wifi, 3G, or Bluetooth), an LED indicator, and so
          on.
        </p>
        <p>
          <strong>
            Build your own charging network with Kazam CMS feature
          </strong>
        </p>
        <p>
          Kazam Charging Management System (CMS) is an ideal solution for Charge
          Point operators, Charge Point Manufacturers, Residential &amp;
          Commercial Properties, Electric Vehicle Fleets, and Developers. You
          can configure, manage and control all of your charging networks with a
          single dashboard.
        </p>
        <p>You can:</p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Locate all
          your chargers in one place
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monitor charge
          point consumption and get real-time visibility of transactions
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Create a
          custom mobile app or integrate it into an existing mobile app with our
          Kazam API integration platform.
        </p>
        <p>
          To have a precise idea of ​​the cost of installation, requesting
          quotes from Kazam EV is essential. This approach is a way of creating
          competition between Micro-Entrepreneurs and thus bringing prices down
          while benefiting from a more qualitative service. The quote request
          can be done quickly online to get answers as soon as possible. It has
          the advantage of being fast and non-binding.
        </p>
      </>
    ),
    blogImg: Blog14,
    heading: 'How can Micro Entrepreneurs use Kazam',
    headingDesc: ' The microenterprise is an individual enterprise...',
  },
  // Blog 15
  {
    blogId: 15,
    answer: (
      <>
        <p>
          <strong>
            What is the best way to purchase an electric vehicle charging
            station?
          </strong>
        </p>
        <img src={Blog15} alt='' />
        <p>
          Electric Vehicle charging infrastructure is rapidly expanding in
          India. In a bid to pace up its journey towards carbon neutrality, the
          field is open for private players to participate &amp; take charge of
          the massive effort required to accelerate the adoption of EVs.
        </p>
        <p>
          The Ministry of Power revised norms to further support the creation of
          electric vehicle charging infrastructure across the country. Two
          important points in these revised guidelines were: removal of
          licensing barriers for setting up EV charging stations &amp; allowing
          private entities to procure government land for setting up and
          operating charging stations on a revenue-sharing basis.
        </p>
        <p>
          If an individual or a private entity wants to set up an electric
          vehicle charging station, the person must choose the right charging
          solution, supplier, and location. Also, such stations must meet the
          technical, safety as well as performance standards and protocols laid
          down by the Ministry of Power.
        </p>
        <p>
          There are various types of EV chargers out there. These are mainly
          categorized based on the levels of charging: Level 1, 2 &amp; 3. More
          on these below:
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AC chargers
          (level 1) are slow chargers that take almost 6-8 hours to charge an EV
          to full capacity. Level 1&nbsp;EV Chargers&nbsp;use a 120V AC
          plug&nbsp;which can be plugged into a standard wall outlet.&nbsp;These
          are often used at home.&nbsp;Level 1 Electric Chargers are the
          cheapest amongst all the available charging stations, but they also
          consume the largest amount of time to charge your car&rsquo;s
          battery.&nbsp;Bharat EV specifications recommend the installation of
          an RCCB &amp; using an IEC 60309 connector.
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Level
          2&nbsp;Electric Vehicle Chargers are used in both commercial &amp;
          residential charging stations. They are perfect for both: businesses
          that plan to offer fast charging options to customers &amp; homeowners
          who require&nbsp;speedy charging for their EVs.
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Level
          3&nbsp;Electric Vehicle Chargers, also known as DC Fast Chargers have
          CCS or CHAdeMO connectors. DC fast chargers are usually used only in
          the industry &amp; commercial applications.&nbsp;
        </p>
        <p>
          If you are planning to set up your own EV charging station, you just
          have to follow the minimum infrastructure requirements, find the right
          vendor &amp; the right location.
        </p>
        <p>
          The cost of setting up an EV charging station in India varies from Rs
          1 Lakh to Rs 40 Lakhs depending on the type of charger you want to
          install at the station.
        </p>
        <p>
          <strong>At-a-glance view of the same is shown below:</strong>
        </p>
        <figure>
          <img
            srcSet='https://whimuc.com/CEkJLdfa6xzxvKqStjdgTd/DC42a8nQKCnTm1.png 2.08x'
            width='100%'
            alt=''
          />
        </figure>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Electricity
          connection cost (250 KVA): 7,50,000
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Civil works:
          2,50,000
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CMS software
          integration cost: 40,000
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manpower &amp;
          maintenance cost: 3,50,000 per annum.
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Advertising
          expenses: 50,000
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the land is
          on lease: 6,00,000 per annum.
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total: Rs
          40,00,000.
        </p>
        <p>
          (Above are approximate estimates and figures might vary from state-to
          state.)
        </p>
        <p>
          For public charging stations, the Department of Heavy Industries has
          approved Bharat Charger specifications: Bharat EV Charger AC001 (for
          AC charging) &amp; Bharat EV Charger DC001 (for DC charging).
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The AC001
          delivers a 15A charging current at 3.3 kW to three vehicles
          simultaneously. The total cost of this charger and its ancillary
          equipment falls between Rs 40-55 thousand.
        </p>
        <p>
          &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The DC001
          delivers a 200A charging current at 15 kW power, directly to the
          vehicle&rsquo;s battery. This charger uses the OCPP protocol for
          EVSE-CMS communication. The total cost of this charger and its
          ancillary equipment varies between Rs 2-3 lakh.
        </p>
        <p>
          Premium e-cars with a battery capacity of 30 kWh and upwards have to
          be fast-charged using high voltage charging tech. The government is
          pushing Bharat Standard, CHAdeMO &amp; CCS charging points at all
          public stations for the same.
        </p>
        <p>
          <strong>Kazam EV Charging Solutions:</strong>
        </p>
        <p>
          Kazam EV Tech provides complete solutions for EV charging at home,
          residential societies, offices, malls, restaurants, shops &amp; petrol
          pumps. By means of our products:&nbsp;
          <a href='https://kazam.in/kazam-3.3'>
            <em>
              <strong>Kazam 3.3</strong>
            </em>
          </a>
          &nbsp;&amp;&nbsp;
          <a href='https://kazam.in/mini'>
            <em>
              <strong>Kazam Mini</strong>
            </em>
          </a>
          , you can set up your own charging station/point.
        </p>
        <p>
          <strong>Delhi-specific details:</strong>
        </p>
        <p>
          For residents of Delhi, you can buy an EV charging station by signing
          up at the website of BSES Rajdhani, BSES Yamuna, or Tata Power DDL
          (depending upon your locality).
        </p>
        <p>
          For more details, visit:&nbsp;
          <a href='https://www.kazam.in/category/blog/subsidy-for-ev-charging-stations-in-delhi:-bses-yamuna-bses-rajdhani-tata-power-what-when-who-how-and-more'>
            <em>
              <strong>
                Subsidy for EV Charging Stations in Delhi: BSES Yamuna, BSES
                Rajdhani, Tata Power - What, When, Who, How, and More
              </strong>
            </em>
          </a>
        </p>
        <p>
          <strong>Conclusion:</strong>
        </p>
        <p>
          While EVs are undoubtedly the future, it would take some time for your
          Return on Investments to turn green. Offer advertisement space,
          refreshments, etc, and you&rsquo;re likely to break even and
          subsequently earn a profit much quicker.
        </p>
        <p>
          The cost of running, which includes paying salaries of personnel,
          maintaining the equipment, rent, etc might seem daunting at first, but
          a long-term vision along with the capacity to invest should keep you
          positive until it becomes a profitable venture.
        </p>
      </>
    ),
    blogImg: Blog15,
    heading: 'What is the best way to purchase',
    headingDesc: 'Electric Vehicle charging infrastructure...',
  },
  // Blog 16
  {
    blogId: 16,
    answer: (
      <>
        <p>
          <strong>
            What would the world look like without EV Charging Solutions?
          </strong>
        </p>
        <img src={Blog16} alt='' />
        <p>
          Electric Vehicles are becoming increasingly popular across India.
          However,&nbsp;before there can be a widespread transition to electric
          car usage, a sufficient network of EV charging stations must be
          created in order to reduce range anxiety for potential owners.
        </p>
        <p>
          As EV adoption accelerates, people may decide where they live, eat,
          shop and park based on the availability of charging stations.
          Providing smart EV charging services to the customers would be an easy
          way to build loyalty and increase footfall.
        </p>
        <p>
          <strong>Places, where customers can charge their EVs:</strong>
        </p>
        <h2>Homes:</h2>
        <p>
          Owning a home EV charger is the best way to fully enjoy an EV. Having
          a charging point at home gives control of when to charge. There is no
          need to rely on public charging stations. Just plug in the EV to the
          charger and focus on the things to be done, or simply sleep.
        </p>
        <h2>Workplace:</h2>
        <p>
          After homes, workplace or office charging is the second most
          sought-after charging choice for EV users. Employees can charge their
          EVs while they are working. Corporates are also making an effort to
          become eco-friendly employers. Social responsibility is vital for
          businesses today and enabling greener transport is a crucial part of
          that. EV charging at the workplace sends a clear and visible message
          about where the business stands with respect to reducing the
          environmental impact of its operations &amp; personnel.
        </p>
        <h2>Malls:</h2>
        <p>
          Charging space at malls gives the shoppers a satisfying experience
          where they can shop carefree while their EV is safe in the parking and
          being charged. This is beneficial for malls as well, as it boosts the
          number of visitors, and as EV charging takes a while, users spend more
          time exploring, shopping, and spending.
        </p>
        <h2>Restaurants:</h2>
        <p>
          These days restaurants are not just about the food. The overall
          experience is given more importance. The presence of charging
          facilities at a restaurant adds to the customer experience. Fine
          dining establishments, whose clients have the financial ability to own
          premium electric cars, are readily installing charging stations.
        </p>
        <h2>Housing Societies/Apartments:</h2>
        <p>
          Offering charging is a direct way for property owners and managers to
          attract residents and retain tenants who own electric cars. Installing
          EV charging stations at a multi-resident apartment property
          communicates to the tenants that their property management prioritizes
          environment-friendliness and essential amenities.
        </p>
        <p>
          Most upcoming societies/apartments/townships are being readied with EV
          charging infrastructure. Existing ones are installing the necessary
          electrical connections, sockets, etc as demands from residents
          increase.
        </p>
        <h2>Petrol Pumps:</h2>
        <p>
          The government of India is working towards installing EV charging
          stations at petrol pumps across the country. For conventional vehicle
          owners, trips to petrol pumps for a petrol/diesel refuel are a very
          common occurrence. So it is a sensible option to have electric
          charging points at these places.
        </p>
        <h2>Life without EV charging solutions?</h2>
        <p>
          Things will come to a standstill if EV charging facilities are
          hampered or are not present. For companies involved in manufacturing
          EVs, their components, batteries and for EV owners, EV charging
          solutions are lifelines now. And the rapidly degrading environment
          also needs such solutions to preserve it.
        </p>
        <p>
          As the EV ecosystem expands in India, it will be very common to spot
          EV charging solutions at the above-mentioned avenues. Without EV
          charging amenities, residential &amp; commercial properties may not be
          considered future-ready. In many western countries, a carbon-credit
          system has been implemented and it is only a matter of time before
          India adopts it.
        </p>
        <p>
          Without EV charging solutions, range anxiety creeps in and will affect
          EV sales.&nbsp;Do public charging stations help drive EV adoption, or
          does greater EV adoption demand a more comprehensive public charging
          infrastructure? This question has been talked about a lot in countries
          that have adopted EVs. In near future, installing EV charging points
          will become a standard in every aspect of functioning.
        </p>
        <p>
          Kazam EV Tech provides complete solutions for EV charging at home,
          residential societies, malls, restaurants, Kirana shops, petrol pumps,
          etc. Visit us at&nbsp;<a href='https://www.kazam.in/'>K</a>
          azam EV&nbsp;for all the details.
        </p>
        <h2>Conclusion:</h2>
        <p>
          Charging providers are competing for space at libraries, museums,
          parks, retailers, public &amp; private parking places, and anywhere
          else that is willing to install a station.
        </p>
        <p>
          According to Rolf Schreiber Energy &amp; Efficiency Institute, as part
          of Google&rsquo;s Electric Vehicle initiative, the company is
          operating 294 chargers on its campus in Mountain View, California. The
          response from the employees has been positive. On average, the
          charging points are used 300-400 times daily, by a growing number of
          EV owners. The study also suggested that the service, which is offered
          as an employee perk, has shown early signs of influencing EV purchase
          decisions.
        </p>
        <p>
          This is just one example of the potential of providing EV charging
          solutions to various stakeholders. Be it offices, malls, restaurants,
          etc, EV charging utilities will hold great importance to provide value
          &amp; support to people in near future.
        </p>
      </>
    ),
    blogImg: Blog16,
    heading: 'How would the world look like without EV',
    headingDesc: 'Electric Vehicles are becoming increasingly...',
  },
  // Blog 17
  {
    blogId: 17,
    answer: (
      <>
        <p>
          <strong>Ola S1 vs TVS iQube Electric: Which one to buy?</strong>
        </p>
        <img src={Blog12} alt='' />
        <p>
          The auto-motorcycle industry is about to experience an unprecedented
          revolution. With increasingly stringent environmental standards, the
          future of thermal power is more than uncertain. If the car
          manufacturers announce one after the other to give up the thermal in
          the next ten years, the sector of the two-wheelers is a little quieter
          on the subject, except at the level of the scooters! Indeed, a
          scooter, whether thermal or electric, only asks to turn the
          accelerator handle to move forward, whereas, on a motorcycle, there
          are gears to change, emotions, vibrations that most passionate bikers
          are not about to give up. Scooter riders have a different approach.
          For them, this machine is above all a utility on two wheels. The
          transition from thermal to electric is, therefore, faster here, and
          many manufacturers are starting to offer a variety of models.
        </p>
        <p>
          In cities and the countryside, we come across more and more electric
          scooters. Practical for getting around, there are many models and
          their functionalities differ. Which is the best e-scooter among Ola S1
          vs TVS iQube Electric? To help make an effective choice, this article
          dedicated to consumers, recognized for its neutrality, offers a
          complete comparison of the best electric scooters. Read the article
          below to find the ideal model.
        </p>
        <h2>Ola Electric S1</h2>
        <p>
          Ola S1 is an electric scooter with a starting price of Rs. 97 703 in
          India. You can choose from 2 styles up to 10 colors, with the highest
          style prices starting at Rs. 1.5 lakh. Ola S1 delivers an engine power
          of 5500 W. Ola S1 uses front and rear disc brakes and a combined
          braking system on all wheels. Ola Electric has launched the new S1
          electric scooter in the Indian market. There are two models of Ola
          electric scooters: Ola S1 and Ola S1 Pro.
        </p>
        <h3>Ola Electric S1 Design</h3>
        <p>
          The two variants of the Ola scooter include dual headlights with DRL
          LED rings, elegant LED lighting mounted on the skirt, front fender in
          the same color as the body, curved side panels, tail lights and
          elegant LED. The design has included an external charging point. The
          Ola electric scooter has 36 liters of storage space under the seat for
          two open-face helmets. The standard module has 5 color options and the
          Pro version has 10 options.
        </p>
        <h3>Ola Electric S1 Specification</h3>
        <p>
          The Ola Electric S1 is equipped with an IPM electric motor with a high
          peak power of 8.5 kW and a continuous power of 5.5 kW. The engine is
          powered by 2.98 kWh (S1) and 3.97 kWh (S1 Pro) batteries and has a
          range of 121 km and 181 km respectively. The top speed of the S1 can
          be up to 90 kilometers per hour and the top speed of the S1 Pro is 115
          kilometers per hour. It takes 4 hours and 48 minutes to fully charge
          the S1 Electric Ola S1 and the S1pro can be fully charged in 6 hours
          and 30 minutes with a 750W portable charger. With a quick charger, an
          electric scooter can charge up to 75km in 18 minutes. The Electric Ola
          S1 uses a tubular frame with a front fork and a rear shock absorber.
          In terms of brakes, the electric scooter has a 280mm front disc brake
          and a 220mm rear disc brake, which combines a braking system.
        </p>
        <h3>Ola Electric S1 Features</h3>
        <p>
          The Ola electric scooter has multiple functions. Both models feature a
          full LED lighting system, a large 36-liter under-seat storage
          compartment, a TFT panel with a smartphone, Bluetooth, Wi-Fi, and GPS.
          Other common custom features include side support and anti-theft
          alarms, geofencing, reverse mode, home mode, home lighting, emergency
          home mode, and more. The S1 Pro version also features mountain grip,
          voice assistance, and trip control.
        </p>
        <h3>Ola Electric S1 Price</h3>
        <p>
          Its price started from Rs. 99,999 in 10 stunning color variants
          including black, yellow, white, and so on.
        </p>
        <h2>TVS iQube Electric</h2>
        <p>
          TVS iQube Electric is the first electric car launched by the
          automotive giant TVS. TVS iQube Electric will be launched in 20 cities
          nationwide in March 2022. To that end, in June 2021, the company
          signed a memorandum of understanding with Convergence Energy Services,
          an initiative of the Indian government to install charging stations.
        </p>
        <h3>TVS iQube Design</h3>
        <p>
          The design of TVS iQube is simpler and the more practical design
          spirit of TVS is clearly visible from the design. Unlike newcomers
          entering the market with flexible products, TVS opted for a strong
          scooter idea that could be a great alternative to ICE. The idea is
          that the design is smooth, without unnecessary complications. The
          iQube has a somewhat future U-shaped headlight that could be made with
          more light and pitch. In the center of the front panel is also a light
          strip, on which DRLs and indicators are placed. Overall, the rest of
          the design is quite impressive. Elegant rear light and blue light in
          the center of the engine make it environmentally friendly. In general,
          if you look at it from a distance, it is somewhere that resembles the
          traditional one.As with other TVS products, the paint finish is very
          classy. Few panel gaps were found in the dashboard of the scooter. The
          plastics used in this scooter don't feel good on touching.
        </p>
        <h3>TVS iQube Specification</h3>
        <p>
          The iQube Electric is equipped with a 4.4 kW electric wheel motor and
          a 2.25 kW lithium-ion battery, which allows the e-scooter to
          accelerate to a top speed of 78 km/h. It gives a range of 75
          kilometers in a single charge. iQube accelerates from 0 to 40 km/h in
          just 4.2 seconds. However, the maximum speed of the scooter in economy
          mode is limited to 40 km / h. The scooter basically uses a telescopic
          fork and double shock absorbers at the rear. The scooter is held in
          place by the front disc and the rear drum with 12-inch wheels. The
          IQube weight is 118 kg. It takes 5 hours to completely charge this
          e-bike.
        </p>
        <h3>TVS iQube Features</h3>
        <p>
          The TVS iQube Electric has many features such as a digital color
          display that is easily connectable with the smartphone, LED lighting,
          illuminated signs next to the power bank, lighting under the seats,
          and a USB charging port. Other notable features include Q Park Assist,
          various driving modes, and an advanced braking system. When connected
          to a smartphone, you can view text alerts, a navigation tool,
          historical trip statistics, speed alerts, Fall Alert,battery status.
          You can also check the location of your last parking location. It has
          a subscription model with a price of Rs.900/year through which you can
          avail a lot of features.
        </p>
        <h3>TVS iQube Price</h3>
        <p>
          Its price started from Rs. 1 lakh (Avg. Ex-showroom price) and is
          available in 1 variant.
        </p>
        <h2>Wrapping Up</h2>
        <p>
          Ola S1 comes in a price range of Rs.98000 approx whereas TVS iQube is
          available in 1 valiant only at a price range of Rs. 1 lakh. Ola gives
          an autonomy of 121 km in a single charge which is higher than the
          range of iQube with a range of 75 km in a complete charge. In terms of
          equipped motor, again Ola has a higher watt motor in comparison to TVS
          iQube. We hope that this comparison will help you to choose between
          Ola S1 and TVS iQube Electric for your daily commute. We have made
          this comparison in terms of price, specification, features, and
          design.
        </p>
      </>
    ),
    blogImg: Blog12,
    heading: 'Ola S1 vs TVS iQube Electric',
    headingDesc: 'The auto-motorcycle industry is about to...',
  },
];
