import React, { useState } from 'react';
import Vehicle from '../Vehicle/Vehicle';
import droomImage from '../../Assets/Images/droom.svg';
import './TabBar.scss';

const TabBar = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = index => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    toggleState === index ? className : '';

  return (
    <>
      <div className='container'>
        <ul className='tab-list'>
          <li
            className={`tabs ${getActiveClass(1, 'active-tabs')}`}
            onClick={() => toggleTab(1)}
          >
            Car
          </li>
          <li
            className={`tabs ${getActiveClass(2, 'active-tabs')}`}
            onClick={() => toggleTab(2)}
          >
            Bike
          </li>
          <li
            className={`tabs ${getActiveClass(3, 'active-tabs')}`}
            onClick={() => toggleTab(3)}
          >
            Scooter
          </li>
        </ul>

        <div className='content-container'>
          <div className={`content ${getActiveClass(1, 'active-content')}`}>
            {toggleState === 1 ? <Vehicle type={'car'} /> : null}
          </div>

          <div className={`content ${getActiveClass(2, 'active-content')}`}>
            {toggleState === 2 ? <Vehicle type={'bike'} /> : null}
          </div>

          <div className={`content ${getActiveClass(3, 'active-content')}`}>
            {toggleState === 3 ? <Vehicle type={'scooter'} /> : null}
          </div>
        </div>
      </div>

      <footer>
        <div className='droom-footer-tab'>
          <div className='droom-text'>
            <p>Powered by</p>
          </div>
          <div className='droom-image'>
            <img src={droomImage} alt='Droom-Logo' />
          </div>
        </div>
      </footer>
    </>
  );
};

export default TabBar;
